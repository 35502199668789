import React from 'react';
import PropTypes from 'prop-types';
import Dimmer from './Dimmer';
import Loader from './Loader';

const ComponentLoader = ({ className, active, label, fullPage }) => (
    <Dimmer className={ className } active={ active } fullPage={ fullPage }>
        <Loader content={ label } />
    </Dimmer>
);

ComponentLoader.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.string,
    fullPage: PropTypes.bool
};

ComponentLoader.defaultProps = {
    active: true,
    label: Loader.defaultProps.label,
    fullPage: false
};

export default ComponentLoader;
