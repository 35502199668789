import * as React from 'react';
import { Accordion, Box, Form, ResponsiveContext, Text } from 'grommet';
import { useHistory } from 'react-router';
import StepNavigationComponent from 'app/features/planning/components/StepNavigationComponent';
import { routes } from 'app/common/routes';
import HeadingOne from 'app/common/HeadingOne';
import UserContext from 'app/features/user/context/UserContext';
import { Button } from '@snsw/react-component-library';
import BackLinkComponent from '../../planning/components/BackLinkComponent';
import HighlightCardParagraphs from '../../../common/HighlightCardParagraphs';
import RequiredField from '../../../common/RequiredField';
import { getTaskItem, updateTaskItem } from '../../../common/services/DataService';
import FieldLabel from '../../../theme/FieldLabel';
import {
  getDocumentLocations,
  updateDocumentLocations,
} from '../../../features/documentLocation/services/DocumentLocationDataService';
import { EnduringGuardianPanel } from '../../../features/documentLocation/components/EnduringGuardianPanel';
import RadioGroup from '../../../theme/RadioGroup/RadioGroup';

const EnduringGuardianStatus: React.FunctionComponent = () => {
  const INITIAL_STATE_EG = {};
  const INITIAL_STATE_DOC_LOC = {
    eg: {
      saved: false,
      type: {
        label: 'Enduring Guardianship',
        value: 'eg',
      },
    },
  };
  const history = useHistory();
  const { user } = React.useContext(UserContext);

  const [enduringGuardianship, setEnduringGuardianship] = React.useState<EnduringGuardianship>(INITIAL_STATE_EG);
  const [errors, setErrors] = React.useState<EnduringGuardianshipErrors>({});

  const [documentLocation, setDocumentLocation] = React.useState(INITIAL_STATE_DOC_LOC);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const closeAccordion = () => {
    setActiveIndex([]);
  };

  React.useEffect(() => {
    const loadDocumentLocation = async () => {
      try {
        const payload = await getDocumentLocations(user);
        setDocumentLocation({
          ...documentLocation,
          ...payload.documentLocation,
        });
      } catch (err) {
        console.warn('documentLocation not found. 1st time in?');
      }
    };
    loadDocumentLocation();
  }, []);

  React.useEffect(() => {
    const loadEnduringGuardianship = async () => {
      try {
        const payload = await getTaskItem(user, 'enduringGuardianship');
        setEnduringGuardianship(payload.enduringGuardianship);
      } catch (err) {
        console.warn('enduringGuardianship not found. 1st time in?');
      }
    };
    loadEnduringGuardianship();
  }, []);

  const saveDocumentLocation = async (updated) => {
    setDocumentLocation(updated);
    await updateDocumentLocations(user, updated);
  };

  const handleOnChange = (fieldName, value) => {
    const updatedEnduringGuardianship = { ...enduringGuardianship, [fieldName]: value };

    // clear unnecessary data
    if (fieldName === 'hasValidEgAppointment' && (value === 'no' || value === 'notSure')) {
      delete updatedEnduringGuardianship.isRenewingEgAppointment;
    }

    setEnduringGuardianship(updatedEnduringGuardianship);
    const updatedErrors = checkFieldError(fieldName, updatedEnduringGuardianship as EnduringGuardianship);
    setErrors(updatedErrors);
  };

  const fieldNameCheck = (fieldName) => {
    if (!['hasValidEgAppointment', 'isRenewingEgAppointment'].includes(fieldName)) {
      throw new Error(`Unrecognised fieldName ${fieldName}`);
    }
    return fieldName;
  };

  const checkFieldError = (fieldName, data = {} as EnduringGuardianship) => {
    const updatedErrors = {
      ...errors,
    };

    if (!['hasValidEgAppointment', 'isRenewingEgAppointment'].includes(fieldName)) {
      return updatedErrors;
    }

    if (!data[fieldNameCheck(fieldName)]) {
      updatedErrors[fieldNameCheck(fieldName)] = true;
    } else {
      delete updatedErrors[fieldNameCheck(fieldName)];
    }

    return updatedErrors;
  };

  const checkFormErrors = (data = {} as EnduringGuardianship) => {
    const updatedErrors = {
      ...errors,
    } as EnduringGuardianshipErrors;

    if (!data.hasValidEgAppointment) {
      updatedErrors.hasValidEgAppointment = true;
    } else if (data.hasValidEgAppointment) {
      delete updatedErrors.hasValidEgAppointment;
    }

    if (data.hasValidEgAppointment === 'yes' && !data.isRenewingEgAppointment) {
      updatedErrors.isRenewingEgAppointment = true;
    } else if (data.hasValidEgAppointment === 'yes' && data.isRenewingEgAppointment) {
      delete updatedErrors.isRenewingEgAppointment;
    }

    return updatedErrors;
  };

  const saveEnduringGuardianship = async () => {
    const updatedErrors = checkFormErrors(enduringGuardianship);
    if (Object.keys(updatedErrors).length !== 0) {
      setErrors(updatedErrors);
    } else {
      await updateTaskItem(user, enduringGuardianship, 'enduringGuardianship');
      if (enduringGuardianship.isRenewingEgAppointment === 'no') {
        history.push(routes.HOME);
      } else {
        history.push(routes.ENDURING_GUARDIAN_DETAILS);
      }
    }
  };

  const handleOnActive = (indexes) => {
    setActiveIndex(indexes);
  };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          <BackLinkComponent
            handleBack={() => {
              history.push(routes.HOME);
            }}
          />
          <HeadingOne title="Enduring Guardianship preparation" />
          <Box width={size === 'small' ? '100%' : '75%'}>
            <Box margin={{ bottom: '34px' }}>
              <Text>
                An Enduring Guardian can make health, medical and lifestyle decisions for you if you lose the capacity
                to make your own decisions at some time in the future. This is different from a guardian appointed to
                care for children under the age of 18 years.
              </Text>
              <Text>
                The purpose of this service is to assist you in preparing for a meeting with a professional, ensuring
                all aspects of your Enduring Guardian appointment are being considered.
              </Text>
            </Box>
            <HighlightCardParagraphs
              paragraphs={[
                `This service is a record of your information.  It is not instructions for or a draft of, a final will, 
                power of attorney or appointment of enduring guardian.  Nor does it cancel or alter any of these documents.`,
              ]}
              type={'legal'}
            />
          </Box>
          <RequiredField />
          <Box width={size === 'small' ? '100%' : '75%'} pad={{ bottom: 'small' }}>
            <Form name="EnduringGuardianshipForm">
              <Box pad={{ bottom: '2px' }}>
                <RadioGroup
                  id="hasValidEgAppointment"
                  name="hasValidEgAppointment"
                  label={'Do you have a valid Enduring Guardianship appointment? *'}
                  tooltip={`A valid Enduring Guardianship appointment is a legal document signed by you and all the appointed 
                  guardians and witnessed by a legal practitioner (Australian or overseas), registrar of the local court, or 
                  approved officer from NSW Trustee & Guardian.`}
                  value={enduringGuardianship?.hasValidEgAppointment || ''}
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                    { value: 'notSure', label: 'Not sure' },
                  ]}
                  handleOnChange={(value) => {
                    handleOnChange('hasValidEgAppointment', value);
                  }}
                  hasError={errors?.hasValidEgAppointment}
                ></RadioGroup>
              </Box>
              {enduringGuardianship?.hasValidEgAppointment === 'yes' && (
                <Box pad={{ top: '10px' }}>
                  <RadioGroup
                    id="isRenewingEgAppointment"
                    name="isRenewingEgAppointment"
                    label={'Are you considering changing or renewing this? *'}
                    value={enduringGuardianship?.isRenewingEgAppointment || ''}
                    options={[
                      { value: 'yes', label: 'Yes' },
                      { value: 'no', label: 'No' },
                    ]}
                    handleOnChange={(value) => {
                      handleOnChange('isRenewingEgAppointment', value);
                    }}
                    hasError={errors?.isRenewingEgAppointment}
                  ></RadioGroup>
                </Box>
              )}
              {enduringGuardianship?.hasValidEgAppointment === 'yes' &&
                enduringGuardianship?.isRenewingEgAppointment === 'yes' && (
                  <Box pad={{ top: '4px' }}>
                    <HighlightCardParagraphs
                      paragraphs={[
                        `If your circumstances change and you wish to change or renew, you will need to 
                          revoke your earlier document by taking it to a professional. You can return to 
                          this task and start planning your new Enduring Guardianship document, but it 
                          does not cancel or alter your current valid Enduring Guardianship appointment.`,
                      ]}
                      type={'advise'}
                    />
                  </Box>
                )}
              {enduringGuardianship?.hasValidEgAppointment === 'yes' &&
                enduringGuardianship?.isRenewingEgAppointment === 'no' && (
                  <Box pad={{ top: '8px' }}>
                    <FieldLabel
                      htmlForId={''}
                      label={'Where is your Enduring Guardianship document stored?'}
                      margin={{}}
                    />
                    <Box pad={{ top: '3px' }}>
                      <Text>
                        Make sure that your documents are in a safe place, and let a trusted person(s) and / or your
                        medical practitioner(s) know where they are located.
                      </Text>
                    </Box>
                    <Box width="large" gap="medium" pad={{ top: '24px' }} margin={{ bottom: '20px' }}>
                      <Accordion
                        activeIndex={activeIndex}
                        onActive={handleOnActive}
                        border={{ color: '#C4D0D6', size: 'small' }}
                        round="1%"
                      >
                        <EnduringGuardianPanel
                          activeIndex={activeIndex}
                          documentLocation={documentLocation}
                          saveDocumentLocation={saveDocumentLocation}
                          closeAccordion={closeAccordion}
                          accordionIndex={0}
                        />
                      </Accordion>
                    </Box>
                    <HighlightCardParagraphs
                      paragraphs={[
                        `If your circumstances change and you wish to change or renew, 
                          you will need to revoke your earlier document by taking it to 
                          a professional. You can return to this task and start planning 
                          your new Enduring Guardianship document, but it does not cancel 
                          or alter your current valid Enduring Guardianship appointment.`,
                      ]}
                      type={'advise'}
                    />
                  </Box>
                )}
              {enduringGuardianship?.hasValidEgAppointment === 'notSure' && (
                <Box pad={{ top: '3px' }}>
                  <HighlightCardParagraphs
                    paragraphs={[
                      `An Enduring Guardian is typically created alongside other estate 
                      planning documents such as your will and Enduring Power of Attorney. 
                      You may have created one if you had to undergo a period of 
                      hospitalisation and / or needed someone to act on your behalf for 
                      medical reasons, so be sure to check your legal documents to avoid 
                      duplication.`,
                    ]}
                    type={'advise'}
                  />
                </Box>
              )}
            </Form>
          </Box>
          <Box pad={{ bottom: '24px' }}></Box>
          <Box margin={{ bottom: 'medium' }}>
            <StepNavigationComponent
              handleBack={() => {
                history.push(routes.HOME);
              }}
              nextButton={
                <Button theme="primary" className="primary-button-dcs" type="button" onClick={saveEnduringGuardianship}>
                  {enduringGuardianship?.hasValidEgAppointment === 'yes' &&
                  enduringGuardianship?.isRenewingEgAppointment === 'no'
                    ? 'Save and exit'
                    : 'Save and continue'}
                </Button>
              }
            />
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default EnduringGuardianStatus;
