import heading from './heading';
import layout from './layout';
import text from './text';

export default {
  global: {
    ...layout,
    focus: {
      border: {
        color: '#F9FDFF',
      },
    },
    colors: {
      brand: '#002664',
      control: {
        light: '#2E5299',
      },
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '6px',
      large: '12px',
      xlarge: '24px',
    },
  },
  heading,
  text,
  accordion: {
    border: {
      color: '#F9FDFF',
    },
    panel: {
      border: null,
    },
  },
  radioButton: {
    border: {
      color: { light: 'rgb(195, 195, 195)', dark: 'rgb(195, 195, 195)' },
    },
    check: {
      background: {
        color: { light: 'rgb(255, 255, 255)', dark: 'rgb(59, 59, 59)' },
      },
      color: { light: '#2E5299', dark: 'white' },
    },
    container: {
      extend: {
        fontSize: '16px',
      },
    },
    gap: '15px',
    size: '1.75em',
  },
  anchor: {
    textDecoration: 'underline',
  },
};
