import * as PropTypes from 'prop-types';
import { Container, Dropdown, Flex, Margin, StyledAddressLegend, StyledFormInput } from './AddressV2AutoSuggest.styled';
import { getID } from '../../../utils';
import AutoSuggest from '../../AutoSuggest/AutoSuggest';
import FormInput from '../FormInput';
import React from 'react';
import TertiaryButton from '../../../Buttons/TertiaryButton/TertiaryButton.styled';
import nswPointV2Api from './nswPointV2Api';

const defaultManualAddressFields = {
    suburb: '',
    state: '',
    postcode: '',
    addressLine: ''
};

const defaultAutoSuggestedAddressFields = {
    ...defaultManualAddressFields,
    buildingNumber: '',
    country: '',
    formattedAddress: '',
    latitude: '',
    longitude: '',
    nswPointId: '',
    propertyName: '',
    streetName: '',
    streetNumber: '',
    streetType: '',
    unitNumber: '',
    validated: true
};

const defaultAddressValidator = (address) => {
    if (address.validated) {
        return true;
    }

    return Object.keys(defaultManualAddressFields).every(key => address[key]);
};

export default class AddressAutoSuggest extends React.Component {
    state = {
        suggestions: [],
        hasReceivedUpdatedPropsOnce: false,
        address: this.props.value,
        userSelected: this.props.value && this.props.value.validated,
        manualMode: this.props.initialLoadManual,
    };

    static getDerivedStateFromProps(props, state) {
        if (Object.keys(props.value).length > 0 && !state.hasReceivedUpdatedPropsOnce) {
            return {
                address: props.value,
                manualMode: props.initialLoadManual || props.value.validated === false,
                hasReceivedUpdatedPropsOnce: true
            };
        }
        return null;
    }

    onSwitchToManual = () => {
        this.setState({
            address: {
                ...defaultManualAddressFields,
                validated: this.props.emptyAddressValid
            },
            userSelected: false,
            manualMode: true,
        });

        this.props.emptyAddressValid ? this.addressHasBecomeValid() : this.onAddressHasBecomeInvalid();
        this.props.onSwitchToManual();
    };

    onAddressHasBecomeInvalid = () => {
        const invalidAddress = this.state.manualMode
            ? { ...this.state.address }
            : { ...defaultAutoSuggestedAddressFields, validated: false };

        this.props.onAddressHasBecomeInvalid(invalidAddress);
    }

    onSwitchToAutoSuggest = () => {
        this.setState({
            address: {
                ...defaultAutoSuggestedAddressFields,
                validated: this.props.emptyAddressValid
            },
            userSelected: true,
            manualMode: false,
        });

        this.props.emptyAddressValid ? this.addressHasBecomeValid() : this.onAddressHasBecomeInvalid();
        this.props.onSwitchToAutoSuggest();
    };

    handleChange = (key, value) => {
        const updatedAddress = {
            ...this.state.address,
            [key]: value
        };

        this.setState({
            address: updatedAddress
        }, () => {
            if (this.props.validator(updatedAddress)) {
                this.addressHasBecomeValid(updatedAddress, false);
            } else {
                this.onAddressHasBecomeInvalid();
            }
        });
    };

    fetchSuggestions = async (value) => {
        const response = await this.props.backendAPI.getLatestSuggestions(
            value,
            this.props.apiKey,
            this.props.apiState,
            this.props.apiAddressType,
            this.props.apiDataSet
        );

        const { suggestions, isLatest } = response;
        if (isLatest) {
            this.setState({ suggestions });
        }
    };

    debouncedFetchSuggestions = debounce(this.fetchSuggestions, 500);

    handleChangeAfterSelection = (updatedAddress) => {
        if (!updatedAddress || updatedAddress === '') {
            this.setState({
                address: {},
                userSelected: false,
            });
            this.props.emptyAddressValid
                ? this.addressHasBecomeValid(defaultAutoSuggestedAddressFields, true)
                : this.onAddressHasBecomeInvalid();
            return;
        }

        if (this.state.userSelected) {
            if (updatedAddress !== this.state.address.formattedAddress) {
                this.onAddressHasBecomeInvalid();
            } else {
                this.addressHasBecomeValid(this.state.address, true);
            }
        }
    };

    addressHasBecomeValid = (updatedAddress, validated = true) => {
        const completeAddress = { ...defaultAutoSuggestedAddressFields, ...updatedAddress, validated: validated };
        this.props.onAddressHasBecomeValid({ ...completeAddress });
    };

    selectValue = async (value) => {//suggestion select
        const { id, address } = value;
        if (typeof address === 'object') {
            this.onSwitchToManual();
        } else {
            const detailedAddress = await this.props.backendAPI.getDetailsById(id, this.props.apiKey);
            this.setState({
                address: detailedAddress,
                userSelected: true,
            });

            this.addressHasBecomeValid(detailedAddress, true);
        }
    };

    clearSuggestions = () => {
        this.setState({ suggestions: [] });
        if (!this.state.address.validated) {
            this.setState({ address: {} });
        }
    };

    renderSuggestion = ({ address }) => address;

    getSuggestionValue = ({ address }) => {
        if (typeof address === 'string') {
            return address;
        } else {
            return this.props.value.formattedAddress;
        }
    };

    render() {
        const { suggestions, address, manualMode } = this.state;
        const {
            addressType, helpMessage, manualModeMessage, manualAddressHeading, id, name,
            label, disabled, placeholder, hasError, errorMessage
            , onBlur } = this.props;

        const genID = () => {
            return id || getID();
        };

        const elemID = genID();

        const { addressLine = '', suburb = '', state = '', postcode = '', formattedAddress } = address;
        const streetAddressLabel =
            <Flex direction='row' justifyContent='space-between' onClick={ e => {
                e.preventDefault();
            } }
            >
                Street address
                <TertiaryButton textDecoration={ 'underline' } onClick={ this.onSwitchToAutoSuggest }>
                    Find your address
                </TertiaryButton>
            </Flex>;

        const autoSuggestLabel =
            <Flex
                direction='row'
                justifyContent='space-between'
                onClick={ (e) => {
                    e.preventDefault();
                } }
            >
                {helpMessage}
                <TertiaryButton
                    style={ { textAlign: 'right' } }
                    textDecoration={ 'underline' }
                    onClick={ this.onSwitchToManual }
                >
                    {manualModeMessage}
                </TertiaryButton>
            </Flex>;

        return (
            <div className={ `${addressType}-address` }>
                {manualMode ? (
                    <Container data-test={ 'manualAddressContainer' } >
                        <Margin top={ 1 } />
                        <StyledAddressLegend>{manualAddressHeading}</StyledAddressLegend>
                        <StyledFormInput
                            id={ `${elemID}-address-line-input` }
                            className={ `${addressType}-address-line-input` }
                            data-test='addressLine'
                            label={ streetAddressLabel }
                            value={ addressLine }
                            maxLength={ 255 }
                            onChange={ (e) =>
                                this.handleChange('addressLine', e.target.value)
                            }
                            hasError={ hasError && !!errorMessage.addressLine }
                            errorMessage={ errorMessage.addressLine }
                        />
                        <FormInput
                            id={ `${elemID}-address-suburb-input` }
                            className={ `${addressType}-address-suburb-input` }
                            data-test='suburb'
                            label='Suburb'
                            value={ suburb }
                            maxLength={ 40 }
                            onChange={ (e) => this.handleChange('suburb', e.target.value) }
                            hasError={ hasError && !!errorMessage.suburb }
                            errorMessage={ errorMessage.suburb }
                        />
                        <Flex direction='row'>
                            <Dropdown
                                id={ `${elemID}-address-state-dropdown` }
                                className={ `${addressType}-address-state-dropdown` }
                                label='State'
                                data-test='state'
                                options={ AddressAutoSuggest.statesCodes }
                                value={ state }
                                maxLength={ 20 }
                                style={ { marginRight: '1rem' } }
                                onChange={ (e) => this.handleChange('state', e.target.value) }
                                hasError={ hasError && !!errorMessage.state }
                                errorMessage={ errorMessage.state }
                            />
                            <FormInput
                                id={ `${elemID}-address-postcode-input` }
                                className={ `${addressType}-address-postcode-input` }
                                data-test='postcode'
                                label='Postcode'
                                value={ postcode }
                                maxLength={ 4 }
                                onChange={ (e) =>
                                    this.handleChange('postcode', e.target.value)
                                }
                                hasError={ hasError && !!errorMessage.postcode }
                                errorMessage={ errorMessage.postcode }
                            />
                        </Flex>
                        <FormInput
                            id={ `${elemID}-address-country-input` }
                            className={ `${addressType}-address-country-input` }
                            data-test='country'
                            label='Country'
                            value='Australia'
                            maxLength={ 40 }
                            disabled={ true }
                        />
                    </Container>
                ) : (<Container data-test={ 'autoSuggestContainer' }>
                    <AutoSuggest
                        id={ elemID }
                        name={ name }
                        label={ label }
                        helpMessage={ autoSuggestLabel }
                        hasError={ hasError }
                        errorMessage={ errorMessage.autoSuggest }
                        disabled={ disabled }
                        placeholder={ placeholder }
                        showManualAddress={ false }
                        initialValue={ formattedAddress }
                        suggestions={ suggestions }
                        onGetSuggestions={ this.debouncedFetchSuggestions }
                        onClearSuggestions={ this.clearSuggestions }
                        onSelect={ this.selectValue }
                        getSuggestionValue={ this.getSuggestionValue }
                        onValueChange={ (e) => this.handleChangeAfterSelection(e) }
                        onBlur={ (e) => onBlur(e) }
                        renderSuggestion={ this.renderSuggestion }
                        minLength={ 4 /* min query length for V2 api */ }
                    />
                </Container>) }
            </div>
        );
    }

    static statesCodes = [
        { text: '', value: '' },
        { text: 'NSW', value: 'NSW' },
        { text: 'ACT', value: 'ACT' },
        { text: 'QLD', value: 'QLD' },
        { text: 'VIC', value: 'VIC' },
        { text: 'TAS', value: 'TAS' },
        { text: 'SA', value: 'SA' },
        { text: 'NT', value: 'NT' },
        { text: 'WA', value: 'WA' },
        { text: 'OT', value: 'OT' },
    ];
}

AddressAutoSuggest.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    apiKey: PropTypes.string,
    apiAddressType: PropTypes.string,
    apiDataSet: PropTypes.string,
    apiState: PropTypes.string,
    addressType: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    value: PropTypes.shape({
        addressLine: PropTypes.string,
        suburb: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
        country: PropTypes.string,
        formattedAddress: PropTypes.string,
        nswPointReferenceId: PropTypes.string,
        validated: PropTypes.bool,
    }).isRequired,
    initialLoadManual: PropTypes.bool.isRequired,
    emptyAddressValid: PropTypes.bool.isRequired,
    onAddressHasBecomeValid: PropTypes.func.isRequired,
    onAddressHasBecomeInvalid: PropTypes.func.isRequired,
    onSwitchToManual: PropTypes.func.isRequired,
    onSwitchToAutoSuggest: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    helpMessage: PropTypes.string,
    manualModeMessage: PropTypes.string,
    manualAddressHeading: PropTypes.string,
    hasError: PropTypes.bool.isRequired,
    errorMessage: PropTypes.shape({
        autoSuggest: PropTypes.string,
        addressLine: PropTypes.string,
        suburb: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string
    }),
    backendAPI: PropTypes.shape({
        getLatestSuggestions: PropTypes.func.isRequired,
        getDetailsById: PropTypes.func.isRequired
    }).isRequired,
};

AddressAutoSuggest.defaultProps = {
    addressType: 'residential',
    apiState: 'ALL',
    validator: defaultAddressValidator,
    initialLoadManual: false,
    onSwitchToManual: () => {
    },
    onSwitchToAutoSuggest: () => {
    },
    emptyAddressValid: false,
    helpMessage: 'Start typing to search for an address',
    manualModeMessage: 'Can’t find the address?',
    manualAddressHeading: 'Enter your address',
    hasError: false,
    errorMessage: {},
    backendAPI: nswPointV2Api,
};

function debounce(func, wait) {
    let timerId;
    return function (...args) {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}
