import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { H3 } from '../../Headers/HeaderTag.styled';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton.styled';

export const FileContainer = styled.div`
    position: relative;
    margin-top: 24px;
    border-radius: 4px;
    border: dashed 2px ${props => props.error ? props.theme.colors.red : props.theme.colors.standardBorder};

    hr {
        border: 1px solid #e3e3e3;
    }
`;

export const FileHeader = styled.div`
    padding: 24px 24px 8px 24px;
    color: ${(props) => props.theme.colors.blackBlue};
    font-size: 14px;

    ul {
        padding-left: 16px;
    }

    li {
        margin-bottom: 16px;
    }
`;

export const FileContent = styled.div`
    padding: 24px;
`;

export const FileRestrictions = styled.p`
    color: #707480;
    font-size: 14px;
`;

export const FileInput = styled.div`
    input {
        visibility: hidden;
        position: absolute;
        z-index: -1;
    }
`;

export const FileInfo = styled.div`
    display: flex;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
`;

export const FileName = styled.div`
    word-break: break-word;
    display: flex;
    align-items: center;
    width: 100%;
`;

export const FileExtension = styled.span`
    padding: 0 4px;
    height: 32px;
    line-height: 32px;
    text-transform: uppercase;
    background: rgb(100, 105, 116);
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-right: 8px;
`;

export const RemoveFileButton = styled.button`
    color: rgb(155, 155, 155);
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Spacer = styled.div`
  width: 100%;

  ${props => !isNaN(props.md) && css`
      height: ${props.md}rem;
  `}

  ${props => !isNaN(props.xs) && css`
    @media(max-width: 480px) {
      height: ${props.xs}rem;
    }
  `};
`;

Spacer.propTypes = {
    md: PropTypes.number,
    xs: PropTypes.number
};

export const QuestionTitle = styled(H3).attrs({ 'data-testid': 'question-title' })`
    margin: 32px 0;
`;

export const ButtonContainer = styled.div`
    ${SecondaryButton} {
        margin-left: 24px;

        @media (max-width: 480px) {
            margin-top: 16px;
            margin-left: 0px;
        }
    }
`;
