import { Box } from 'grommet';
import * as React from 'react';
import LabelAndValue from 'app/common/LabelAndValue';
import { routes } from 'app/common/routes';
import { questionsLabels, getAnswerByQuestionId } from 'planning/utils/questions';
import SummaryHeaderComponent from './SummaryHeaderComponent';

type Props = {
  questions: WillPlanningForm.Question[];
};

const YourAnswersComponent: React.FunctionComponent<Props> = ({ questions }: Props) => {
  return (
    <Box fill>
      <Box>
        <SummaryHeaderComponent text="Questions to help plan your will" goTo={routes.QUESTIONS} />
      </Box>
      <Box width="xxlarge" gap="medium">
        {questionsLabels.map(({ id: questionId, label }) => (
          <Box key={questionId} fill>
            <LabelAndValue label={label} value={getAnswerByQuestionId(questions, questionId)} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default YourAnswersComponent;
