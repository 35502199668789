import * as React from 'react';
import { doLogin } from '../../user/components/MyAccountService';

const RedirectToMyAccount: React.FunctionComponent = () => {
  React.useEffect(() => {
    doLogin();
  }, []);
  return <></>;
};

export default RedirectToMyAccount;
