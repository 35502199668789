import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  require('../public/new-relic.js');
}

const tagManagerArgs = {
  gtmId: 'GTM-N56Q3QK',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
