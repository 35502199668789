import * as React from 'react';
import { Box, Text, TextInput } from 'grommet';
import { Button } from '@snsw/react-component-library';
import { useHistory } from 'react-router';
import { routes } from 'app/common/routes';
import StepNavigationComponent from 'app/features/planning/components/StepNavigationComponent';
import UserContext from 'app/features/user/context/UserContext';
import BackLinkComponent from 'app/features/planning/components/BackLinkComponent';
import HeadingOne from 'app/common/HeadingOne';
import HeadingTwo from 'app/common/HeadingTwo';
import HighlightCardParagraphs from 'app/common/HighlightCardParagraphs';
import RequiredField from 'app/common/RequiredField';
import FieldRequired from 'app/common/FieldRequired';
import DataService from 'app/common/services/DataService';

const EnduringGuardianAdvanceCareDirective: React.FunctionComponent = () => {
  const history = useHistory();

  const { user } = React.useContext(UserContext);

  const [enduringGuardianship, setEnduringGuardianship] = React.useState<EnduringGuardianship>(null);

  React.useEffect(() => {
    const loadEnduringGuardianship = async () => {
      try {
        const payload = await DataService.getTaskItem(user, 'enduringGuardianship');
        console.log('enduringGuardianship =>', payload);
        setEnduringGuardianship(payload.enduringGuardianship);
      } catch (err) {
        console.warn('enduringGuardianship not found. 1st time in?');
      }
    };
    loadEnduringGuardianship();
  }, []);

  return (
    <>
      <BackLinkComponent
        handleBack={() => {
          history.push(routes.ENDURING_GUARDIAN_DETAILS);
        }}
      />
      <HeadingOne title="Enduring Guardianship preparation" />
      <HeadingTwo title="Function and limits" />
    </>
  );
};

export default EnduringGuardianAdvanceCareDirective;
