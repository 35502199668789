import * as React from 'react';
import { Box, Heading, RadioButtonGroup, ThemeContext } from 'grommet';
import radioColorTheme from '../utils/radioButtonTheme';
import FieldErrorMessage from './FieldErrorMessage';

type BookingRadioProps = {
  name: string;
  legend: string;
  options: string[];
  onChange: (value: string) => void;
  value: string;
  hasError: boolean;
};

const BookingRadio: React.FunctionComponent<BookingRadioProps> = ({
  name,
  legend,
  onChange,
  options,
  value,
  hasError,
}) => {
  return (
    <Box fill margin={{ bottom: 'medium' }}>
      <Box fill>
        <Heading level="5" margin={{ bottom: 'medium' }}>
          {legend}
        </Heading>
      </Box>
      <Box>
        <ThemeContext.Extend value={...radioColorTheme(hasError)}>
          <RadioButtonGroup
            onChange={(event) => onChange(event.target.value)}
            options={options}
            name={name}
            value={value}
          />
        </ThemeContext.Extend>
      </Box>
      {hasError && <FieldErrorMessage />}
    </Box>
  );
};

export default BookingRadio;
