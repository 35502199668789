import * as React from 'react';
import { Text as ReactPDFText, View } from '@react-pdf/renderer';
import { assetTypesSelectOptions } from '../../../utils/assetTypesSelectOptions';
import styles from './stylesheet';

const labelMap = new Map();
labelMap.set('certificateOfTitleLocation', 'Where are your title deeds stored?');
labelMap.set('addressOfTheProperty', 'Property address');
labelMap.set('realEstateOwnership', 'How do you own this real estate?');
labelMap.set('brokerDetails', 'Details of broker or financial adviser');
labelMap.set('fundType', 'Type of fund');
labelMap.set('typeOfItem', 'Type of item');
labelMap.set('description', 'Description');
labelMap.set('nameOfInsuranceProvider', 'Name of insurance provider');
labelMap.set('businessName', 'Name of business');
labelMap.set('businessStructure', 'Business structure');
labelMap.set('bankName', 'Name of financial institution');
labelMap.set('bankAccountsOwnership', 'How do you own this bank account?');

const assetList = (assets: WillPlanningForm.AssetSubType[]) => {
  if (!assets) {
    return null;
  }

  return assets.map((asset, index) => {
    const option = assetTypesSelectOptions.find((assetType) => assetType.value === asset?.type);
    const assetEntries = Object.entries(asset);
    return (
      <View style={styles.asset} key={option.text + index}>
        <View style={styles.dataRow}>
          <View style={styles.dataColumn}>
            <ReactPDFText>Asset type:</ReactPDFText>
          </View>
          <View style={styles.dataColumn}>
            <ReactPDFText style={styles.dataValue}>{option.text}</ReactPDFText>
          </View>
        </View>
        {assetEntries.map((entry) => {
          if (entry[0] !== 'type') {
            return (
              <View style={styles.dataRow} key={entry[0]}>
                <View style={styles.dataColumn}>
                  <ReactPDFText>{labelMap.get(entry[0])}</ReactPDFText>
                </View>
                <View style={styles.dataColumn}>
                  <ReactPDFText style={styles.dataValue}>{entry[1]}</ReactPDFText>
                </View>
              </View>
            );
          }
        })}
      </View>
    );
  });
};

const AssetsSection: React.FunctionComponent<{ assets: WillPlanningForm.AssetSubType[] }> = ({ assets }) => {
  return (
    <View style={styles.section}>
      <View>
        <ReactPDFText style={styles.title}>Your assets</ReactPDFText>
      </View>
      {assetList(assets)}
    </View>
  );
};

export default AssetsSection;
