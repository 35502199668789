import styled, { css } from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../../utils';
import Heading from '../Headings/Heading';

const { colors, font } = tokens;

export const FormFieldset = styled.fieldset`
    position: relative;
    border: 0;
    padding: 0;
    border-radius: 0;
    margin-top: ${getSpacing(['xl'])};

    &:first-child{
        margin-top: 0;
    }

    ${getBreakpoint.md`
        margin-top: ${getSpacing(['xxxl'])};
    `}

    > .field-item:first-of-type,
    .date-container {
        margin-top: ${getSpacing(['xs'])};
    }

    .radio-item:first-of-type,
    .checkbox-item:first-of-type{
        margin-top: ${getSpacing(['sm'])};
    }

    + .field-item,
    + .checkbox-item,
    + * {
        margin-top: ${getSpacing(['xl'])};

        ${getBreakpoint.md`
            margin-top: ${getSpacing(['xxxl'])};
        `}
    }
`;

const FormStyled = styled.form`
    position: relative;
    max-width: 100%;
`;

export const FormContainer = css`
    position: relative;
    margin: ${getSpacing(['sm', 'none'])};
`;

export const FormLabel = styled.label`
    display: block;
    color: ${colors.forms.labelText};
    margin-bottom: 0;
`;

export const FormLabelNote = styled.span`
    font-style: italic;
    font-size: ${pxToRem(font.size.base)};
`;

export const FormError = styled.span`
    display: block;
    color: ${colors.text.error};
    margin-top: ${getSpacing(['xs'])};
    padding-left: ${pxToRem(28)};
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23c60c30' cx='8' cy='8' r='8'/%3E%3Cpath fill='%23fff' d='M4.994 4L4 4.994 7.006 8 4 11.006l.994.994L8 8.994 11.006 12l.994-.994L8.994 8 12 4.994 11.006 4 8 7.006z'/%3E%3C/svg%3E") no-repeat 0 4px;
`;

export const HiddenRadioCheckbox = css`
    position: absolute;
    opacity: 0;
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    padding: 0;
    margin: 0;
`;

export const CustomRadioCheckbox = css`
    position: relative;
    padding: ${getSpacing([4, 'none', 4, 'xxl'])};
    margin: 0;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border: 2px solid ${colors.forms.borderColor};
        background-color: ${colors.forms.background};
        width: ${pxToRem(32)};
        height: ${pxToRem(32)};
        box-sizing: border-box;
    }

    &::after {
        position: absolute;
        content: '';
        top: -3px;
        left: -3px;
        width: calc(2rem + 6px);
        height: calc(2rem + 6px);
        box-sizing: border-box;
    }
`;

export const FormLegend = styled.legend`
    padding: 0;
`;

export const FormLegendLabel = styled(Heading).attrs({ level: 3 })`
    margin: 0;
`;

export const RadioCheckboxLegendLabel = styled.span`
    display: block;
    color: ${colors.forms.labelText};
    font-size: ${pxToRem(font.size.base)};
    font-weight: ${font.weight.medium};
`;

export const FormHelp = styled.span`
    font-size: ${pxToRem(14)};
    line-height: ${font.lineHeight.heading};
    color: ${colors.forms.helpText};
    margin: 0;
    display: block;
`;

export const FormButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${getSpacing(['xl'])};

    * + * {
        margin-top: ${getSpacing(['md'])};
    }

    ${getBreakpoint.md`
        flex-direction: row;
        justify-content: space-between;
        margin-top: ${getSpacing(['xxxl'])};

        * + * {
            margin-top: 0;
        }
    `}
`;

export default FormStyled;
