import * as React from 'react';
import { Box, Heading, RadioButton, ThemeContext } from 'grommet';
import TooltipComponent from 'app/common/TooltipComponent';
import radioColorTheme from '../utils/radioButtonTheme';
import FieldErrorMessage from './FieldErrorMessage';

type BookingLocationTypeProps = {
  name: string;
  onChange: (value: string) => void;
  hasError: boolean;
};

const radioOptionToolTip = (option) => (
  <Box direction="row" gap="xxxsmall" align="center" justify="start">
    <Box>{option.label}</Box>
    <Box>
      <TooltipComponent
        text={
          "Pop up location is also known as 'Plan ahead day' where we set up temporarily in a venue to provide services."
        }
      />
    </Box>
  </Box>
);

const BookingLocationType: React.FunctionComponent<BookingLocationTypeProps> = ({ name, onChange, hasError }) => {
  const [bookingLocation, setBookingLocation] = React.useState(null);

  const updateFieldValue = (value) => () => {
    setBookingLocation(value);
    onChange(value);
  };

  return (
    <Box margin={{ bottom: 'medium' }}>
      <Box>
        <Box direction="row" align="center" justify="start">
          <Box>
            <Heading level="5" margin={{ bottom: 'medium' }}>
              Choose a place *
            </Heading>
          </Box>
        </Box>
      </Box>
      <ThemeContext.Extend value={...radioColorTheme(hasError)}>
        <Box gap="small">
          <RadioButton
            label="At a branch"
            onChange={updateFieldValue('branch')}
            value="branch"
            checked={bookingLocation === 'branch'}
            name={name}
          />
          <RadioButton
            label={radioOptionToolTip({
              label: 'At a pop-up location',
              value: 'pop-up',
            })}
            onChange={updateFieldValue('pop-up')}
            value="pop-up"
            checked={bookingLocation === 'pop-up'}
            name={name}
          />
        </Box>
      </ThemeContext.Extend>
      {hasError && <FieldErrorMessage />}
    </Box>
  );
};

export default BookingLocationType;
