import * as React from 'react';
import { Heading } from 'grommet';

type Props = {
  title: string;
};

const HeadingOne: React.FunctionComponent<Props> = ({ title }: Props) => {
  return (
    <Heading level="1" size="32px" margin={{ bottom: '19px' }}>
      {title}
    </Heading>
  );
};

export default HeadingOne;
