import React from 'react';
import styled from 'styled-components/macro';
import {color, layout} from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const SpinnerSvg = ({className, color, theme, width, height}) =>
    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid'
        className={ className } viewBox='0 0 100 100' width={ width } height={ height } focusable='false' aria-hidden='true'>
        <path fill='none' d='M0 0h100v100H0z'/>
        <g transform='translate(84 50)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
        <g transform='rotate(45 -52.355 126.397)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0.12s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0.12s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
        <g transform='rotate(90 -17 67)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0.25s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0.25s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
        <g transform='rotate(135 -2.355 42.397)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0.37s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0.37s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
        <g transform='rotate(180 8 25)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0.5s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0.5s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
        <g transform='rotate(-135 18.355 7.603)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0.62s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0.62s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
        <g transform='rotate(-90 33 -17)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0.75s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0.75s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
        <g transform='rotate(-45 68.355 -76.397)'>
            <circle r='8' fill={ colors.legacy[color] || color }>
                <animate attributeName='opacity' from='1' to='.1' begin='0.87s' dur='1s' repeatCount='indefinite'/>
                <animateTransform attributeName='transform' type='scale' from='1.5' to='1' begin='0.87s' dur='1s'
                    repeatCount='indefinite'/>
            </circle>
        </g>
    </svg>;

const Spinner = styled(SpinnerSvg)`
  ${color}
  ${layout}
`;

Spinner.defaultProps = {
    color: `${colors.white}`,
    height: 14,
    width: 14,
};

export default Spinner;
