import Icons from '../../Icons/index';
import styled from 'styled-components/macro';

export const TickIcon = styled(Icons.TickCircle)`margin-right: 1rem;`;
export const ErrorIcon = styled(Icons.Cancel).attrs({
    height: 24,
    width: 24,
})`margin-right: 1rem;`;

export const StyledDiv = styled.div`display: flex;
                                    flex-wrap: wrap;
                                    align-items: baseline;`;
