import * as React from 'react';
const { createContext, useReducer } = React;

const PlanningFormContext = createContext<WillPlanningForm.Context>(null);

const initialState: WillPlanningForm.State = {
  personalQuestionsAnswers: [],
  assets: [],
  taskInfo: {
    status: 'initial',
    statusMessage: '',
    createDateTime: '',
    updateDateTime: '',
    submitDateTime: '',
  },
};

const reducer: WillPlanningForm.Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PERSONAL_QUESTIONS_ANSWERS':
      return {
        ...state,
        personalQuestionsAnswers: action.personalQuestionsAnswers,
      };
    case 'SET_ASSETS':
      return {
        ...state,
        assets: action.assets,
      };
    case 'ADD_ASSET':
      return {
        ...state,
        assets: [...state.assets, action.asset],
      };
    case 'EDIT_ASSET': {
      const newAssets = [...state.assets];
      newAssets[action.index] = action.asset;

      return {
        ...state,
        assets: [...newAssets],
      };
    }
    case 'DELETE_ASSET': {
      return {
        ...state,
        assets: state.assets.filter((_asset, index) => index !== action.index),
      };
    }
    case 'SET_TASK_INFO': {
      return {
        ...state,
        taskInfo: action.taskInfo,
      };
    }
    default:
      throw new Error();
  }
};

export const PlanningFormProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer<WillPlanningForm.Reducer>(reducer, initialState);

  const setAssets = async (assets: WillPlanningForm.AssetSubType[]) => {
    dispatch({
      type: 'SET_ASSETS',
      assets,
    });
  };

  const addAsset = async (asset: WillPlanningForm.AssetSubType) => {
    dispatch({
      type: 'ADD_ASSET',
      asset,
    });
  };

  const editAsset = async (asset: WillPlanningForm.AssetSubType, index: number) => {
    dispatch({
      type: 'EDIT_ASSET',
      asset,
      index,
    });
  };

  const deleteAsset = async (index: number) => {
    dispatch({
      type: 'DELETE_ASSET',
      index,
    });
  };

  const setPersonalQuestionsAnswers = async (
    personalQuestionsAnswers: WillPlanningForm.PersonalQuestionsAnswersState,
  ) => {
    dispatch({
      type: 'SET_PERSONAL_QUESTIONS_ANSWERS',
      personalQuestionsAnswers,
    });
  };

  const setTaskInfo = async (taskInfo: WillPlanningForm.TaskInfo) => {
    dispatch({
      type: 'SET_TASK_INFO',
      taskInfo,
    });
  };

  return (
    <PlanningFormContext.Provider
      value={{
        personalQuestionsAnswers: state.personalQuestionsAnswers,
        assets: state.assets,
        setPersonalQuestionsAnswers,
        setAssets,
        addAsset,
        editAsset,
        deleteAsset,
        taskInfo: state.taskInfo,
        setTaskInfo,
      }}
    >
      {children}
    </PlanningFormContext.Provider>
  );
};

export default PlanningFormContext;
