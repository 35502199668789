import * as React from 'react';
import PlanningService from 'planning/services/PlanningService';
import UserContext from 'app/features/user/context/UserContext';
import PlanningFormContext from '../context/PlanningFormContext';
import AssetListComponent from './AssetListComponent';

const getAssetsFromApiAndSetInContext = async (user: User, setAssets) => {
  try {
    const assets = await PlanningService.getAssets(user);
    setAssets(assets);
  } catch (error) {
    console.error('Could not fetch assets from API');
  }
};

const AssetListContainer: React.FunctionComponent = () => {
  const { assets: assetsInContext, setAssets, addAsset, deleteAsset, editAsset } = React.useContext(
    PlanningFormContext,
  );
  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    getAssetsFromApiAndSetInContext(user, setAssets);
  }, []);

  const addAssetToContextAndSaveInAPI = async (asset) => {
    addAsset(asset);
    PlanningService.setAssets(user, [...assetsInContext, asset]);
  };

  const editAssetInContextAndSaveInAPI = async (asset, index) => {
    const updatedAssets = [...assetsInContext];
    updatedAssets[parseInt(index)] = asset;

    editAsset(asset, index);

    PlanningService.setAssets(user, updatedAssets);
  };

  const deleteAssetInContextAndSaveInAPI = async (indexToDelete) => {
    const updatedAssets = assetsInContext.filter((_asset, index) => index !== indexToDelete);

    deleteAsset(indexToDelete);

    PlanningService.setAssets(user, updatedAssets);
  };

  return (
    <AssetListComponent
      assetList={assetsInContext}
      handleAddAsset={addAssetToContextAndSaveInAPI}
      handleEditAsset={editAssetInContextAndSaveInAPI}
      handleDeleteAsset={deleteAssetInContextAndSaveInAPI}
    />
  );
};

export default AssetListContainer;
