import * as React from 'react';
import { FieldProps } from '@rjsf/core';
import { Text, ResponsiveContext } from 'grommet';
const DescriptionField: React.FunctionComponent<FieldProps> = ({ description }) => {
  if (description) {
    return (
      <ResponsiveContext.Consumer>
        {(size) => <Text margin={{ bottom: size === 'small' ? 'none' : 'medium' }}>{description}</Text>}
      </ResponsiveContext.Consumer>
    );
  }

  return null;
};

export default DescriptionField;
