import * as React from 'react';
import { Box } from 'grommet';
import HeadingThree from 'app/common/HeadingThree';
import EditButtonComponent from './EditButtonComponent';

type Props = {
  text: string;
  goTo: string;
};

const SummaryHeaderComponent: React.FunctionComponent<Props> = ({ text, goTo }: Props) => {
  return (
    <Box direction="row" align="center" justify="between">
      <Box>
        <HeadingThree title={text} />
      </Box>
      <Box>
        <EditButtonComponent goTo={goTo} />
      </Box>
    </Box>
  );
};

export default SummaryHeaderComponent;
