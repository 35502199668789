import * as React from 'react';
import { Box, Heading, Text } from 'grommet';
import ActionModal from './ActionModal';
import { redirectTo } from './navigation';

type Props = {
  onModalClose: () => void;
  redirectUrl: string;
};

const LeavingFinalsAffairsModal: React.FunctionComponent<Props> = ({ onModalClose, redirectUrl }: Props) => {
  return (
    <ActionModal onModalClose={onModalClose} handleContinue={() => redirectTo(redirectUrl)}>
      <Box>
        <Heading level="3" margin={{ bottom: 'large' }}>
          You are leaving the End of Life planner
        </Heading>
      </Box>
    </ActionModal>
  );
};

export default LeavingFinalsAffairsModal;
