import PlanningService from 'app/features/planning/services/PlanningService';
import UserContext from 'app/features/user/context/UserContext';
import ControlStatesContext, { status } from 'app/features/control-states/context/ControlStatesContext';
import * as React from 'react';
import { getCurrentPageName, willPlanningFlowRouteNames } from 'app/common/routes';
import { useLocation } from 'react-router';

const updateLastVisitedPageInContextAndApi = (setLastPageVisitedInContext, setHasReachedEndOfFlowInContext) => (
  lastPageVisited: string,
  currentPath: string,
  hasReachedEndOfFlow: boolean,
) => {
  const currentPage = getCurrentPageName(currentPath);

  if (canUpdateLastVisitedPage(currentPage, lastPageVisited)) {
    setLastPageVisitedInContext(currentPage);
  }

  if (!hasReachedEndOfFlow && currentPage === 'SUMMARY') {
    setHasReachedEndOfFlowInContext(true);
  }
};

const canUpdateLastVisitedPage = (currentPage, lastPageVisited) => {
  return currentPage !== lastPageVisited && Object.values(willPlanningFlowRouteNames).includes(currentPage);
};

const UserInteractions: React.FunctionComponent = () => {
  const { pathname } = useLocation();
  const { user } = React.useContext(UserContext);

  const {
    loadUserInteractions,
    setLastPageVisited,
    setHasReachedEndOfFlow,
    setStatus,
    lastPageVisited,
    hasReachedEndOfFlow,
  } = React.useContext(ControlStatesContext);

  const updateLastVisitedPage = updateLastVisitedPageInContextAndApi(setLastPageVisited, setHasReachedEndOfFlow);

  React.useEffect(() => {
    PlanningService.getUserInteractions(user)
      .then((userInteractions) => {
        return loadUserInteractions(userInteractions);
      })
      .then(() => {
        setStatus(status.loaded);
      })
      .catch(() => {
        setStatus(status.error);
      });
  }, []);

  React.useEffect(() => {
    updateLastVisitedPage(lastPageVisited, pathname, hasReachedEndOfFlow);
  }, [pathname]);

  return null;
};

export default UserInteractions;
