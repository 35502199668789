import * as React from 'react';
import ActionModal from 'app/common/ActionModal';
import { Box, Heading, Anchor } from 'grommet';
import UserContext from 'app/features/user/context/UserContext';
import { Button } from '@snsw/react-component-library';
import { logout } from 'app/features/user/components/AuthService';
import { redirectTo } from './navigation';

type Props = {
  type?: string;
};

const Logout: React.FunctionComponent<Props> = ({ type }) => {
  const { setUser } = React.useContext(UserContext);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = React.useState(false);

  const handleLogoutClick = () => {
    setIsLogoutModalVisible(true);
  };

  const handleContinue = async () => {
    await logout();
    setUser(null);
    redirectTo(process.env.LOGOUT_REDIRECT);
  };

  return (
    <>
      {type === 'button' ? (
        <Button theme="secondary" type="button" onClick={handleLogoutClick} className="button-dcs">
          Log out
        </Button>
      ) : (
        <Anchor onClick={handleLogoutClick} size="medium" label="Log out" color="white" />
      )}
      {isLogoutModalVisible && (
        <ActionModal
          onModalClose={() => setIsLogoutModalVisible(false)}
          handleContinue={handleContinue}
          continueButtonText="Log out"
          style={{ backgroundColor: '#FFFFFF', color: '#444444' }}
        >
          <Box pad={{ bottom: 'large' }}>
            <Heading level="2" size="small" margin={{ top: 'none' }}>
              Are you sure you want to log out?
            </Heading>
          </Box>
        </ActionModal>
      )}
    </>
  );
};

export default Logout;
