import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icons from './../Icons/index';
import SlidingModal from './SlidingModal';
import StyledModal from './Modal.styled';
import styled from 'styled-components/macro';
import Button from '../Components/Button/Button';
import { getID } from '../utils';

export const IconButton = styled.button`
    cursor: pointer;
    display: inline-flex;
    background: none;
    border: none;
    padding: 0;

    svg {
        margin: 0;
    }
`;

export const MoreInfoModal = ({
    id,
    title,
    subTitle,
    helpText,
    questionIconFlag,
    questionHyperLink,
    hyperLinKText
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [returnFocus, setReturnFocus] = useState(null);

    const toggleModal = () => {
        setIsOpen(!isOpen);

        if(isOpen){
            returnFocus.focus();
        }
        else{
            setReturnFocus(document.activeElement);
        }
    };

    const genID = () => {
        return id || getID();
    };

    const elemID = genID();
    const idTitle = `${elemID}-title`;
    const idDesc = `${elemID}-desc`;

    if (!helpText) {
        return null;
    }

    return (
        <>
            {!questionHyperLink &&
                <IconButton type='button' onClick={ toggleModal }>
                    {questionIconFlag && <Icons.Info color='#2E5299' />}
                    {!questionIconFlag && <Icons.Question color='#2E5299' />}
                </IconButton>
            }
            {questionHyperLink && !questionIconFlag &&
                <Button onClick={ toggleModal } theme='link'>
                    {hyperLinKText}
                </Button>
            }
            <SlidingModal open={ isOpen } onClose={ toggleModal }>
                <StyledModal.Header id={ idTitle } slidingPanel>
                    {title}<br/>
                    {subTitle}
                </StyledModal.Header>
                <StyledModal.Body id={ idDesc }>
                    {helpText}
                </StyledModal.Body>
            </SlidingModal>
        </>
    );
};

MoreInfoModal.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    helpText: PropTypes.node,
    questionIconFlag: PropTypes.bool,
    questionHyperLink: PropTypes.bool,
    hyperLinKText: PropTypes.string
};

MoreInfoModal.defaultProps = {
    questionIconFlag: false,
    questionHyperLink: false
};
