import { Box, ResponsiveContext } from 'grommet';
import * as React from 'react';
import { Button } from '@snsw/react-component-library';

type Props = {
  handleBack?: () => void;
  textBack?: string;
  nextButton: JSX.Element;
};

const StepNavigationComponent: React.FunctionComponent<Props> = ({
  handleBack,
  textBack = 'Back',
  nextButton,
}: Props) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Box
      direction={size === 'small' ? 'column-reverse' : 'row-responsive'}
      justify="between"
      align="center"
      gap="medium"
    >
      <Box fill={size === 'small' ? 'horizontal' : false}>
        <Button theme="secondary" type="button" onClick={handleBack} className="button-dcs">
          {textBack}
        </Button>
      </Box>
      <Box fill={size === 'small' ? 'horizontal' : false}>{nextButton}</Box>
    </Box>
  );
};

export default StepNavigationComponent;
