import * as React from 'react';
const { createContext, useReducer } = React;
import PlanningService from '../../planning/services/PlanningService';
import UserContext from '../../user/context/UserContext';

const ControlStatesContext = createContext<ControlStates.Context>(null);

export const status = {
  loading: 'loading',
  loaded: 'loaded',
  error: 'error',
} as const;

const initialState: ControlStates.State = {
  termsAndConditions: { timestamp: 0, hasAccepted: false, userAction: '' },
  lastPageVisited: '',
  hasReachedEndOfFlow: false,
  isOverEighteen: '',
  status: status.loading,
};

const reducer: ControlStates.Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TERMS_AND_CONDITIONS':
      return {
        ...state,
        termsAndConditions: action.termsAndConditions,
      };
    case 'SET_LAST_PAGE_VISITED':
      return {
        ...state,
        lastPageVisited: action.lastPageVisited,
      };
    case 'SET_HAS_REACHED_END_OF_FLOW':
      return {
        ...state,
        hasReachedEndOfFlow: action.hasReachedEndOfFlow,
      };
    case 'SET_IS_OVER_EIGHTEEN':
      return {
        ...state,
        isOverEighteen: action.isOverEighteen,
      };
    case 'SET_STATUS':
      return {
        ...state,
        status: action.status,
      };
    default:
      throw new Error();
  }
};

export const ControlStatesProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer<ControlStates.Reducer>(reducer, initialState);
  const { user } = React.useContext(UserContext);

  const updateUserInteractions = async (userInteractions) => {
    PlanningService.updateUserInteractions(user, userInteractions);
  };

  const loadUserInteractions = async (userInteractions) => {
    dispatch({
      type: 'SET_TERMS_AND_CONDITIONS',
      termsAndConditions: userInteractions.termsAndConditions,
    });
    dispatch({
      type: 'SET_LAST_PAGE_VISITED',
      lastPageVisited: userInteractions.lastPageVisited,
    });
    dispatch({
      type: 'SET_HAS_REACHED_END_OF_FLOW',
      hasReachedEndOfFlow: userInteractions.hasReachedEndOfFlow,
    });
    dispatch({
      type: 'SET_IS_OVER_EIGHTEEN',
      isOverEighteen: userInteractions.isOverEighteen,
    });
  };

  const setTermsAndConditions = async (termsAndConditions) => {
    dispatch({
      type: 'SET_TERMS_AND_CONDITIONS',
      termsAndConditions,
    });
    await updateUserInteractions({ termsAndConditions });
  };

  const setLastPageVisited = async (lastPageVisited) => {
    dispatch({
      type: 'SET_LAST_PAGE_VISITED',
      lastPageVisited,
    });
    await updateUserInteractions({ lastPageVisited });
  };

  const setHasReachedEndOfFlow = async (hasReachedEndOfFlow) => {
    dispatch({
      type: 'SET_HAS_REACHED_END_OF_FLOW',
      hasReachedEndOfFlow,
    });
    await updateUserInteractions({ hasReachedEndOfFlow });
  };

  const setIsOverEighteen = async (isOverEighteen) => {
    dispatch({
      type: 'SET_IS_OVER_EIGHTEEN',
      isOverEighteen,
    });
    await updateUserInteractions({ isOverEighteen: isOverEighteen });
  };

  const setStatus = async (status: ControlStates.ControlStatesStatus) => {
    dispatch({
      type: 'SET_STATUS',
      status,
    });
  };

  const { termsAndConditions, lastPageVisited, hasReachedEndOfFlow, isOverEighteen, status } = state;

  return (
    <ControlStatesContext.Provider
      value={{
        termsAndConditions,
        lastPageVisited,
        hasReachedEndOfFlow,
        isOverEighteen,
        status,
        setTermsAndConditions,
        setLastPageVisited,
        setHasReachedEndOfFlow,
        loadUserInteractions,
        setIsOverEighteen,
        setStatus,
      }}
    >
      {children}
    </ControlStatesContext.Provider>
  );
};

export default ControlStatesContext;
