import * as React from 'react';
import { Box, Heading } from 'grommet';
import { FormSelect } from '@snsw/react-component-library';

type BookingLocationProps = {
  name: string;
  options: Array<{ text: string; value: string }>;
  onChange: (value: string) => void;
  hasError?: boolean;
};

const updateLocation = (onChange) => (event) => {
  onChange(event.target.value);
};

const BookingLocation: React.FunctionComponent<BookingLocationProps> = ({ name, onChange, options, hasError }) => {
  return (
    <Box margin={{ bottom: 'medium' }}>
      <Box>
        <Box direction="row" align="center" justify="start" gap="xxsmall">
          <Box>
            <Heading level="5">Select a location *</Heading>
          </Box>
        </Box>
      </Box>
      <Box>
        <FormSelect
          name={name}
          options={options}
          placeholder="Select"
          onChange={updateLocation(onChange)}
          hasError={hasError}
          errorMessage={'Please select an option'}
        />
      </Box>
    </Box>
  );
};

export default BookingLocation;
