const font = {
    family: '\'Gotham A\', \'Gotham B\', Helvetica, Arial, sans-serif',
    weight: {
        book: 300,
        medium: 500,
        bold: 700
    },
    size: {
        base: 16,
        sm: 18,
        md: 20,
        lg: 22,
        xl: 26,
        xxl: 28,
        xxxl: 32,
        xxxxl: 36
    },
    lineHeight: {
        default: 1.5,
        heading: 1.4
    },
    typeSize: {
        sm: [
            16,
            16
        ],
        md: [
            18,
            18
        ],
        lg: [
            20,
            22
        ],
        xl: [
            26,
            28
        ],
        xxl: [
            32,
            36
        ]
    }
};

export default font;