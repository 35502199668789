import React from 'react';
import PropTypes from 'prop-types';
import { FormLegend, FormLegendLabel, FormFieldset } from './Form.styled';
import Field from './Field.styled';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';

const Fieldset = ({
    children,
    className,
    legend,
    hasError,
    helpMessage,
    errorMessage
}) => {

    return (
        <FormFieldset
            className={ className }
            aria-invalid={ hasError }
        >
            {legend && <FormLegend>
                <FormLegendLabel>{legend}</FormLegendLabel>
                {helpMessage && (
                    <Field.GroupHelp>{helpMessage}</Field.GroupHelp>
                )}
                {hasError && errorMessage && <SROnly>{errorMessage}</SROnly>}
            </FormLegend>}
            {children}
        </FormFieldset>
    );
};

Fieldset.defaultProps = {
    hasError: false,
};

Fieldset.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    legend: PropTypes.string,
    hasError: PropTypes.bool,
    helpMessage: PropTypes.string,
    errorMessage: PropTypes.string
};

export default Fieldset;
