import { ErrorIcon, StyledDiv, TickIcon } from './HeadingWithIcon.styled';

import Heading from './Heading';
import PropTypes from 'prop-types';
import React from 'react';

const getIcon = (iconType) => {
    switch (iconType) {
        case 'error':
            return <ErrorIcon />;
        default:
            return <TickIcon />;
    }
};

const HeadingWithIcon = ({
    children,
    className,
    level,
    type
}) => {
    return (
        <StyledDiv>
            { getIcon(type) }
            <Heading className={ className } level={ level }>{ children }</Heading>
        </StyledDiv>
    );
};

HeadingWithIcon.defaultProps = {
    level: 1,
    type: ''
};

HeadingWithIcon.propTypes = {
    className: PropTypes.string,
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    ]),
    children: PropTypes.string,
    type: PropTypes.string,
    // info: PropTypes.bool,
    // error: PropTypes.bool,
    // warning: PropTypes.bool,
    // success: PropTypes.bool,
};

export default HeadingWithIcon;
