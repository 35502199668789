import * as React from 'react';
import { Accordion, AccordionPanel, Box, Text } from 'grommet';
import { RoundType } from 'grommet/utils';
import { Button } from '@snsw/react-component-library';
import { getAssetTitle } from '../utils/assetTypesSelectOptions';
import NewAssetForm from './NewAssetForm';
import EditAssetForm from './EditAssetForm';
import { AccordionLabel, CustomAccordionPanel } from './AssetListComponent.styled';

const ASSET_PREPEND_ID = 'asset-';

type Props = {
  assetList: WillPlanningForm.Asset[];
  handleAddAsset: (asset: WillPlanningForm.AssetSubType) => void;
  handleEditAsset: (asset: WillPlanningForm.AssetSubType, index: number) => void;
  handleDeleteAsset: (index: number) => void;
};

const AssetListComponent: React.FunctionComponent<Props> = ({
  assetList,
  handleAddAsset,
  handleEditAsset,
  handleDeleteAsset,
}: Props) => {
  const [isAddMoreAvailable, setIsAddMoreAvailable] = React.useState(assetList?.length === 0);
  const [accordionActiveIndex, setAccordionActiveIndex] = React.useState(assetList?.length === 0 ? [0] : null);

  const handleOnActive = (indexes) => {
    setAccordionActiveIndex(indexes);
    setIsAddMoreAvailable(false);
  };

  const addAsset = (asset, type) => {
    setIsAddMoreAvailable(false);
    handleAddAsset({
      type,
      ...asset,
    });
    const lastAssetId =
      assetList && assetList?.length > 0
        ? ASSET_PREPEND_ID + (assetList?.length - 1).toString()
        : 'assetListAddAnAsset';
    document.getElementById(lastAssetId) && window.screen.width < 768
      ? document.getElementById(lastAssetId).scrollIntoView()
      : null;
    // console.log(lastAssetId);
  };

  const handleAddAnother = () => {
    setIsAddMoreAvailable(true);
    setAccordionActiveIndex([assetList?.length]);
  };

  const editAsset = (asset: WillPlanningForm.AssetSubType, index: number) => {
    handleEditAsset(asset, index);
    setAccordionActiveIndex([]);
  };

  const deleteAsset = (index: number) => {
    handleDeleteAsset(index);
    setAccordionActiveIndex([]);
  };

  React.useEffect(() => {
    setIsAddMoreAvailable(assetList?.length === 0);
    setAccordionActiveIndex(assetList?.length === 0 ? [0] : null);
  }, [assetList]);

  return (
    <Box width="large" gap="medium" margin={{ bottom: 'xlarge' }}>
      <Box>
        <Accordion
          activeIndex={accordionActiveIndex}
          onActive={handleOnActive}
          border={{ color: '#C4D0D6', size: 'small' }}
          round="1%"
        >
          {assetList?.map((asset, index: number) => {
            const assetTypeLabel = getAssetTitle(asset);

            const isLastItem = assetList.length - 1 === index;
            const hasMoreThanOne = assetList.length > 1;
            const isFirstItem = index === 0;

            const round = isLastItem
              ? ({ size: '1%', corner: 'bottom' } as RoundType)
              : isFirstItem
              ? ({ size: '1%', corner: 'top' } as RoundType)
              : null;
            return (
              <Box
                background={accordionActiveIndex && accordionActiveIndex[0] === index ? '' : '#F9FDFF'}
                key={index}
                pad="medium"
                fill
                border={
                  isAddMoreAvailable || (hasMoreThanOne && !isLastItem) ? { color: '#C4D0D6', side: 'bottom' } : null
                }
                round={round}
                id={ASSET_PREPEND_ID + index.toString()}
              >
                <CustomAccordionPanel
                  label={
                    <AccordionLabel>
                      <Text truncate={true}>{assetTypeLabel}</Text>
                    </AccordionLabel>
                  }
                >
                  <EditAssetForm
                    currentAsset={asset}
                    handleOnSubmit={(
                      asset: Omit<WillPlanningForm.AssetSubType, 'type'>,
                      assetType: WillPlanningForm.AssetType,
                    ) =>
                      editAsset(
                        {
                          type: assetType,
                          ...asset,
                        } as WillPlanningForm.AssetSubType,
                        index,
                      )
                    }
                    handleOnDelete={() => deleteAsset(index)}
                  />
                </CustomAccordionPanel>
              </Box>
            );
          })}
          {isAddMoreAvailable && (
            <Box pad={{ left: 'medium', top: 'small', bottom: 'medium', right: 'medium' }}>
              <AccordionPanel header={<></>}>
                <NewAssetForm handleOnSubmit={addAsset} />
              </AccordionPanel>
            </Box>
          )}
        </Accordion>
      </Box>
      {!isAddMoreAvailable && (
        <Box width="small" id="assetListAddAnotherAssetButton">
          <Button theme="primary" className="primary-button-dcs" type="button" onClick={handleAddAnother}>
            Add another asset
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AssetListComponent;
