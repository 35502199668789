import React, { useEffect, useState } from 'react';
import { bsb } from '../../Validation/Masks';

import FormInput from '../../Components/Form/FormInput';
import Heading from '../../Components/Headings/Heading';
import PropTypes from 'prop-types';
import { getErrorMessage } from '../../Validation/ErrorMessages';
import {
    bankAccountNameDoesNotMatchRegex,
    bankAccountNumberDoesNotMatchRegex,
    isMaskInputIncomplete
} from '../../Validation/Validators';

const BankPage = ({
    accountNameLabel,
    topHelpText,
    onChange,
    initialValues
}) => {
    const initialValueInit = () => {
        const result = initialValues || {
            accountName: { value: '', hasError: true },
            bsb: { value: '', hasError: true },
            accountNumber: { value: '', hasError: true }
        };
        return result;
    };
    const [bankDetails, setBankDetails] = useState(initialValueInit());

    const handleAccNameChange = (e, event) => {
        setBankDetails({ ...bankDetails, accountName: event });
    };

    const handleBsbChange = (e, event) => {
        setBankDetails({ ...bankDetails, bsb: event });
    };

    const handleAccNumberChange = (e, event) => {
        setBankDetails({ ...bankDetails, accountNumber: event });
    };

    const accountNumberValidate = value => {
        if (bankAccountNumberDoesNotMatchRegex(value)) {
            return getErrorMessage('bankAccountNumberFormat');
        }
    };

    const accountNameValidate = value => {
        if (bankAccountNameDoesNotMatchRegex(value)) {
            return getErrorMessage('bankAccountNameFormat');
        }
    };

    const maskInputOnValidate = value => {
        if (isMaskInputIncomplete(value)) {
            return getErrorMessage('required');
        }
    };
    useEffect(() => {
        onChange(bankDetails);
    }, [bankDetails, onChange]);

    return (
        <>
            <Heading level={ 2 }>Payment details</Heading>
            <p>{ topHelpText }</p>
            <FormInput
                label={ accountNameLabel }
                id={ 'accName' }
                name={ 'accountName' }
                value={ bankDetails?.accountName?.value }
                onChange={ handleAccNameChange }
                onValidate={ accountNameValidate }
            />
            <FormInput
                helpMessage={ 'Enter your 6 digit BSB' }
                label={ 'BSB' }
                mask={ bsb }
                name={ 'bsb' }
                onChange={ handleBsbChange }
                value={ bankDetails?.bsb?.value }
                onValidate={ maskInputOnValidate }
            />
            <FormInput
                helpMessage={ 'Enter your account number' }
                label={ 'Account number' }
                name={ 'accountNumber' }
                onChange={ handleAccNumberChange }
                value={ bankDetails?.accountNumber.value }
                onValidate={ accountNumberValidate }
            />
        </>
    );
};

BankPage.propTypes = {
    accountNameLabel: PropTypes.string,
    onChange: PropTypes.func,
    topHelpText: PropTypes.string,
    initialValues: PropTypes.object
};

BankPage.defaultProps = {
    accountNameLabel: '',
    topHelpText: ''
};

export default BankPage;
