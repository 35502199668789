import React, { useEffect } from 'react';

import Icons from '../../Icons';
import PropTypes from 'prop-types';
import StyledButton from './Button.styled';
import { trackMe } from '../ComponentTracker/componentTracker';

const Button = ({
    theme,
    size,
    href,
    disabled,
    external,
    onClick,
    children,
    type,
    className,
    as,
    ...otherOptions
}) => {

    useEffect(() => {
        trackMe('Button');
    }, []);

    if (typeof as !== 'object' && href) {
        otherOptions.href = href;
        otherOptions.as = 'a';

        if (external) {
            otherOptions.external = external ? 1 : 0;
            otherOptions.target = '_blank';
            otherOptions.rel = 'noopener noreferrer';
        }
    }
    else if (typeof as === 'object' && href) {
        otherOptions.to = href;
        otherOptions.as = as;
        otherOptions.onClick = onClick;
    }
    else {
        otherOptions.type = type;
        otherOptions.onClick = onClick;
        otherOptions.disabled = disabled;
        otherOptions.external = external ? 1 : 0;
    }

    return (
        <StyledButton
            className={ className }
            theme={ theme }
            size={ size }
            { ...otherOptions }
        >
            {theme === 'back' && <Icons.ChevronLeft /> }
            {theme === 'back' ? 'Back' : children}
            {external && theme !== 'back' && theme !== 'arrow' && typeof as !== 'object' && <Icons.ExternalLink />}
            {theme === 'arrow' && <Icons.Arrow /> }
        </StyledButton>
    );
};

Button.defaultProps = {
    theme: 'primary',
    size: 'default',
    disabled: false,
    external: false,
    type: 'button',
};

Button.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link', 'arrow', 'back']),
    size: PropTypes.oneOf(['default', 'small', 'xl', 'xxl', 'short']),
    href: PropTypes.string,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    as: PropTypes.object
};

export default Button;
