export const routes = {
  REDIRECT_TO_MY_ACCOUNT: '/start-planning',
  PLANNER_BASE_PATH: '/end-of-life-planner',
  HOME: '/end-of-life-planner/home',
  GET_LEGAL_ADVICE: '/end-of-life-planner/legal-advice',
  USER_DETAILS: '/end-of-life-planner/your-details',
  QUESTIONS: '/end-of-life-planner/questions',
  ASSETS: '/end-of-life-planner/assets',
  SUMMARY: '/end-of-life-planner/summary',
  BOOKING: '/end-of-life-planner/book-a-meeting',
  BOOKING_CONFIRMATION: '/end-of-life-planner/booking-confirmation',
  BOOKING_ERROR: '/end-of-life-planner/booking-error',
  AUTH_CALLBACK: '/auth/callback',
  DOCUMENT_LOCATION: '/end-of-life-planner/document-location',
  ENDURING_GUARDIAN_STATUS: '/end-of-life-planner/enduring-guardian-status',
  ENDURING_GUARDIAN_DETAILS: '/end-of-life-planner/enduring-guardian-details',
  ENDURING_GUARDIAN_ADVANCE_CARE_DIRECTIVE: '/end-of-life-planner/enduring-guardian-advance-care-directive',
};

export const willPlanningFlowRouteNames = {
  USER_DETAILS: 'USER_DETAILS',
  ASSETS: 'ASSETS',
  QUESTIONS: 'QUESTIONS',
  SUMMARY: 'SUMMARY',
};

export const getCurrentPageName = (currentPath: string): string => {
  return Object.keys(routes).find((route) => {
    // eslint-disable-next-line security/detect-object-injection
    return routes[route] === currentPath;
  });
};
