import * as React from 'react';
import { Accordion, Box } from 'grommet';
import HeadingOne from 'app/common/HeadingOne';
import StepNavigationComponent from 'app/features/planning/components/StepNavigationComponent';
import { routes } from 'app/common/routes';
import { useHistory } from 'react-router';
import HeadingTwo from 'app/common/HeadingTwo';
import UserContext from 'app/features/user/context/UserContext';
import { Button } from '@snsw/react-component-library';
import { updateAcceptTerms } from 'common/services/DataService';
import DocumentLocationDataService from '../services/DocumentLocationDataService';
import BackLinkComponent from '../../planning/components/BackLinkComponent';
import Declaration from '../../../common/Declaration';
import { AddIcon } from './AddIcon';
import WillPanel from './WillPanel';
import EnduringPowerOfAttorneyPanel from './EnduringPowerOfAttorneyPanel';
import { EnduringGuardianPanel } from './EnduringGuardianPanel';
import OtherPanel from './OtherPanel';

export const DocumentType = {
  Will: {
    label: 'Will',
    value: 'will',
  },
  EnduringPowerOfAttorney: {
    label: 'Enduring Power of Attorney',
    value: 'epoa',
  },
  EnduringGuardianship: {
    label: 'Enduring Guardianship',
    value: 'eg',
  },
  Other: {
    label: 'Other important documents',
    value: 'other',
  },
} as const;

export const Location = {
  Solicitor: {
    label: "At my Solicitor's",
    value: 'solicitor',
  },
  Bank: {
    label: 'In a bank vault',
    value: 'bank',
  },
  TrueteeAndGuardian: {
    label: 'NSW Trustee & Guardian',
    value: 't&g',
  },
  Home: {
    label: 'At home',
    value: 'home',
  },
  Other: {
    label: 'Other',
    value: 'other',
  },
} as const;

export const INITIAL_STATE = {
  will: { saved: false, type: DocumentType.Will.value },
  epoa: { saved: false, type: DocumentType.EnduringPowerOfAttorney.value },
  eg: { saved: false, type: DocumentType.EnduringGuardianship.value },
  others: [{ saved: false, type: DocumentType.Other.value }],
};

export const theme = (hasData) => {
  return hasData
    ? {} // no theme change..
    : {
        accordion: {
          icons: {
            expand: AddIcon,
          },
        },
      };
};

const disclaimerTerms = [
  'I acknowledge that this service, and any information I provide are not instructions for, or a draft of, or a final will, Power of Attorney or appointment of Enduring Guardian.  Nor does it cancel or alter any of these documents.',
  'I agree to releasing any information, personal or otherwise, I have stored on this service/portal to any person who may be eligible under the laws of New South Wales or Australia, including a court or tribunal who may request information for any purpose related to my current or future documents contained within the scope of this service.',
  'I understand that while using this part of our service I will be asked to share personal information relating to my finances and my personal wishes about your decisions.  This information is used only to provide me with this service and is not shared outside of the End of Life planning program without my express permission.',
];

const DocumentLocation: React.FunctionComponent = () => {
  const { user } = React.useContext(UserContext);
  const [documentLocation, setDocumentLocation] = React.useState(INITIAL_STATE);
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const handleOnActive = (indexes) => {
    setActiveIndex(indexes);
  };
  const closeAccordion = () => {
    setActiveIndex([]);
  };

  React.useEffect(() => {
    const loadDocumentLocation = async () => {
      let hasAcceptedTerms = false;
      try {
        const payload = await DocumentLocationDataService.getDocumentLocations(user);
        setDocumentLocation({
          ...documentLocation,
          ...payload.documentLocation,
        });
        hasAcceptedTerms = !!payload.userDetails?.acceptTermsDateTime || false;
      } catch (err) {
        console.warn('documentLocation not found. 1st time in?');
      }
      setVisibleModal(!hasAcceptedTerms);
    };
    loadDocumentLocation();
  }, []);

  const history = useHistory();

  const saveDocumentLocation = async (updated) => {
    console.log('updated', updated);
    setDocumentLocation(updated);
    await DocumentLocationDataService.updateDocumentLocations(user, updated);
  };

  const handleBack = () => {
    history.push(routes.HOME);
  };

  const closeModal = () => {
    setVisibleModal(false);
    history.push(routes.HOME);
  };

  const saveAcknowledgement = async () => {
    await updateAcceptTerms(user, 'documentLocation');
    setVisibleModal(false);
  };

  const needsNewOther = (others) => {
    let needsNewOther = true;
    for (const other of others) {
      if (!other.saved) {
        needsNewOther = false;
      }
    }
    return needsNewOther;
  };

  if (needsNewOther(documentLocation.others)) {
    documentLocation.others.push({ saved: false, type: DocumentType.Other.value });
  }

  const otherPanels = documentLocation.others.map((other, index) => (
    <OtherPanel
      key={index}
      activeIndex={activeIndex}
      documentLocation={documentLocation}
      saveDocumentLocation={saveDocumentLocation}
      otherIndex={index}
      closeAccordion={closeAccordion}
    />
  ));

  return (
    <>
      <Box>
        <BackLinkComponent
          handleBack={() => {
            history.push(routes.HOME);
          }}
        />
      </Box>
      <HeadingOne title="Will and other documents location" />
      <Box margin={{ bottom: '34px' }}>
        <p style={{ fontSize: '16px' }}>
          Storing your important documents in a safe place, as well as letting your executor, beneficiaries and family
          know where they&apos;re located will ensure that your wishes are known.
        </p>
      </Box>
      <HeadingTwo title="Documents location" />
      <Box width="large" gap="medium" margin={{ bottom: 'xlarge' }}>
        <Accordion
          activeIndex={activeIndex}
          onActive={handleOnActive}
          border={{ color: '#C4D0D6', size: 'small' }}
          round="1%"
        >
          <WillPanel
            activeIndex={activeIndex}
            documentLocation={documentLocation}
            saveDocumentLocation={saveDocumentLocation}
            closeAccordion={closeAccordion}
            accordionIndex={0}
          />
          <EnduringPowerOfAttorneyPanel
            activeIndex={activeIndex}
            documentLocation={documentLocation}
            saveDocumentLocation={saveDocumentLocation}
            closeAccordion={closeAccordion}
            accordionIndex={1}
          />
          <EnduringGuardianPanel
            activeIndex={activeIndex}
            documentLocation={documentLocation}
            saveDocumentLocation={saveDocumentLocation}
            closeAccordion={closeAccordion}
            accordionIndex={2}
          />
          {otherPanels}
        </Accordion>
      </Box>
      <Box margin={{ bottom: 'medium' }}>
        <StepNavigationComponent
          handleBack={() => {
            history.push(routes.HOME);
          }}
          nextButton={
            <Button theme="primary" className="primary-button-dcs" type="button" onClick={handleBack}>
              Return to Homepage
            </Button>
          }
        />
      </Box>
      {visibleModal && (
        <Declaration closeModal={closeModal} saveAcknowledgement={saveAcknowledgement} terms={disclaimerTerms} />
      )}
    </>
  );
};

export default DocumentLocation;
