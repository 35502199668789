import * as React from 'react';
import { Box, Card, Text } from 'grommet';

type Props = {
  title?: string;
  text: string;
  backgroundColor: string;
  borderColor: string;
  icon: JSX.Element;
};

const HighlightCard: React.FunctionComponent<Props> = ({ title, text, backgroundColor, borderColor, icon }: Props) => {
  return (
    <Card
      width="large"
      background={backgroundColor}
      border={{ side: 'left', color: borderColor, size: 'medium' }}
      round=""
      elevation="none"
      pad={{ vertical: '26px', right: '26px' }}
      margin={{ bottom: 'medium' }}
    >
      <Box direction="row">
        <Box width={{ min: 'xxsmall', max: 'xxsmall' }} margin={{ left: 'xxxxsmall', right: 'xxxsmall' }}>
          {icon}
        </Box>
        <Box>
          {title && <Text weight="bold">{title}</Text>}
          <Text>{text}</Text>
        </Box>
      </Box>
    </Card>
  );
};

export default HighlightCard;
