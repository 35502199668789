import { Box, Image, RadioButtonGroup, Text } from 'grommet';
import * as React from 'react';
import { useHistory } from 'react-router';
import warnIcon from 'app/common/assets/warning.svg';
import HeadingOne from 'app/common/HeadingOne';
import HeadingThree from 'app/common/HeadingThree';
import ParagraphText from 'app/common/ParagraphText';
import WarningCard from 'app/common/WarningCard';
import UserContext from 'app/features/user/context/UserContext';
import ControlStatesContext from 'app/features/control-states/context/ControlStatesContext';
import ErrorIcon from 'app/common/assets/error.svg';
import { routes } from 'app/common/routes';
import { Button } from '@snsw/react-component-library';
import StepNavigationComponent from '../planning/components/StepNavigationComponent';
import BackLinkComponent from '../planning/components/BackLinkComponent';
import UserDetailsService from './UserDetailsService';
import YourDetailsComponent from './YourDetailsComponent';

const WarnIcon = <Image src={warnIcon} />;

const UserDetails: React.FunctionComponent = () => {
  const { isOverEighteen, setIsOverEighteen } = React.useContext(ControlStatesContext);
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const [validationError, setValidationError] = React.useState(false);

  const handleSave = async () => {
    if (isOverEighteen) {
      setValidationError(false);

      await UserDetailsService.updateUserDetails(user, { isOverEighteen: isOverEighteen });

      history.push(routes.HOME);
    } else {
      setValidationError(true);
    }
  };

  const handleBack = () => {
    history.push(routes.HOME);
  };

  const hasAgeSelectedAndDoesNotHaveAge = () => {
    return isOverEighteen !== null && 'no' === `${isOverEighteen}`;
  };

  const handleEligibilityRadioOnChange = (value) => {
    setIsOverEighteen(value);
    setValidationError(false);
  };

  return (
    <Box>
      <Box>
        <Box>
          <Box>
            <BackLinkComponent handleBack={handleBack} />
          </Box>
          <Box>
            <HeadingOne title="Personal details" />
          </Box>
          <Box>
            <ParagraphText text="Please check that your personal information is up to date and correct." />
          </Box>
        </Box>
        <YourDetailsComponent user={user} />
      </Box>
      <Box border={{ size: 'xsmall' }} />
      <Box pad={{ vertical: 'medium' }}>
        <Box>
          <Box>
            <HeadingThree title="Eligibility" />
          </Box>
          <Box>
            <Text margin={{ bottom: 'small' }}>Are you 18 years old or over?</Text>
          </Box>
        </Box>
        <Box gap="small" margin={{ bottom: 'small' }}>
          <Box>
            <RadioButtonGroup
              onChange={(event) => handleEligibilityRadioOnChange(event.target.value)}
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              name="eligibilityRadio"
              value={isOverEighteen}
            />
          </Box>
          {validationError && (
            <Box>
              <Box direction="row" align="center" gap="small">
                <Box>
                  <Image src={ErrorIcon} />
                </Box>
                <Box>
                  <Text color="#b81237">Please select an option</Text>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {hasAgeSelectedAndDoesNotHaveAge() && (
          <Box>
            <WarningCard text="While you may wish to continue through the rest of this service, be aware there are very limited circumstances where a person under 18 can make a will. If you’re under 18 and believe there is a reason you should have a will in place, you should get professional advice." />
          </Box>
        )}
      </Box>
      <Box>
        <StepNavigationComponent
          handleBack={handleBack}
          nextButton={
            <Button theme="primary" className="primary-button-dcs" type="button" onClick={handleSave}>
              Save and exit
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default UserDetails;
