import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';

export const Device = ({ mobile, tablet, desktop, breakpoints, children }) => {
    let maxWidth;
    let minWidth;

    if (mobile) {
        minWidth = 0;
        maxWidth = 480;
    }

    if (tablet) {
        minWidth = minWidth >= 0 ? minWidth : 480 + 1;
        maxWidth = 992;
    }

    if (desktop) {
        minWidth = minWidth >= 0 ? minWidth : 992 + 1;
        maxWidth = undefined;
    }

    //force breakpoints, overrides mobile, tablet & desktop only tags
    if (breakpoints && (breakpoints.min !== null || breakpoints.max !== null)) {
        minWidth = breakpoints.min || 0;
        maxWidth = breakpoints.max ? breakpoints.max - 1 : undefined;
    }

    const minWidthQuery = minWidth ? `(min-width: ${minWidth}px)` : '';
    const maxWidthQuery = maxWidth ? `(max-width: ${maxWidth}px)` : '';
    const separator = minWidthQuery && maxWidthQuery ? 'and' : '';
    const query = `${minWidthQuery} ${separator} ${maxWidthQuery}`;

    return (
        <Media query={ query.trim() } render={
            () => children
        }/>
    );
};

Device.propTypes = {
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    desktop: PropTypes.bool,
    breakpoints: PropTypes.object,
    children: PropTypes.node,
};
