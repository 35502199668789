import * as React from 'react';
import { Box } from 'grommet';
import { getSchema } from '../utils/assetTypesSelectOptions';
import FormContainer from './FormContainer';

type AssetType = WillPlanningForm.AssetType;

type Props = {
  currentAsset: WillPlanningForm.Asset;
  handleOnSubmit: (asset: Omit<WillPlanningForm.AssetSubType, 'type'>, assetType: AssetType) => void;
  handleOnDelete?: (asset: Omit<WillPlanningForm.AssetSubType, 'type'>) => void;
};

const EditAssetForm: React.FunctionComponent<Props> = ({ handleOnSubmit, currentAsset, handleOnDelete }: Props) => {
  return (
    <Box fill gap="medium">
      <Box>
        <FormContainer
          initialFormData={currentAsset}
          schema={getSchema(currentAsset.type)}
          handleOnSubmit={(asset) => {
            handleOnSubmit(asset, currentAsset.type);
          }}
          handleOnDelete={handleOnDelete}
        ></FormContainer>
      </Box>
    </Box>
  );
};

export default EditAssetForm;
