import * as React from 'react';
import { Box, Drop, Image, Text, Anchor, ResponsiveContext } from 'grommet';
import QuestionIcon from './assets/question.svg';

type Props = {
  text: string | React.ReactElement | React.ReactElement[];
  linkText?: string;
  width?: string;
};

type Ref = {
  current: HTMLAnchorElement;
};

type SetOver = (value: boolean) => void;

const closeTooltipWhenClickingOutside = (ref: Ref, setOver: SetOver) => (event: any) => {
  if (ref.current && !ref.current.contains(event.target)) {
    setOver(false);
  }
};

const TooltipComponent: React.FunctionComponent<Props> = ({ text, linkText, width = 'medium' }: Props) => {
  const [over, setOver] = React.useState(false);
  const ref = React.useRef();
  const size = React.useContext(ResponsiveContext);
  React.useEffect(() => {
    const closeTooltip = closeTooltipWhenClickingOutside(ref, setOver);
    document.addEventListener('mousedown', closeTooltip);
    return () => {
      document.removeEventListener('mousedown', closeTooltip);
    };
  }, [ref]);

  const AnchorDesktop = (
    <Anchor
      color="#2E5299"
      ref={ref}
      onClick={(event) => event.preventDefault}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      onFocus={() => setOver(true)}
      onBlur={() => setOver(false)}
      style={{ cursor: 'pointer', width: 'fit-content' }}
    >
      {linkText}
    </Anchor>
  );

  const AnchorMobile = (
    <Anchor
      color="#2E5299"
      ref={ref}
      onClick={(event) => setOver(true)}
      style={{ cursor: 'pointer', width: 'fit-content' }}
    >
      {linkText}
    </Anchor>
  );

  const ImageDesktop = (
    <Image
      src={QuestionIcon}
      ref={ref}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      onFocus={() => setOver(true)}
      onBlur={() => setOver(false)}
      width="16px"
      height="16px"
      style={{ cursor: 'pointer' }}
    />
  );

  const ImageMobile = (
    <Image
      src={QuestionIcon}
      ref={ref}
      onClick={(event) => setOver(true)}
      width="16px"
      height="16px"
      style={{ cursor: 'pointer' }}
    />
  );

  const TooltipDesktop = linkText ? AnchorDesktop : ImageDesktop;
  const TooltipMobile = linkText ? AnchorMobile : ImageMobile;

  return (
    <Box>
      {size === 'small' ? TooltipMobile : TooltipDesktop}
      {ref.current && over && (
        <Drop
          align={{ left: 'right' }}
          target={ref.current}
          plain
          // trapFocus set to false allows tabbing through
          trapFocus={false}
        >
          <Box
            margin="xxsmall"
            pad="medium"
            background="#242934"
            width={size === 'small' ? '85%' : width}
            alignSelf={size === 'small' ? 'center' : null}
          >
            {typeof text === 'string' ? (
              <Text size="16px" color="white">
                {text}
              </Text>
            ) : (
              text
            )}
          </Box>
        </Drop>
      )}
    </Box>
  );
};

export default TooltipComponent;
