import styled from 'styled-components/macro';
import Spinner from '../Icons/Spinner';

export const LoaderContainer = styled.div`
  text-align: center;
  position: relative;
  max-width: 500px;
`;

export const SpinnerImageContainer = styled.div`
  text-indent: 100%;
  overflow: hidden;
  width: 100%;
  height: 70px;

  ${Spinner} {
    position: absolute;
    top: 0;
    margin-left: -35px;
    left: 50%;
    width: 70px;
    height: 70px;
  }
`;

export const LoaderMessage = styled.p`
  margin: 10px 0 0;
`;