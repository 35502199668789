import { FormError, FormHelp, FormLabel, FormLabelNote } from './Form.styled';
import { getBreakpoint, getSpacing, pxToRem } from '../../utils';
import styled, { css } from 'styled-components/macro';

import MaskedInput from 'react-text-mask';
import React from 'react';
import classNames from 'classnames';
import { layout } from 'styled-system';
import space from '../../GlobalStyle/space/space';
import tokens from '../../Tokens/tokens';

const { colors, font, layouts } = tokens;

export const baseInputStyles = css`
    width: 100%;
    max-width: ${props => props.inputWidth ? pxToRem(layouts.inputWidth[props.inputWidth]) : '100%' };
    font-size: ${pxToRem(font.size.base)};
    color: ${colors.forms.inputText};
    background-color: ${colors.forms.background};
    border: 2px solid ${colors.forms.borderColor};
    border-radius: 6px;
    padding: ${getSpacing(['none', 13])};
    margin-top: ${pxToRem(4)};
    transition: border-color 0.25s ease, background-color 0.25s ease;
    ${props => !props.rows && `height: ${pxToRem(48)}`};
    cursor: text;
    appearance: none;

    &::placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder,   /* Edge */
    &:-ms-input-placeholder {       /* Internet Explorer 10-11 */
        color: ${colors.forms.helpText};
        opacity: 1;
    }
    &.focus-visible,
    &:focus {
        outline: 2px solid ${colors.borders.focus};
        outline-offset: 2px;
    }
    &:disabled {
        opacity: 1;
        background-color: ${colors.backgrounds.disabled};
    }
    &::-ms-clear {
        display: none;
    }
    &:-moz-ui-invalid {
        box-shadow: none;
    }

    ${props => props.hasError && css`
        ${FieldError}
    `}
`;

const FieldError = css`
    border-color: ${colors.borders.error};
    color: ${colors.forms.inputText};
    box-shadow: none;
`;

const Field = styled.div.attrs(props => ({
    className: 'field-item' // needed when referencing this in another component for spacing
}))`
    max-width: 100%;
    margin-top: ${getSpacing(['sm'])};


    flex-grow: ${props => props.grow || '1'};
    ${props => props.columns && `flex-basis: ${props.columns * 6.25}%`};
    position: relative;

    ${props => props.inline && getBreakpoint.md`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > label {
            flex-basis: 37.5%;
            text-align: right;
            padding-right: ${getSpacing('sm')};
        }
        > input, > textarea, ${AffixWrapperStyled} {
            flex-basis: 62.5%;
        }
        > p {
            margin-left: 37.5%;
        }
    `};

    ${layout};
    ${space};
`;

Field.Input = styled.input.attrs(props => ({
    className: classNames(props.className, { error: props.hasError }), // 'form__text'
}))`
    ${baseInputStyles}
`;

Field.TextArea = styled.textarea.attrs(props => ({
    className: classNames(props.className, { error: props.hasError }), // 'form__textarea',
}))`
    ${baseInputStyles}
    padding-top: ${pxToRem(11)};
    padding-bottom: ${pxToRem(11)};
    line-height: ${font.lineHeight.default};
    display: block;
`;

Field.Select = styled.select.attrs(props => ({
    className: classNames(props.className, { error: props.hasError }), // 'form__select'
}))`
    ${baseInputStyles}
    ${props => props.small && css`max-width: ${pxToRem(174)}`};
    ${props => !props.value && css`color: ${colors.forms.inputText}`};

    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23002664' d='M12 2c0-.1 0-.1-.1-.2L10.3.1c-.1-.1-.3-.1-.4 0L6 4 2.1 0c-.1 0-.3 0-.4.1L.1 1.7c-.1.1-.1.3 0 .4l5.7 5.8c.1.1.3.1.4 0l5.7-5.8c.1 0 .1-.1.1-.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right ${pxToRem(18)} center;
    cursor: pointer;
    padding-right: ${getSpacing('xl')};

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
    &::-ms-expand {
        display: none;
    }

    option:not(:checked) {
        color: #000;
    }
`;

Field.Label = styled(FormLabel)`
    font-weight: ${font.weight.medium};
`;

Field.Label.Note = styled(FormLabelNote)``;

Field.Error = styled(FormError)``;

Field.Help = styled(FormHelp)``;

Field.GroupHelp = styled(FormHelp)``;

export const MaskedInputStyled = styled(({ inputWidth, hasError, ...props }) =>
    <MaskedInput { ...props }
        className={ classNames(props.className, { error: props.hasError }) }
    />)`
    ${baseInputStyles}

    ${props => (props.prefix || props.suffix) && css`
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
        display: block;
        background-clip: padding-box;
    `};

    ${props => props.prefix && css`
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `};

    ${props => props.suffix && css`
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    `};
`;

export const AffixWrapperStyled = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
`;

export const PrefixWrapperStyled = styled.div`
    margin-right: -${pxToRem(1)};
    display: flex;
`;

export const SuffixWrapperStyled = styled.div`
    margin-left: -${pxToRem(1)};
    display: flex;
`;

export const AffixStyled = css`
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: ${pxToRem(font.size.base)};
    font-weight: ${font.weight.medium};
    line-height: ${font.lineHeight.default};
    color: ${colors.forms.inputText};
    background: ${colors.grey.geyser};
    text-align: center;
    white-space: nowrap;
    border: 2px solid ${colors.forms.borderColor};
    ${props => props.hasError && css`
        ${FieldError}
    `}
`;

export const PrefixStyled = styled.span`
    ${AffixStyled}
    border-radius: 6px 0px 0px 6px;
    border-right: none;
    padding: ${getSpacing([6, 12, 6, 10])};
    margin-top: ${pxToRem(4)};
`;

export const SuffixStyled = styled.span`
    ${AffixStyled}
    border-radius: 0px 6px 6px 0px;
    border-left: none;
    padding: ${getSpacing([6, 10, 6, 12])};
    margin-top: ${pxToRem(4)};
`;

export default Field;
