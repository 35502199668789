export const userToBookingUserDetailsAdapter = (user: User, isOverEighteen: boolean): Booking.UserDetails => {
  return {
    title: user.title || '',
    firstName: user.givenName || '',
    middleName: user.middleName || '',
    lastName: user.familyName || '',
    email: user.email || '',
    mobileNumber: user.phoneNumber || '',
    residentialAddress: user.address || '',
    isOverEighteen: isOverEighteen,
  };
};
