import { Box } from 'grommet';
import * as React from 'react';
import LabelAndValue from 'app/common/LabelAndValue';
import HeadingThree from 'app/common/HeadingThree';
import TooltipComponent from 'app/common/TooltipComponent';
import { routes } from 'app/common/routes';
import SummaryHeaderComponent from '../planning/components/SummaryHeaderComponent';

type Props = {
  user: User;
  showEdit?: boolean;
};

const YourDetailsComponent: React.FunctionComponent<Props> = ({ user, showEdit = false }: Props) => {
  const headerText = 'Your details';
  return (
    <Box fill width="medium" margin={{ bottom: 'xlarge' }}>
      <Box>
        <Box direction="row" gap="xxxsmall" align="center">
          <Box>
            {showEdit && <SummaryHeaderComponent text={headerText} goTo={routes.USER_DETAILS} />}
            {!showEdit && <HeadingThree title={headerText} />}
          </Box>
          <Box>
            <TooltipComponent
              text={
                "If you want to change your details, you'll need to log in to MyServiceNSW Account and update your details there."
              }
            />
          </Box>
        </Box>
      </Box>
      <Box gap="medium">
        <Box fill>
          <LabelAndValue label="Name" value={user?.fullName} />
        </Box>
        <Box>
          <LabelAndValue label="Email" value={user?.email} />
        </Box>
        <Box>
          <LabelAndValue label="Mobile number" value={user?.phoneNumber} />
        </Box>
        <Box>
          <LabelAndValue label="Residential address" value={user?.address} />
        </Box>
      </Box>
    </Box>
  );
};

export default YourDetailsComponent;
