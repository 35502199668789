import axios from 'axios';
import { getIdTokenInLocalStorage } from 'app/features/user/components/AuthService';

const client = axios.create({
  baseURL: process.env.EOL_API_URL,
});

const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${process.env.USER_TOKEN || getIdTokenInLocalStorage()}`,
      'X-API-Key': process.env.X_API_KEY,
    },
  };
};

const getAllTaskInfo = async (user: User) => {
  const { data: allTaskInfo } = await client.get(`/task/${user.myAccountId}/taskInfo`, getHeaders());

  return allTaskInfo;
};

export default {
  getAllTaskInfo,
};
