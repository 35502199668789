import styled, { css } from 'styled-components/macro';
import Icons from '../../Icons';
import tokens from '../../Tokens/tokens';
import { getBreakpoint, getSpacing, pxToRem } from '../../utils';

const { font } = tokens;

const StyledButton = styled.button`
    font-family: 'Gotham A','Gotham B', Helvetica, Arial, sans-serif;
    height: auto;
    width: 100%;
    padding: calc((48px - 4px - 1.5rem) / 2) ${getSpacing('md')};
    line-height: ${font.lineHeight.default};
    font-size: 1rem;
    font-weight: ${font.weight.medium};
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    border: 0;
    transition: border-color 0.5s ease, background-color 0.5s ease,
        color 0.5s ease;

    &:focus {
        outline: 2px solid #2e5299;
        outline-offset: 2px;
    }

    ${getBreakpoint.sm`
        width: auto;
        min-width: ${pxToRem(200)};
    `}

    ${(props) => props.theme && themeVariant[props.theme]};

    ${(props) => props.size && sizeVariant[props.size]};

    ${(props) => props.disabled && css`
        ${disabledStyle}
    `};

    ${(props) => props.external && css`
        ${externalStyle}
    `};

    ${(props) =>
        props.external && (props.theme === 'tertiary' ||
        props.theme === 'link' ||
        props.theme === 'back' ||
        props.theme === 'arrow') && css`
        ${externalStyle}
        text-decoration: underline;
    `};
`;

const primaryButtonStyle = css`
    color: #fff;
    background-color: #d7153a;
    border: 2px solid #d7153a;

    &:hover {
        color: #fff;
        background-color: #e80229;
        border-color: transparent;
    }

    &:active {
        color: #fff;
        background-color: #a71930;
        border-color: transparent;
    }

    &:disabled{
        color: #fff;
        background-color: #d7153a;
        border: 2px solid #d7153a;
    }

    ${getBreakpoint.print`
        border: 2px solid #d7153a;
        background: #fff;
        color: #d7153a;
    `}
`;

const secondaryButtonStyle = css`
    color: #2e5299;
    background-color: #f9fdff;
    border: 2px solid #2e5299;

    &:hover {
        color: #2e5299;
        background-color: #fff;
        border-color: #deeaf0;
    }

    &:active {
        color: #002664;
        background-color: #e0e4e6;
        border-color: #929fa4;
    }

    &:disabled{
        color: #2e5299;
        background-color: #f9fdff;
        border: 2px solid #c4d0d6;
    }

    ${getBreakpoint.print`
        border: 2px solid #929fa4;
        background: #fff;
        color: #2e5299;
    `}
`;

const tertiaryButtonStyle = css`
    color: #2e5299;
    background: none;
    text-decoration: underline;

    &:hover {
        color: #2e5299;
        text-decoration: none;
    }

    &:active {
        color: #2e5299;
    }

    &:disabled{
        color: #2e5299;
        text-decoration: underline;
    }

    ${getBreakpoint.print`
        color: #2e5299;
    `}
`;

const whiteButtonStyle = css`
    color: #242934;
    background-color: #fff;
    border: 2px solid #c4d0d6;

    &:hover {
        color: #242934;
        background-color: #fff;
        border-color: #deeaf0;
    }

    &:active {
        color: #242934;
        background-color: #fff;
        border-color: #929fa4;
    }

    &:disabled{
        color: #242934;
        background-color: #fff;
        border: 2px solid #c4d0d6;
    }

    ${getBreakpoint.print`
        border: 2px solid #929fa4;
        background: #fff;
        color: #2e5299;
    `}
`;

const linkButtonStyle = css`
    text-decoration: underline;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    min-width: 0;
    background: none;
    color: #2e5299;
    border-radius: 0;

    ${getBreakpoint.sm`
        width: auto;
        min-width: 0;
    `}

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        text-decoration: underline;
    }
`;

const backStyle = css`
    ${linkButtonStyle};

    ${Icons.ChevronLeft} {
        margin-right: ${pxToRem(6)};
        vertical-align: middle;
    }
`;

const arrowStyle = css`
    ${linkButtonStyle};
    position: relative;

    & {
        padding-right: ${getSpacing('xl')};
        width: auto;
    }

    &:hover:not(:disabled) {
        ${Icons.Arrow} {
            transform: translateX(14px);
        }
    }

    ${getBreakpoint.sm`
        padding-right: ${getSpacing('xl')};
    `}

    ${Icons.Arrow} {
        g {
            stroke-width: 2;
            stroke: #2e5299;
        }
        margin-left: ${pxToRem(4)};
        width: ${pxToRem(19)};
        height: ${pxToRem(19)};
        vertical-align: middle;
        transition: transform 0.5s ease-in-out;
        position: absolute;
        top: 50%;
        right: ${pxToRem(14)};
        margin-top: -${pxToRem(10)};
    }
`;

const externalStyle = css`
    text-decoration: none;

    ${Icons.ExternalLink} {
        margin-left: ${pxToRem(4)};
        width: ${pxToRem(12)};
        height: ${pxToRem(12)};
        ${(props) => props.theme && themeIconVariant[props.theme]};
    }
`;

const disabledStyle = css`
    cursor: not-allowed;
    -webkit-transition: none;
    transition: none;
    opacity: 0.3;
`;

const smallButtonSize = css`
    font-size: 0.875rem;
    line-height: 1em;
    padding-top: calc((36px - 4px - 1em) / 2);
    padding-bottom: calc((36px - 4px - 1em) / 2);
`;

const xlButtonSize = css`
    padding-top: calc((48px - 4px - 1.5rem) / 2);
    padding-bottom: calc((48px - 4px - 1.5rem) / 2);

    ${getBreakpoint.md`
        font-size: 1.125rem;
        padding-top: calc((56px - 4px - 1.5rem) / 2);
        padding-bottom: calc((56px - 4px - 1.5rem) / 2)
    `}
`;

const xxlButtonSize = css`
    padding-top: calc((48px - 4px - 1.5rem) / 2);
    padding-bottom: calc((48px - 4px - 1.5rem) / 2);

    ${getBreakpoint.md`
        font-size: 1.375rem;
        line-height: 2rem;
        padding-top: calc((80px - 4px - 2rem) / 2);
        padding-bottom: calc((80px - 4px - 2rem) / 2);
        border-radius: 10px;
    `}
`;

const shortButtonSize = css`
    width: auto;
    min-width: 0;
    padding-left: ${getSpacing('md')};
    padding-right: ${getSpacing('md')};

    ${getBreakpoint.sm`
        width: auto;
        min-width: 0;
    `}
`;

const primaryIconStyle = css`
    fill: #ffffff;
`;

const secondaryIconStyle = css`
    fill: #2e5299;
`;

const tertiaryIconStyle = css`
    fill: #2e5299;
`;

const whiteIconStyle = css`
    fill: #242934;
`;

const linkIconStyle = css`
    fill: #2e5299;
`;

const themeVariant = {
    primary: primaryButtonStyle,
    secondary: secondaryButtonStyle,
    tertiary: tertiaryButtonStyle,
    white: whiteButtonStyle,
    link: linkButtonStyle,
    arrow: arrowStyle,
    back: backStyle,
};

const themeIconVariant = {
    primary: primaryIconStyle,
    secondary: secondaryIconStyle,
    tertiary: tertiaryIconStyle,
    white: whiteIconStyle,
    link: linkIconStyle,
};

const sizeVariant = {
    small: smallButtonSize,
    xl: xlButtonSize,
    xxl: xxlButtonSize,
    short: shortButtonSize,
};

export default StyledButton;
