import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '../Icons/ExternalLink';
import { LinkContainer } from './External.styled';
import { trackMe } from '../Components/ComponentTracker/componentTracker';

const External = ({ className, children, newTab, href, onClick }) => {

    useEffect(() => {
        trackMe('External');
    }, []);

    return (
        <LinkContainer
            className={ className }
            href={ href }
            target={ newTab ? '_blank' : '_self' }
            rel='noopener noreferrer'
            onClick={ onClick }
        >
            {children}
            <ExternalLink pl='0.2rem' width='1rem' />
        </LinkContainer>
    );
};

External.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    newTab: PropTypes.bool,
    onClick: PropTypes.func,
};

External.defaultProps = {
    newTab: true
};

export default External;
