import { Anchor, Box, Image, Text } from 'grommet';
import * as React from 'react';
import HighlightCard from 'app/common/HighlightCard';
import LeavingFinalsAffairsModal from 'app/common/LeavingFinalAffairsModal';
import ErrorIcon from './assets/error.svg';

const BookingError: React.FunctionComponent = () => {
  const [isLeavingModalVisible, setIsLeavingModalVisible] = React.useState(false);

  return (
    <>
      <Box gap="large">
        <Box>
          <HighlightCard
            title="It looks like there was a problem"
            text="We apologise for the disruption. We're working to fix the issue."
            borderColor="#B92E37"
            backgroundColor="#F4DBE1"
            icon={<Image src={ErrorIcon} />}
          />
        </Box>
        <Box>
          <Box gap="small">
            <Text>
              In the meantime, you can contact{' '}
              <Anchor
                size="medium"
                label="NSW Trustee &amp; Guardian"
                onClick={() => setIsLeavingModalVisible(true)}
                style={{ textDecoration: 'underline' }}
              />{' '}
              to book your appointment.
            </Text>
          </Box>
        </Box>
      </Box>
      {isLeavingModalVisible && (
        <LeavingFinalsAffairsModal
          onModalClose={() => setIsLeavingModalVisible(false)}
          redirectUrl={'https://www.tag.nsw.gov.au/contact-us.html'}
        />
      )}
    </>
  );
};

export default BookingError;
