import { decode } from 'jsonwebtoken';
import axios from 'axios';
import { doLogout } from './MyAccountService';

const eolApiUrl = process.env.EOL_API_URL;

const secondsToMilliseconds = (seconds) => seconds * 1000;

const parseIdToken = (idToken: JWT): MyAccount.ParsedIdToken | null => {
  try {
    const { exp, sub } = decode(idToken) as { exp: number; sub: string };
    return {
      myAccountId: sub,
      jwtExpireTimestamp: exp,
      jwt: idToken,
    };
  } catch (e) {
    return null;
  }
};

const getAccessTokenInLocalStorage = (): AccessToken => {
  return window.localStorage.getItem('access_token');
};

const getIdTokenInLocalStorage = (): JWT => {
  return window.localStorage.getItem('id_token');
};

const getRefreshTokenInLocalStorage = (): RefreshToken => {
  return window.localStorage.getItem('refresh_token');
};

const saveTokensInLocalStorage = (tokens: MyAccount.Tokens): void => {
  window.localStorage.setItem('id_token', tokens.idToken);
  window.localStorage.setItem('access_token', tokens.accessToken);
  window.localStorage.setItem('refresh_token', tokens.refreshToken);
};

const removeTokensFromLocalStorage = (): void => {
  window.localStorage.removeItem('id_token');
  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('refresh_token');
};

const getTokensByAuthorizationCode = async (code: string): Promise<MyAccount.Tokens> => {
  if (!code) {
    return Promise.reject('Invalid authorization code');
  }

  const { data: tokens } = await axios.get(`${eolApiUrl}/auth/tokens?code=${code}`);
  if (tokens.error) {
    return Promise.reject(`${tokens.error}. ${tokens.error_description}`);
  }

  return {
    idToken: tokens.id_token,
    accessToken: tokens.access_token,
    refreshToken: tokens.refresh_token,
  };
};

const logout = async (): Promise<unknown> => {
  await doLogout(getAccessTokenInLocalStorage());
  removeTokensFromLocalStorage();
  return Promise.resolve();
};

const hasTokenExpired = (idTokenExpiryTimestamp: number): boolean => {
  return new Date().getTime() > secondsToMilliseconds(idTokenExpiryTimestamp);
};

const isLoggedIn = (user: User, idTokenExpiryTimestamp: number): boolean =>
  user && !hasTokenExpired(idTokenExpiryTimestamp);

export {
  isLoggedIn,
  parseIdToken,
  getAccessTokenInLocalStorage,
  getIdTokenInLocalStorage,
  getRefreshTokenInLocalStorage,
  saveTokensInLocalStorage,
  getTokensByAuthorizationCode,
  logout,
};
