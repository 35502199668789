import * as React from 'react';
import { Box, Heading } from 'grommet';
import { FormSelect } from '@snsw/react-component-library';
import { assetTypesSelectOptions, getSchema } from '../utils/assetTypesSelectOptions';
import FormContainer from './FormContainer';

type AssetType = WillPlanningForm.AssetType;

type Props = {
  currentAsset?: WillPlanningForm.Asset;
  handleOnSubmit: (asset: Omit<WillPlanningForm.AssetSubType, 'type'>, assetType: AssetType) => void;
};

const NewAssetForm: React.FunctionComponent<Props> = ({ handleOnSubmit, currentAsset }: Props) => {
  const [assetType, setAssetType] = React.useState<AssetType>(currentAsset?.type);

  return (
    <Box fill>
      <Box fill>
        <Box>
          <Heading level="5">Asset type</Heading>
        </Box>
        <Box>
          <FormSelect
            options={assetTypesSelectOptions}
            value={assetType}
            placeholder="Select"
            onChange={(event) => {
              setAssetType(event.target.value);
            }}
          />
        </Box>
      </Box>
      <Box>
        {assetType && (
          <FormContainer
            initialFormData={{}}
            schema={getSchema(assetType)}
            handleOnSubmit={(asset) => {
              handleOnSubmit(asset, assetType);
            }}
          ></FormContainer>
        )}
      </Box>
    </Box>
  );
};

export default NewAssetForm;
