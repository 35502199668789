import * as React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import { FormCheckbox } from '@snsw/react-component-library';
import ActionModal from '../../../common/ActionModal';

type Props = {
  isViewMode?: boolean;
  closeModal: () => void;
  saveAcknowledgement?: () => void;
  setHasAcceptedTerms?: (checked: boolean) => void;
  hasAcceptedTerms: boolean;
};

const Disclaimer: React.FunctionComponent<Props> = ({
  closeModal,
  saveAcknowledgement,
  hasAcceptedTerms,
  setHasAcceptedTerms,
  isViewMode = false,
}: Props) => {
  const size = React.useContext(ResponsiveContext);
  const [showError, setShowError] = React.useState(false);

  const handleAcknowledgementOnChange = (checked: boolean) => {
    setShowError(false);
    setHasAcceptedTerms(!!checked || false);
  };

  const handleContinue = () => {
    if (!hasAcceptedTerms) {
      setShowError(true);
      return;
    }
    saveAcknowledgement();
  };

  return (
    <ActionModal
      onModalClose={closeModal}
      handleContinue={isViewMode ? closeModal : handleContinue}
      showCancelButton={!isViewMode}
      continueButtonText={!isViewMode ? 'Save and continue' : 'Close'}
      style={
        size === 'small'
          ? {
              display: 'block',
              height: '100%',
              position: 'fixed',
              overflow: 'scroll',
              webkitOverflowScrolling: 'touch',
            }
          : null
      }
    >
      <Box>
        <Box align="start" justify="center">
          <Box pad={{ bottom: 'large' }}>
            <Heading level="3" margin="none">
              Important information
            </Heading>
          </Box>
          <Box pad={{ bottom: 'xlarge' }}>
            <ul style={{ margin: 0, listStyleType: 'revert' }}>
              <li>
                The purpose of this service is to help you think about and act on some of the matters related to
                planning for end of life. It could also assist you in preparing for a meeting if you intend to seek
                professional advice.
              </li>
              <li>
                The information on this website is of a general nature only and should not be regarded as, or relied
                upon, as legal advice. It does not take into account your individual circumstances and objectives. It is
                recommended that you seek professional advice in relation to your particular circumstances and
                objectives.{' '}
              </li>
              <li>
                This service, and any information you provide are not instructions for, or a draft of, or a final will,
                Power of Attorney or appointment of an Enduring Guardian. Nor does it cancel or alter any of those
                documents.
              </li>
            </ul>
          </Box>
          <Box pad={{ bottom: 'large' }}>
            <FormCheckbox
              id="acknowledgement"
              name="acknowledgement"
              label="I acknowledge and understand."
              checked={hasAcceptedTerms}
              value={'true'}
              onChange={(value) => handleAcknowledgementOnChange(value)}
              disabled={isViewMode}
              hasError={!isViewMode && showError}
              errorMessage="Please indicate your agreement to continue"
            />
          </Box>
        </Box>
      </Box>
    </ActionModal>
  );
};

export default Disclaimer;
