import Heading from '../../Components/Headings/Heading';
import HeadingWithIcon from '../../Components/Headings/HeadingWithIcon';
import Notice from '../../Components/Notice/Notice.styled';
import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const ConfirmationPage = ({
    appTitle,
    linkLabel,
    linkUrl,
    refNumber,
    successLabel
}) => {
    return (
        <>
            <HeadingWithIcon level={ 2 } >Your application has been submitted for assessment.</HeadingWithIcon>
            <Notice info>
                <Notice.Title>
                    Important
                </Notice.Title>
                <Notice.Copy>
                    { successLabel }
                </Notice.Copy>
                { linkLabel && linkUrl && <a href={ linkUrl }>{ linkLabel }</a> }
            </Notice>
            <Heading level={ 3 }>Application details</Heading>
            <p>Reference Number: <strong>{ refNumber }</strong>
            </p>
            <p>Application: <strong>{ appTitle }</strong>
            </p>
            <p>Lodgement date: <strong>{ dayjs().format('DD-MMM-YYYY') }</strong>
            </p>
        </>
    );
};

ConfirmationPage.propTypes = {
    appTitle: PropTypes.string,
    linkLabel: PropTypes.string,
    linkUrl: PropTypes.string,
    refNumber: PropTypes.string,
    successLabel: PropTypes.string
};

ConfirmationPage.defaultProps = {
    appTitle: '',
    linkLabel: '',
    linkUrl: '',
    refNumber: '',
    successLabel: ''
};

export default ConfirmationPage;
