import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListItem } from './ExpandedBreadcrumb.styled';

export const ExpandedBreadcrumb = ({ linksList }) => {
    return (
        linksList.map(({ content, path }, index) => {
            const currentPage = index === linksList.length - 1 && { 'aria-current': 'page' };
            return (
                <ListItem key={ content }>
                    <Link key={ content } to={ path } { ...currentPage }>{content}</Link>
                </ListItem>
            );
        })
    );
};

ExpandedBreadcrumb.propTypes = {
    linksList: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    })),
};