import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const HealthSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='M19 29c-.4 0-.7-.2-.9-.5l-3.5-6.7-1.5 2.7c-.2.3-.5.5-.9.5H3.6c-.6 0-1-.4-1-1s.4-1 1-1h8l2.2-3.8c.2-.3.5-.5.9-.5s.7.2.9.5l3 5.8 2.2-10.3c.1-.4.4-.7.8-.8.4-.1.8.1 1 .5l2 3.5h4.8c.6 0 1 .4 1 1s-.4 1-1 1H24c-.4 0-.7-.2-.9-.5l-1-1.7L20 28.2c-.1.4-.4.7-.8.8H19z'/>
        <circle cx='31' cy='19' r='4'/>
        <path d='M38.1 7.2C36.2 5.1 33.6 4 30.9 4c-2.8 0-5.4 1.2-7.4 3.4L22 9l-1.5-1.7c-2-2.2-4.6-3.4-7.4-3.4-2.7 0-5.3 1.1-7.2 3.2-3.4 3.8-3.8 9.6-1.3 13.9H7c-2.8-3.7-2.7-9.1.4-12.5C8.9 6.9 10.9 6 13.1 6s4.3 1 5.9 2.7l2.2 2.5h.2c.1.1.1.1.2.1H22.3c.1 0 .2-.1.3-.2 0 0 .1 0 .1-.1L25 8.7C26.6 6.9 28.7 6 30.9 6s4.2.9 5.7 2.6c3.2 3.6 3.2 9.4-.1 13.1L22 37.5 12.4 27H9.7l11.5 12.7s.1 0 .1.1c.1.1.2.1.3.2h.8c.1 0 .2-.1.3-.2 0 0 .1 0 .1-.1L37.9 23c4-4.4 4.1-11.5.2-15.8z'/>
    </svg>
);

export const Health = styled(HealthSvg)`
  ${color}
  ${layout}
`;

Health.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Health;
