import * as React from 'react';
import { Box, Text } from 'grommet';
import { useHistory } from 'react-router';
import HeadingOne from 'app/common/HeadingOne';
import QuestionsRadio from 'planning/components/QuestionsRadio';
import UserContext from 'app/features/user/context/UserContext';
import { getAnswerByQuestionId, questionsLabels } from 'planning/utils/questions';
import { routes } from 'app/common/routes';
import { Button } from '@snsw/react-component-library';
import PlanningService from '../services/PlanningService';
import PlanningFormContext from '../context/PlanningFormContext';
import StepNavigationComponent from './StepNavigationComponent';
import BackLinkComponent from './BackLinkComponent';

const Questions: React.FunctionComponent = () => {
  const { personalQuestionsAnswers, setPersonalQuestionsAnswers } = React.useContext(PlanningFormContext);
  const { user } = React.useContext(UserContext);

  const history = useHistory();

  React.useEffect(() => {
    PlanningService.getQuestionsAnswers(user).then(setPersonalQuestionsAnswers);
  }, []);

  const handleOnChanges = (questionId: string, answer: WillPlanningForm.Answer) => {
    const newPersonalQuestionsAnswers = personalQuestionsAnswers.filter(
      (personalQuestion) => personalQuestion.id !== questionId,
    );

    newPersonalQuestionsAnswers.push({
      id: questionId,
      answer,
    });

    setPersonalQuestionsAnswers(newPersonalQuestionsAnswers);
    PlanningService.setQuestionsAnswers(user, newPersonalQuestionsAnswers);
  };

  const handleNext = () => {
    PlanningService.submitWill(user);
    history.push(routes.SUMMARY);
  };

  const handleBack = () => {
    history.push(routes.ASSETS);
  };

  return (
    <Box>
      <Box>
        <BackLinkComponent handleBack={handleBack} />
      </Box>
      <Box>
        <HeadingOne title="Questions to help plan your will" />
      </Box>
      <Box width="xlarge" margin={{ bottom: 'xlarge' }}>
        <Text>
          In planning your will or when getting professional advice, a range of circumstances often need to be
          considered, including details of your assets, financial interests and family.
        </Text>
        <br />
        <Text>Some things to be aware of, or prepare for, include whether:</Text>
      </Box>
      <Box pad={{ bottom: 'medium' }}>
        {questionsLabels.map(({ id: questionId, label }) => (
          <QuestionsRadio
            key={questionId}
            name={questionId}
            legend={label}
            onChange={(answer) => handleOnChanges(questionId, answer as WillPlanningForm.Answer)}
            value={getAnswerByQuestionId(personalQuestionsAnswers, questionId)}
          />
        ))}
      </Box>
      <Box>
        <StepNavigationComponent
          handleBack={handleBack}
          nextButton={
            <Button theme="primary" className="primary-button-dcs" type="button" onClick={handleNext}>
              Save and continue
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default Questions;
