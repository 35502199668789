// eslint-disable-next-line @typescript-eslint/no-explicit-any
const radioColor: any = (hasError: boolean) => {
  return {
    radioButton: {
      border: {
        color: hasError
          ? {
              dark: '#b81237',
              light: '#b81237',
            }
          : {
              dark: 'rgba(255, 255, 255, 0.5)',
              light: 'rgba(0, 0, 0, 0.15)',
            },
      },
    },
  };
};

export default radioColor;
