import * as React from 'react';
import { AccordionPanel, Box, ThemeContext, Text } from 'grommet';
import { theme } from './DocumentLocation';
import ReusablePanel from './ReusablePanel';

type Props = {
  activeIndex: number[];
  documentLocation: any;
  saveDocumentLocation: (updated: any) => Promise<void>;
  otherIndex: number;
  closeAccordion: () => void;
};

const OtherPanel: React.FunctionComponent<Props> = ({
  activeIndex,
  documentLocation,
  saveDocumentLocation,
  otherIndex,
  closeAccordion,
}: Props) => {
  const onSave = (updated) => {
    if (updated.saved) {
      documentLocation.others[otherIndex] = updated;
    } else {
      documentLocation.others.splice(otherIndex, 1);
    }
    saveDocumentLocation(documentLocation);
    document.getElementById(`other-${otherIndex}`).scrollIntoView();
  };

  const label = () => {
    const label = `Other important documents`;
    if (documentLocation?.others[otherIndex]?.documentType) {
      return (
        <Text>
          {documentLocation?.others[otherIndex]?.documentType} -{' '}
          <Text weight="bold">{documentLocation.others[otherIndex].label}</Text>
        </Text>
      );
    }
    return <Text>{label}</Text>;
  };

  return (
    <ThemeContext.Extend value={theme(documentLocation?.others[otherIndex]?.saved)}>
      <Box
        id={`other-${otherIndex}`}
        pad="medium"
        fill
        background={activeIndex && activeIndex[0] - 3 === otherIndex ? '' : '#F9FDFF'}
        border={{ color: '#C4D0D6', side: 'bottom' }}
        round={{ size: '1%', corner: 'bottom' }}
      >
        <AccordionPanel label={label()}>
          <ReusablePanel
            docTypeData={documentLocation.others[otherIndex]}
            onSave={onSave}
            closeAccordion={closeAccordion}
          />
        </AccordionPanel>
      </Box>
    </ThemeContext.Extend>
  );
};

export default OtherPanel;
