import styled from 'styled-components/macro';

export const Container = styled.div`
    max-width: 1224px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
        padding-right:30px;
        padding-left: 30px
    }

    @media print {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0
    }
`;

export const PageWrapper = styled.div`
    padding: 2rem 0;

    @media (min-width: 768px) {
        padding: 3.5rem 0;
    }
`;
