import { Box, Text, Image } from 'grommet';
import * as React from 'react';
import { useHistory } from 'react-router';
import { routes } from 'app/common/routes';
import { Button } from '@snsw/react-component-library';
import infoIcon from 'app/common/assets/info.svg';
import HighlightCard from 'app/common/HighlightCard';
import HeadingOne from 'app/common/HeadingOne';
import HeadingTwo from 'app/common/HeadingTwo';
import TooltipComponent from 'app/common/TooltipComponent';
import AssetListContainer from './AssetListContainer';
import StepNavigationComponent from './StepNavigationComponent';
import BackLinkComponent from './BackLinkComponent';

const InfoIcon = <Image src={infoIcon} />;

const Section: React.FunctionComponent<{ heading: string; text: string }> = ({ heading, text }) => {
  return (
    <Box>
      <Text>
        <b>{heading}</b> {text}
      </Text>
    </Box>
  );
};

const ToolTip: React.FunctionComponent = () => {
  return (
    <Box gap="small">
      <Section heading="Real estate" text="such as property, land and buildings." />
      <Section
        heading="Bank accounts"
        text="and accounts with other financial institutions, including money in any cheque, savings or cash management accounts."
      />
      <Section heading="Personal property" text="such as shares and other forms of business ownership." />

      <Section heading="Valuable items" text="such as cars, artwork, jewellery and furniture." />
      <Section
        heading="Other assets"
        text="including debts owed to a testator, interest in a deceased estate, as well as copyright of published and unpublished works, patent rights and royalties."
      />
    </Box>
  );
};

const AssetListPageComponent: React.FunctionComponent = () => {
  const history = useHistory();

  const handleNext = () => {
    history.push(routes.QUESTIONS);
  };

  const handleBack = () => {
    history.push(routes.HOME);
  };

  return (
    <Box>
      <Box>
        <BackLinkComponent handleBack={handleBack} />
      </Box>
      <Box>
        <HeadingOne title="List your assets" />
      </Box>
      <Box width="xlarge">
        <Box margin={{ bottom: 'medium' }}>
          <TooltipComponent text={<ToolTip />} linkText="Which assets to include in a will?" width="large" />
        </Box>
        <Box margin={{ bottom: 'medium' }}>
          <p>
            Making a list of assets before you write a will helps ensure you don&apos;t overlook any significant
            property as part of your estate.
            <br />
            It also gets you thinking about all the important decisions needed when planning for end of life.
          </p>
        </Box>
        <Box>
          <HighlightCard
            text={
              "Some things that you might think of as your assets may not form part of your estate, such as superannuation, life insurance, joint tenancies, and assets held by trusts and companies. It always depends on the circumstances, so it's important to get professional advice to ensure you understand what things you can and can’t give away in your will."
            }
            borderColor="#2E5299"
            backgroundColor="#EAEDF4"
            icon={InfoIcon}
          />
        </Box>
      </Box>
      <Box>
        <Box id="assetListAddAnAsset">
          <HeadingTwo title="Add an asset" />
        </Box>
        <Box>
          <AssetListContainer />
        </Box>
      </Box>
      <Box pad={{ top: 'medium' }}>
        <StepNavigationComponent
          handleBack={handleBack}
          nextButton={
            <Button theme="primary" className="primary-button-dcs" type="button" onClick={handleNext}>
              Continue
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default AssetListPageComponent;
