export default {
  anchor: {
    textDecoration: 'underline',
  },
  font: {},
  xsmall: {
    size: '12px',
    height: '18px',
    maxWidth: '288px',
  },
  small: {
    size: '14px',
    height: '20px',
    maxWidth: '336px',
  },
  medium: {
    size: '16px',
    height: '24px',
    maxWidth: '432px',
  },
  large: {
    size: '24px',
    height: '28px',
    maxWidth: '528px',
  },
  xlarge: {
    size: '28px',
    height: '32px',
    maxWidth: '624px',
  },
  xxlarge: {
    size: '36px',
    height: '40px',
    maxWidth: '816px',
  },
};
