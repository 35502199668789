import { JSONSchema7 } from 'json-schema';
import * as realEstateSchema from '../schemas/real-estate-schema.json';
import * as bankAccountsSchema from '../schemas/bank-accounts-schema.json';
import * as lifeInsuranceSchema from '../schemas/life-insurance-schema.json';
import * as sharesSchema from '../schemas/shares-schema.json';
import * as superannuationSchema from '../schemas/superannuation-schema.json';
import * as businessSchema from '../schemas/business-schema.json';
import * as valuableItemsSchema from '../schemas/valuable-items-schema.json';
import * as otherAssetsSchema from '../schemas/other-assets-schema.json';

type SelectOption = {
  text: string;
  value:
    | 'realEstate'
    | 'bankAccount'
    | 'lifeInsurance'
    | 'superannuation'
    | 'business'
    | 'shares'
    | 'valuableAsset'
    | 'otherAsset';
  schema: JSONSchema7;
};

type DefaultLabelFields = {
  name: string;
  value:
    | 'realEstate'
    | 'bankAccount'
    | 'lifeInsurance'
    | 'superannuation'
    | 'business'
    | 'shares'
    | 'valuableAsset'
    | 'otherAsset';
  amendDefaultLabel?: (origLabel: string) => string;
};

export const assetTypesSelectOptions: SelectOption[] = [
  { text: 'Real estate', value: 'realEstate', schema: realEstateSchema as JSONSchema7 },
  { text: 'Personal Bank account', value: 'bankAccount', schema: bankAccountsSchema as JSONSchema7 },
  { text: 'Life insurance', value: 'lifeInsurance', schema: lifeInsuranceSchema as JSONSchema7 },
  { text: 'Financial products', value: 'shares', schema: sharesSchema as JSONSchema7 },
  { text: 'Superannuation', value: 'superannuation', schema: superannuationSchema as JSONSchema7 },
  { text: 'Business', value: 'business', schema: businessSchema as JSONSchema7 },
  { text: 'Valuable items', value: 'valuableAsset', schema: valuableItemsSchema as JSONSchema7 },
  { text: 'Other assets', value: 'otherAsset', schema: otherAssetsSchema as JSONSchema7 },
];

const amendSuperAssetLabel = (origLabel: string) => {
  return origLabel === 'other fund' ? 'Industry, retail or corporate fund' : origLabel;
};

const assetTitleDefaultFieldNames: DefaultLabelFields[] = [
  { name: 'addressOfTheProperty', value: 'realEstate' },
  { name: 'bankName', value: 'bankAccount' },
  { name: 'brokerDetails', value: 'shares' },
  { name: 'businessName', value: 'business' },
  {
    name: 'fundType',
    value: 'superannuation',
    amendDefaultLabel: amendSuperAssetLabel,
  },
  { name: 'nameOfInsuranceProvider', value: 'lifeInsurance' },
  { name: 'otherAssetDescription', value: 'otherAsset' },
  { name: 'typeOfItem', value: 'valuableAsset' },
];

const findOption = (assetType: string): SelectOption => {
  return assetTypesSelectOptions.find(({ value }) => value === assetType);
};

const findDefaultLabelField = (assetType: string): DefaultLabelFields => {
  return assetTitleDefaultFieldNames.find(({ value }) => value === assetType);
};

export const getSchema = (assetType: string): JSONSchema7 => {
  return findOption(assetType)?.schema;
};

export const getAssetTypeLabel = (assetType: string): string => {
  return findOption(assetType)?.text;
};

const getDefaultAssetTitle = (asset: WillPlanningForm.Asset): string => {
  const titlePrefix = findOption(asset.type)?.text;
  const defaultLabelField = findDefaultLabelField(asset.type);
  if (!defaultLabelField) {
    return titlePrefix;
  }

  const amendTitle = defaultLabelField.amendDefaultLabel;
  const titleSuffix = amendTitle ? amendTitle(asset[defaultLabelField.name]) : asset[defaultLabelField.name];
  return titlePrefix + (titleSuffix ? ` - ${titleSuffix}` : '');
};

export const getAssetTitle = (asset: WillPlanningForm.Asset): string => {
  if (asset.label) {
    return `${findOption(asset.type)?.text} - ${asset.label}`;
  }

  return getDefaultAssetTitle(asset);
};
