import BookingAdditionalNotes from 'booking/components/BookingAdditionalNotes';
import BookingLocation from 'booking/components/BookingLocation';
import BookingRadio from 'booking/components/BookingRadio';
import BookingLocationType from '../components/BookingLocationType';
import VideoConferenceInfo from '../components/VideoConferenceInfo';
import { bookingLocationOptions } from './bookingLocations';

type IsVisible = (form: Partial<Booking.MeetingFormData>) => boolean;

type FieldComponent = React.FunctionComponent<{
  name: string;
  required?: boolean;
  onChange?: (value: string) => void;
  hasError?: boolean;
}>;

interface BookingField {
  Component: FieldComponent;
  name: string;
  isVisible: IsVisible;
  data?: Record<string, unknown>;
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const bookingFields: BookingField[] = [
  {
    Component: BookingRadio,
    name: 'meetingType',
    data: {
      legend: 'Where do you want the appointment to take place? *',
      options: [
        {
          label: 'In person',
          value: 'in person',
        },
        {
          label: 'Online video conference',
          value: 'online video',
        },
      ],
    },
    isVisible: () => true,
  },
  {
    Component: VideoConferenceInfo,
    name: 'videoConferenceInfo',
    data: {},
    isVisible: (form) => form.meetingType === 'online video',
  },
  {
    Component: BookingLocationType,
    name: 'locationType',
    isVisible: (form) => form.meetingType != null,
  },
  {
    Component: BookingLocation,
    name: 'location',
    data: {
      options: bookingLocationOptions('branches', ' (Business Hours)'),
    },
    isVisible: (form) => form.locationType === 'branch',
  },
  {
    Component: BookingLocation,
    name: 'location',
    data: {
      options: bookingLocationOptions('popup', ' (Join Waitlist)'),
    },
    isVisible: (form) => form.locationType === 'pop-up',
  },
  {
    Component: BookingRadio,
    name: 'preferredTimeOfDay',
    data: {
      legend: 'Choose a preferred time of day *',
      options: [
        'Morning (9am - 11am)',
        'Lunchtime (11am - 1pm)',
        'Afternoon (1pm - 3pm)',
        'Late afternoon (3pm - 5pm)',
      ],
    },
    isVisible: (form) => form.location != null || Boolean(form.preferredTimeOfDay).valueOf(),
  },
  {
    Component: BookingRadio,
    name: 'isNswResident',
    data: {
      legend: 'Are you residing in NSW? *',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    isVisible: (form) => form.preferredTimeOfDay != null,
  },
  {
    Component: BookingRadio,
    name: 'isReceivingMaximumPension',
    data: {
      legend: 'Are you receiving the maximum Centrelink age pension? *',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    isVisible: (form) => form.isNswResident != null,
  },
  {
    Component: BookingAdditionalNotes,
    name: 'additionalNotes',
    isVisible: (form) => form.isReceivingMaximumPension != null,
  },
];
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

export const requiredFieldNames = [
  'meetingType',
  'locationType',
  'location',
  'preferredTimeOfDay',
  'isNswResident',
  'isReceivingMaximumPension',
];
