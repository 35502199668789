import { Box, Heading, Paragraph, Text } from 'grommet';
import * as React from 'react';
import { useHistory } from 'react-router';
import StepNavigationComponent from 'planning/components/StepNavigationComponent';
import PlanningService from 'planning/services/PlanningService';
import UserContext from 'user/context/UserContext';
import { routes } from 'app/common/routes';
import { Button } from '@snsw/react-component-library';
import Spinner from 'app/common/Spinner';
import ControlStatesContext from 'app/features/control-states/context/ControlStatesContext';
import { bookingFields as registeredBookingFields } from 'booking/utils/bookingFields';
import { userToBookingUserDetailsAdapter } from '../utils/userToBookingUserDetailsAdapter';
import { requiredFieldNames } from '../utils/bookingFields';
import BookingContext from '../context/BookingContext';
import BackLinkComponent from '../../planning/components/BackLinkComponent';
import BookingForm from './BookingForm';

const Booking: React.FunctionComponent = () => {
  const { booking } = React.useContext(BookingContext);
  const { isOverEighteen } = React.useContext(ControlStatesContext);
  const { user } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fieldsWithError, setFieldsWithError] = React.useState([]);
  const history = useHistory();
  const goToLegalAdvice = () => {
    history.push(routes.GET_LEGAL_ADVICE);
  };

  const goToBookingConfirmation = async () => {
    const freshFieldsWithError = getBookingFieldsWithError();
    setFieldsWithError(freshFieldsWithError);

    if (freshFieldsWithError.length !== 0) {
      return;
    }

    try {
      setIsLoading(true);

      const userDetails = userToBookingUserDetailsAdapter(user, isOverEighteen === 'yes');
      await PlanningService.sendBookingRequest(userDetails, booking, user.myAccountId);

      history.replace(routes.BOOKING_CONFIRMATION);
    } catch (error) {
      history.replace(routes.BOOKING_ERROR);
    }
  };

  const getEmptyVisibleRequiredFields = (formData, requiredFieldNames) => {
    const reducer = (accumulator, requiredFieldName) => {
      const requiredFieldArray = registeredBookingFields.filter(
        (registeredBookingField) => registeredBookingField.name === requiredFieldName,
      );
      const isVisible = requiredFieldArray.find((requiredField) => requiredField.isVisible(formData));

      // we use predefined fieldNames here
      // eslint-disable-next-line security/detect-object-injection
      if (isVisible && !formData[requiredFieldName]) {
        accumulator.push(requiredFieldName);
      }

      return accumulator;
    };
    return requiredFieldNames.reduce(reducer, []);
  };

  const getBookingFieldsWithError = () => {
    return getEmptyVisibleRequiredFields(booking, requiredFieldNames);
  };

  const updateErrorState = (fieldName: string) => {
    setFieldsWithError(fieldsWithError.filter((field) => field !== fieldName));
  };

  return (
    <Box fill>
      <Box>
        <BackLinkComponent
          handleBack={() => {
            history.push(routes.GET_LEGAL_ADVICE);
          }}
        />
      </Box>
      <Box>
        <Heading level="2" margin="0">
          Request an appointment
        </Heading>
      </Box>
      <Box width="large" pad={{ top: 'medium' }}>
        <Text>To book an appointment with NSW Trustee &amp; Guardian</Text>
        <Paragraph margin={{ top: 'xxsmall' }}>
          <Text color="red">* </Text>
          <Text>indicates a required field</Text>
        </Paragraph>
      </Box>
      <Box width="large" pad={{ top: 'medium' }}>
        <BookingForm fieldsWithError={fieldsWithError} updateCallback={updateErrorState} />
      </Box>
      <Box pad={{ top: 'large' }}>
        <StepNavigationComponent
          handleBack={goToLegalAdvice}
          nextButton={
            <Button
              theme="primary"
              className="primary-button-dcs"
              type="button"
              disabled={isLoading}
              onClick={goToBookingConfirmation}
            >
              {isLoading ? <Spinner color="#ffffff" /> : 'Confirm'}
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default Booking;
