import * as React from 'react';
import { Box, Image } from 'grommet';
import ErrorIcon from './assets/error.svg';
import TextError from './TextError';

const OptionRequired: React.FunctionComponent = () => {
  return (
    <Box direction="row" margin={{ vertical: '10px' }}>
      <Image src={ErrorIcon} width="16" height="16" margin={{ right: '10px' }} alignSelf="center" />
      <TextError>Please select an option</TextError>
    </Box>
  );
};

export default OptionRequired;
