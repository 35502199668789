import * as React from 'react';
import { bookingFields } from '../utils/bookingFields';
import BookingContext from '../context/BookingContext';

const BookingForm: React.FunctionComponent<{
  fieldsWithError: string[];
  updateCallback: (fieldName: string) => void;
}> = ({ fieldsWithError, updateCallback }) => {
  const [fieldsValues, setFieldsValues] = React.useState({});
  const { setBooking } = React.useContext(BookingContext);

  const updateFieldsValues = (fieldName: string) => (value: string) => {
    const newFieldsValues = {};
    const copyFieldsValues: Partial<Booking.MeetingFormData> = { ...fieldsValues };

    // we use predefined fieldNames here
    // eslint-disable-next-line security/detect-object-injection
    copyFieldsValues[fieldName] = value;
    bookingFields.map(({ name, isVisible }) => {
      if (isVisible(copyFieldsValues)) {
        // we use predefined fieldNames here
        // eslint-disable-next-line security/detect-object-injection
        newFieldsValues[name] = copyFieldsValues[name];
      }
    });

    // reset location as the value is dependent on locationType
    if (fieldName === 'locationType') {
      delete newFieldsValues['location'];
    }

    setFieldsValues(newFieldsValues);

    updateCallback(fieldName);
    setBooking({ ...newFieldsValues });
  };

  const hasError = (name: string): boolean => {
    return fieldsWithError && fieldsWithError.includes(name);
  };

  return (
    <form
      name="booking"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {bookingFields.map(
        ({ Component, name, data, isVisible }, index) =>
          isVisible(fieldsValues) && (
            <Component
              key={index}
              name={name}
              required={true}
              onChange={updateFieldsValues(name)}
              {...data}
              hasError={hasError(name)}
            />
          ),
      )}
    </form>
  );
};

export default BookingForm;
