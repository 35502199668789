import * as React from 'react';
import { Text as ReactPDFText, View } from '@react-pdf/renderer';
import styles from './stylesheet';

const UserDetailSection: React.FunctionComponent<{ user: User }> = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <View style={styles.section}>
      <View>
        <ReactPDFText style={styles.title}>Your details</ReactPDFText>
      </View>
      <View style={styles.dataRow}>
        <View style={styles.dataColumn}>
          <ReactPDFText>First name</ReactPDFText>
        </View>
        <View style={styles.dataColumn}>
          <ReactPDFText style={styles.dataValue}>{user?.givenName}</ReactPDFText>
        </View>
      </View>
      <View style={styles.dataRow}>
        <View style={styles.dataColumn}>
          <ReactPDFText>Last name</ReactPDFText>
        </View>
        <View style={styles.dataColumn}>
          <ReactPDFText style={styles.dataValue}>{user?.familyName}</ReactPDFText>
        </View>
      </View>
      <View style={styles.dataRow}>
        <View style={styles.dataColumn}>
          <ReactPDFText>Email address</ReactPDFText>
        </View>
        <View style={styles.dataColumn}>
          <ReactPDFText style={styles.dataValue}>{user?.email}</ReactPDFText>
        </View>
      </View>
      <View style={styles.dataRow}>
        <View style={styles.dataColumn}>
          <ReactPDFText>Phone number</ReactPDFText>
        </View>
        <View style={styles.dataColumn}>
          <ReactPDFText style={styles.dataValue}>{user?.phoneNumber}</ReactPDFText>
        </View>
      </View>
      <View style={styles.dataRow}>
        <View style={styles.dataColumn}>
          <ReactPDFText>Residential address</ReactPDFText>
        </View>
        <View style={styles.dataColumn}>
          <ReactPDFText style={styles.dataValue}>{user?.address}</ReactPDFText>
        </View>
      </View>
    </View>
  );
};

export default UserDetailSection;
