import * as React from 'react';
import { FormInput } from '@snsw/react-component-library';
import { WidgetProps, utils } from '@rjsf/core';
import { Box } from 'grommet';
import LabelAndValue from 'app/common/LabelAndValue';
import FieldLabel from '../FieldLabel';

const { getDisplayLabel } = utils;

export type TextWidgetProps = WidgetProps;

const TextWidget: React.FunctionComponent<TextWidgetProps> = ({
  id,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
}) => {
  const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value === '' ? options.emptyValue : value);
  };

  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const displayLabel = getDisplayLabel(schema, uiSchema);

  if (disabled) {
    return (
      <Box fill pad={{ vertical: 'xxsmall' }}>
        <LabelAndValue label={displayLabel ? label || schema.title : ''} value={value} />
      </Box>
    );
  } else {
    return (
      <Box margin={{ vertical: '15px' }} className="field">
        <FieldLabel htmlForId={id} label={displayLabel ? label || schema.title : ''} tooltip={uiSchema['ui:tooltip']} />
        <Box>
          <FormInput
            disabled={disabled || readonly}
            ceholder={label}
            suffix=""
            id={id}
            name={id}
            autoFocus={autofocus}
            required={required}
            type="text"
            // Value cannot be null.
            value={value || value === 0 ? value : ''}
            hasError={rawErrors.length > 0}
            errorMessage={rawErrors[0]}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
            style={{ margin: 0 }}
          />
        </Box>
      </Box>
    );
  }
};

export default TextWidget;
