const themeHelper = {
    white: props => props.theme.colors.white,
    blue: props => props.theme.colors.blue,
    black: props => props.theme.colors.black,
    red: props => props.theme.colors.red,
    blackBlue: props => props.theme.colors.blackBlue,
    darkBlue: props => props.theme.colors.darkBlue,
    lightGrey1: props => props.theme.colors.lightGrey1,
    lightGrey2: props => props.theme.colors.lightGrey2,
    lightGrey3: props => props.theme.colors.lightGrey3,
    greyBackground: props => props.theme.colors.greyBackground,
    // spaces
    0.5: props => props.theme.space[0.5] + 'px',
    1.0: props => props.theme.space[1.0] + 'px',
    1.5: props => props.theme.space[1.5] + 'px',
    2.0: props => props.theme.space[2.0] + 'px',
    2.5: props => props.theme.space[2.5] + 'px',
    3.0: props => props.theme.space[3.0] + 'px',
    3.5: props => props.theme.space[3.5] + 'px',
    4.0: props => props.theme.space[4.0] + 'px',
    4.5: props => props.theme.space[4.5] + 'px',
    5.0: props => props.theme.space[5.0] + 'px',

    // Media
    media: (field) =>(props) => props.theme.media[field],
};

export default themeHelper;