export default {
  size: {
    xxxsmall: '32px',
    xxsmall: '48px',
    xsmall: '96px',
    small: '192px',
    medium: '400px',
    large: '768px',
    xlarge: '1152px',
    xxlarge: '1536px',
    full: '100%',
  },
  edgeSize: {
    xxxxsmall: '5px',
    xxxsmall: '10px',
    xxsmall: '12px',
    xsmall: '20px',
    small: '24px',
    medium: '30px',
    large: '40px',
    xlarge: '60px',
    xxlarge: '80px',
    full: '100%',
  },
};
