import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

const ArrowWrapper = styled.span`
    svg {
        transform: rotate(${props => props.rotate}deg);
    }
`;

// eslint-disable-next-line react/prop-types
const ArrowSvg = ({ className, color, rotate, strokeWidth, width, height }) => (
    <ArrowWrapper rotate={ rotate }>
        <svg
            className={ className }
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 22 15'
            width={ width }
            height={ height }
            focusable='false'
            aria-hidden='true'
        >
            <g stroke='none' strokeWidth={ strokeWidth } fill='none' fillRule='evenodd'>
                <g transform='translate(-29.000000, -141.000000)' fill={ colors.legacy[color] || color }
                    fillRule='nonzero' stroke={ colors.legacy[color] || color } strokeWidth={ strokeWidth }>
                    <g transform='translate(24.000000, 132.000000)'>
                        <path
                            d='M20.7582701,10.1034718 C20.633334,9.96873963 20.4198135,9.96777297 20.2995226,10.0878053 C20.1772639,10.2097709 20.1682961,10.433669 20.2843612,10.5618346 L24.9315982,15.6659745 L6.32258232,15.6659745 C6.1444201,15.6659745 6,15.8152066 6,15.999305 C6,16.1834035 6.1444201,16.3326355 6.32258232,16.3326355 L24.9315982,16.3326355 L20.2843612,21.4367588 C20.1681993,21.5649244 20.1753606,21.7907891 20.2995226,21.9107881 C20.4235877,22.0307871 20.6232372,22.0337537 20.7582701,21.8951215 L25.9196356,16.2285031 C26.0423137,16.0725044 26.0100554,15.881306 25.9196356,15.770107 L20.7582701,10.1034718 Z'
                        />
                    </g>
                </g>
            </g>
        </svg>
    </ArrowWrapper>
);

const Arrow = styled(ArrowSvg)`
  ${color}
  ${layout}
`;

Arrow.defaultProps = {
    color: `${colors.brand.snswPrimaryBlue}`,
    height: 14,
    width: 22,
    marginRight: '0.5rem',
    rotate: 0,
    strokeWidth: 0.5,
};

export default Arrow;
