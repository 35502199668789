import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const BoatSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='m 40.7,36.2 c -0.4,-0.4 -1,-0.4 -1.4,0 -0.1,0.1 -1.8,1.7 -5.3,1.7 -3.5,0 -5.2,-1.6 -5.3,-1.7 -0.4,-0.4 -0.9,-0.4 -1.3,-0.1 0,0 -0.1,0 -0.1,0.1 -0.1,0.1 -1.8,1.7 -5.3,1.7 -3.5,0 -5.2,-1.6 -5.3,-1.7 -0.4,-0.4 -0.9,-0.4 -1.3,-0.1 0,0 -0.1,0 -0.1,0.1 -0.1,0.1 -1.8,1.7 -5.3,1.7 -3.5,0 -5.2,-1.6 -5.3,-1.7 -0.4,-0.4 -1,-0.4 -1.4,0 -0.4,0.4 -0.4,1 0,1.4 0.1,0.1 2.3,2.3 6.7,2.3 3,0 5,-1.1 6,-1.7 1,0.7 3,1.7 6,1.7 3,0 5,-1.1 6,-1.7 1,0.7 3,1.7 6,1.7 4.4,0 6.6,-2.2 6.7,-2.3 0.4,-0.4 0.4,-1 0,-1.4 z' />
        <path d='m 16,30.9 c -0.6,0 -1,-0.4 -1,-1 v -4.8 c 0,-1.9 2.1,-2.9 4,-3.9 l 0.2,-0.1 c 0.8,-0.4 2.1,-1 3.5,-1.6 1.7,-0.8 3.5,-1.6 5,-2.4 3.6,-1.8 7.8,2 9,3.1 2.2,2.2 2.2,4.8 2.2,7.5 v 0.2 c 0,0.6 -0.4,1 -1,1 -0.6,0 -1,-0.4 -1,-1 v -0.2 c 0,-2.5 0,-4.4 -1.6,-6 -2,-2 -4.8,-3.7 -6.7,-2.8 -1.5,0.7 -3.4,1.6 -5.1,2.4 -1.4,0.6 -2.6,1.2 -3.4,1.6 L 19.9,23 C 18.7,23.6 17,24.5 17,25.1 v 4.8 c 0,0.5 -0.4,1 -1,1 z m 12.5,-7 h 3 c 0.2,0 0.3,-0.1 0.4,-0.1 C 32,23.7 32,23.6 32,23.4 32,23.3 31.9,23.1 31.9,23 31.8,22.9 31.7,22.9 31.5,22.9 h -3 c -0.1,0 -0.3,0.1 -0.4,0.2 -0.1,0.1 -0.1,0.2 -0.1,0.4 0,0.2 0.1,0.3 0.1,0.4 0.1,-0.1 0.3,0 0.4,0 z m 0,2 c -0.7,0 -1.3,-0.3 -1.8,-0.7 -0.5,-0.5 -0.7,-1.1 -0.7,-1.8 0,-0.7 0.3,-1.3 0.7,-1.8 0.4,-0.5 1.1,-0.7 1.8,-0.7 h 3 c 0.7,0 1.3,0.3 1.8,0.7 0.5,0.5 0.7,1.1 0.7,1.8 0,0.7 -0.3,1.3 -0.7,1.8 -0.5,0.5 -1.1,0.7 -1.8,0.7 z' />
        <path d='m 15,10.9 a 4,4 0 0 1 -4,4 4,4 0 0 1 -4,-4 A 4,4 0 0 1 11,6.8999996 4,4 0 0 1 15,10.9 Z M 8.7,6.5 C 8.3,6.5 8,6.3 7.8,5.9 L 7.2,4.4 C 7,3.9 7.2,3.3 7.7,3.1 8.2,2.9 8.8,3.1 9,3.6 L 9.6,5.1 C 9.8,5.6 9.6,6.2 9.1,6.4 9,6.5 8.9,6.5 8.7,6.5 Z m 5.2,12.2 c -0.4,0 -0.8,-0.2 -0.9,-0.6 l -0.6,-1.5 c -0.2,-0.5 0,-1.1 0.5,-1.3 0.5,-0.2 1.1,0 1.3,0.5 l 0.6,1.5 c 0.2,0.5 0,1.1 -0.5,1.3 -0.2,0.1 -0.3,0.1 -0.4,0.1 z m 4,-4 c -0.1,0 -0.3,0 -0.4,-0.1 L 16,14 c -0.5,-0.2 -0.7,-0.8 -0.5,-1.3 0.2,-0.5 0.8,-0.7 1.3,-0.5 l 1.5,0.6 c 0.5,0.2 0.7,0.8 0.5,1.3 -0.2,0.4 -0.5,0.6 -0.9,0.6 z M 5.6,9.7 C 5.5,9.7 5.3,9.7 5.2,9.6 L 3.7,9 C 3.2,8.8 3,8.2 3.2,7.7 3.4,7.2 4,6.9 4.5,7.1 L 6,7.8 C 6.5,8 6.7,8.6 6.5,9.1 6.3,9.4 6,9.7 5.6,9.7 Z M 16.4,9.6 C 16,9.6 15.6,9.4 15.5,9 15.3,8.5 15.5,7.9 16,7.7 l 1.5,-0.6 c 0.5,-0.2 1.1,0 1.3,0.5 0.2,0.5 0,1.1 -0.5,1.3 L 16.8,9.5 C 16.6,9.6 16.5,9.6 16.4,9.6 Z M 4.1,14.8 C 3.7,14.8 3.3,14.6 3.2,14.2 3,13.7 3.2,13.1 3.7,12.9 l 1.5,-0.6 c 0.5,-0.2 1.1,0 1.3,0.5 0.2,0.5 0,1.1 -0.5,1.3 l -1.5,0.6 c -0.1,0 -0.2,0.1 -0.4,0.1 z m 9.1,-8.3 c -0.1,0 -0.3,0 -0.4,-0.1 C 12.3,6.2 12.1,5.6 12.3,5.1 l 0.6,-1.5 c 0.2,-0.5 0.8,-0.7 1.3,-0.5 0.5,0.2 0.7,0.8 0.5,1.3 L 14.1,5.9 C 14,6.2 13.6,6.5 13.2,6.5 Z m -5,12.3 c -0.1,0 -0.3,0 -0.4,-0.1 C 7.3,18.5 7.1,17.9 7.3,17.4 l 0.6,-1.5 c 0.2,-0.5 0.8,-0.7 1.3,-0.5 0.5,0.2 0.7,0.8 0.5,1.3 l -0.6,1.5 c -0.2,0.3 -0.5,0.6 -0.9,0.6 z' />
        <path d='M 7.2573401,30.858229 C 7.0412058,30.810174 6.8970849,30.726831 6.7530091,30.566584 6.59284,30.388438 6.5378527,30.230949 6.5195415,29.89791 l -0.014692,-0.267208 0.093309,-0.01495 c 0.094269,-0.01511 1.7672951,-0.154519 1.8543304,-0.154519 0.1017921,0 0.043595,0.644922 -0.080252,0.889321 -0.1354025,0.267204 -0.3963925,0.46072 -0.6986762,0.518047 -0.1790222,0.03395 -0.221673,0.03289 -0.4162214,-0.01037 z M 7.5,30.9 c -0.6,0 -1,-0.4 -1,-1 v -2.3 c 0,-0.6 0.4,-1 1,-1 0.6,0 1,0.4 1,1 v 2.3 c 0,0.5 -0.4,1 -1,1 z M 6,25.9 h 3 c 0.2,0 0.3,-0.1 0.4,-0.1 0,-0.1 0.1,-0.3 0.1,-0.4 C 9.5,25.3 9.4,25.1 9.4,25 9.3,24.9 9.2,24.9 9,24.9 H 6 c -0.3,0 -0.5,0.2 -0.5,0.5 0,0.3 0.2,0.5 0.5,0.5 z m 0,2 C 4.6,27.9 3.5,26.8 3.5,25.4 3.5,24 4.6,22.9 6,22.9 h 3 c 0.7,0 1.3,0.3 1.8,0.7 0.5,0.5 0.7,1.1 0.7,1.8 0,0.7 -0.3,1.3 -0.7,1.8 -0.5,0.5 -1.1,0.7 -1.8,0.7 z m 36.4,-0.6 c -0.2,-0.4 -0.6,-0.6 -1,-0.6 l -36,3 c -0.6,0 -1,0.5 -0.9,1.1 0,0.1 0,0.2 0.1,0.3 L 6,34.9 H 8.1 L 6.9,31.7 39.1,29 33.7,34.9 h 2.7 l 5.8,-6.4 c 0.3,-0.4 0.4,-0.8 0.2,-1.2' />
    </svg>
);

export const Boat = styled(BoatSvg)`
  ${color}
  ${layout}
`;

Boat.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Boat;
