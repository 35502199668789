import React from 'react';
import PropTypes from 'prop-types';
import { LoaderContainer, LoaderMessage, SpinnerImageContainer } from './Loader.styled';
import Spinner from '../Icons/Spinner';

const Loader = ({ content }) => (
    <LoaderContainer>
        <SpinnerImageContainer>
            <Spinner color='#929ea4'/>
        </SpinnerImageContainer>
        <LoaderMessage>{content}<br/></LoaderMessage>
    </LoaderContainer>
);

Loader.propTypes = {
    content: PropTypes.string,
};

Loader.defaultProps = {
    content: 'Loading...',
};

export default Loader;