export default {
  level: {
    '1': {
      font: {},
      small: {
        size: '20px',
        height: '40px',
        maxWidth: '816px',
      },
      medium: {
        size: '48px',
        height: '56px',
        maxWidth: '1200px',
      },
      large: {
        size: '82px',
        height: '88px',
        maxWidth: '1968px',
      },
      xlarge: {
        size: '114px',
        height: '120px',
        maxWidth: '2736px',
      },
    },
    '2': {
      font: {},
      small: {
        size: '26px',
        height: '32px',
        maxWidth: '624px',
      },
      medium: {
        size: '32px',
        height: '40px',
        maxWidth: '816px',
      },
      large: {
        size: '50px',
        height: '56px',
        maxWidth: '1200px',
      },
      xlarge: {
        size: '66px',
        height: '72px',
        maxWidth: '1584px',
      },
    },
    '3': {
      font: {},
      small: {
        size: '22px',
        height: '28px',
        maxWidth: '528px',
      },
      medium: {
        size: '28px',
        height: '32px',
        maxWidth: '624px',
      },
      large: {
        size: '34px',
        height: '40px',
        maxWidth: '816px',
      },
      xlarge: {
        size: '42px',
        height: '48px',
        maxWidth: '1008px',
      },
    },
    '4': {
      font: {},
      small: {
        size: '18px',
        height: '24px',
        maxWidth: '432px',
      },
      medium: {
        size: '20px',
        height: '25px',
        maxWidth: '278px',
      },
      large: {
        size: '18px',
        height: '24px',
        maxWidth: '432px',
      },
      xlarge: {
        size: '24px',
        height: '24px',
        maxWidth: '432px',
      },
    },
    '5': {
      font: {},
      small: {
        size: '16px',
        height: '22px',
        maxWidth: '384px',
      },
      medium: {
        size: '20px',
        height: '22px',
        maxWidth: '384px',
      },
      large: {
        size: '16px',
        height: '22px',
        maxWidth: '384px',
      },
      xlarge: {
        size: '20px',
        height: '22px',
        maxWidth: '384px',
      },
    },
    '6': {
      font: {},
      small: {
        size: '14px',
        height: '20px',
        maxWidth: '336px',
      },
      medium: {
        size: '14px',
        height: '20px',
        maxWidth: '336px',
      },
      large: {
        size: '14px',
        height: '20px',
        maxWidth: '336px',
      },
      xlarge: {
        size: '14px',
        height: '20px',
        maxWidth: '336px',
      },
    },
  },
};
