import { client, getHeaders } from 'app/common/services/DataService';

export const getDocumentLocations = async (user: User): Promise<any> => {
  const { data } = await client.get(`/documentLocation/${user.myAccountId}`, getHeaders());
  return data;
};

export const updateDocumentLocations = async (user: User, documentLocation): Promise<any> => {
  return client.patch(`/documentLocation/${user.myAccountId}`, documentLocation, getHeaders());
};

export default {
  getDocumentLocations,
  updateDocumentLocations,
};
