import styled from 'styled-components/macro';
import Heading from '../Headings/Heading';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../../utils';

const { colors, font } = tokens;

const StyledSectionHeader = styled.header`
    background: ${colors.grey.iceBlue};
    padding: ${getSpacing(['sm', 'none'])};

    ${getBreakpoint.md`
        padding: ${getSpacing(['lg', 'none', 'md', 'none'])};
    `}

    ${getBreakpoint.print`
        padding: 0;
        background: 0;
    `}

    .zob {
        color: #6E6C6E;
        background: #2B292B;
        font-size: 90px;
    }

    h1 {
        margin: 0;
        max-width: ${pxToRem(768)}
    }

    p {
        margin-top: ${getSpacing('xs')};
        margin-bottom: 0;
        max-width: ${pxToRem(768)}
    }

    .notice:last-of-type {
        margin-bottom: 0;
    }

    @media print {
        .container {
            border-bottom: 2px solid ${colors.grey.geyser};
            padding: 0 0 25pt;
        }
    }

    .page-header__label { // note: match PageLabel styles
        font-size: ${pxToRem(14)};
        line-height: ${font.lineHeight.heading};
    }
`;

export const StyledFormHeader = styled(StyledSectionHeader)`
    padding: ${getSpacing(['md', 'none', 'lg', 'none'])};

    ${getBreakpoint.md`
        padding: ${getSpacing(['xl', 'none', 'xxl', 'none'])};
    `}
`;

export const PageNav = styled.div`
    margin-bottom: ${getSpacing('sm')};
`;

export const PageLabel = styled.span`
    font-size: ${pxToRem(14)};
    line-height: ${font.lineHeight.heading};
`;

export const PageTitle = styled(Heading).attrs({ level: 1 })``;

export default StyledSectionHeader;
