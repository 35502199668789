import * as React from 'react';
import { Redirect, Switch, useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { Header as GrommetHeader, Footer as GrommetFooter, Main, Heading, Box, Text, ResponsiveContext } from 'grommet';
import Header from 'header/components/Header';
import Footer from 'footer/components/Footer';
import Home from 'app/features/home/Home';
import GetLegalAdvice from 'app/features/legal-advise/GetLegalAdvice';
import UserDetails from 'app/features/user-details/UserDetails';
import Questions from 'planning/components/Questions';
import Summary from 'planning/components/Summary';
import AssetListPageComponent from 'planning/components/AssetListPageComponent';
import Booking from 'booking/components/Booking';
import BookingConfirmation from 'booking/components/BookingConfirmation';
import { BookingProvider } from 'booking/context/BookingContext';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Idle from 'react-idle';
import { ControlStatesProvider } from './features/control-states/context/ControlStatesContext';
import { routes } from './common/routes';
import UserInteractions from './features/control-states/components/UserInteractions';
import BookingSuccess from './features/booking/components/BookingSuccess';
import BookingError from './features/booking/components/BookingError';
import ActionModal from './common/ActionModal';
import UserContext from './features/user/context/UserContext';
import { logout } from './features/user/components/AuthService';
import { FIFTEEN_MINUTES_IDLE_TIMEOUT } from './common/config';
import { redirectTo } from './common/navigation';
import DocumentLocation from './features/documentLocation/components/DocumentLocation';
import EnduringGuardianStatus from './features/enduring-guardian/components/EnduringGuardianStatus';
import EnduringGuardianDetails from './features/enduring-guardian/components/EnduringGuardianDetails';
import EnduringGuardianAdvanceCareDirective from './features/enduring-guardian/components/EnduringGuardianAdvanceCareDirective';

type WrapperProps = {
  pathname: string;
};

const Wrapper = styled.div<WrapperProps>`
  margin-bottom: ${({ pathname }) => (pathname === routes.SUMMARY ? '96px' : '0')};
`;

const PlanningFlow: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { setUser } = React.useContext(UserContext);
  const size = React.useContext(ResponsiveContext);
  const [isIdleModalVisible, setIsIdleModalVisible] = React.useState(false);

  const handleIdle = (idle: boolean) => {
    if (idle) {
      setIsIdleModalVisible(true);
    }
  };

  const clearUser = async () => {
    setUser(null);
    return logout();
  };

  const handleContinue = async () => {
    try {
      await clearUser();
    } finally {
      history.push(routes.PLANNER_BASE_PATH);
    }
  };

  const handleLeaveApp = async () => {
    try {
      await clearUser();
    } finally {
      redirectTo(process.env.LOGOUT_REDIRECT);
    }
  };

  return (
    <>
      <Wrapper pathname={location.pathname}>
        <GrommetHeader>
          <Header />
        </GrommetHeader>
        <Main pad={{ bottom: 'small', horizontal: '5%' }}>
          <ControlStatesProvider>
            <UserInteractions />
            <Switch>
              <Route exact path={routes.HOME}>
                <Home />
              </Route>
              <Route exact path={routes.GET_LEGAL_ADVICE}>
                <GetLegalAdvice />
              </Route>
              <Route exact path={routes.USER_DETAILS}>
                <UserDetails />
              </Route>
              <Route exact path={routes.QUESTIONS}>
                <Questions />
              </Route>
              <Route exact path={routes.ASSETS}>
                <AssetListPageComponent />
              </Route>
              <Route exact path={routes.SUMMARY}>
                <Summary />
              </Route>
              <Route exact path={routes.ENDURING_GUARDIAN_STATUS}>
                <EnduringGuardianStatus />
              </Route>
              <Route exact path={routes.ENDURING_GUARDIAN_DETAILS}>
                <EnduringGuardianDetails />
              </Route>
              <Route exact path={routes.ENDURING_GUARDIAN_ADVANCE_CARE_DIRECTIVE}>
                <EnduringGuardianAdvanceCareDirective />
              </Route>
              <Route exact path={routes.DOCUMENT_LOCATION}>
                <DocumentLocation />
              </Route>
              <BookingProvider>
                <Route exact path={routes.BOOKING}>
                  <Booking />
                </Route>
                <Route exact path={routes.BOOKING_CONFIRMATION}>
                  <BookingConfirmation>
                    <BookingSuccess />
                  </BookingConfirmation>
                </Route>
                <Route exact path={routes.BOOKING_ERROR}>
                  <BookingConfirmation>
                    <BookingError />
                  </BookingConfirmation>
                </Route>
              </BookingProvider>
              <Redirect to={routes.HOME} />
            </Switch>
          </ControlStatesProvider>
        </Main>
        <GrommetFooter>
          <Footer />
        </GrommetFooter>
      </Wrapper>
      <Idle timeout={FIFTEEN_MINUTES_IDLE_TIMEOUT} onChange={handleIdle}></Idle>
      {isIdleModalVisible && (
        <ActionModal
          onModalClose={handleLeaveApp}
          handleContinue={handleContinue}
          cancelButtonText="Close"
          continueButtonText="Log back in"
          style={
            size === 'small' || size === 'medium'
              ? {
                  display: 'block',
                  height: '100%',
                  width: '100%',
                  position: 'fixed',
                  overflow: 'scroll',
                  webkitOverflowScrolling: 'touch',
                }
              : null
          }
        >
          <Box>
            <Box>
              <Heading level="3" margin={{ bottom: 'large' }}>
                Your session has timed out
              </Heading>
            </Box>
            <Box>
              <Text margin={{ bottom: 'xlarge' }}>
                After a period of inactivity, and to protect your security you&apos;ve been logged out.
              </Text>
            </Box>
          </Box>
        </ActionModal>
      )}
    </>
  );
};

export default PlanningFlow;
