import styled, { css } from 'styled-components/macro';
import { Separator } from './Separator.styled';
import { LabelContainer } from './Label.styled';
import { colors } from '../GlobalStyle/colors/colors';
import { H6 } from '../Headers/HeaderTag.styled';

export const AccordionStyled = styled.div`
  ${props => props.border ?
        css`
      border-radius: 6px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
    ` :
        null};
  background-color: white;
`;

export const AccordionTileContainer = styled.div`
  position: relative;
  padding: 1.5rem 1.5rem 1.5rem ${props => props.stepAccordion ? '3.5rem' : '1.5rem'};
  ${Separator} {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ${
    props => {
        if (props.separator === undefined) {
            return css`
              :not(:first-child) {
                border-top: 1px solid #dee3e5;
              }
            `;
        } else if (props.separator) {
            return css`
              border-top: 1px solid #dee3e5;
            `;
        }
    }};
  
  .progress {
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    left: 0;
    
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
  }
  
  .top {
    width: 100%;
    height: 38px;
    min-height: 38px;
  }
  
  .bottom {
    width: 100%;
    height: 100%;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: baseline;
  user-select: none;
  outline: none;
  h5 {
      margin-right: 1.4375rem;
  }
  ${LabelContainer} {
    position: absolute;
    left: 0;
    top: 1.5rem;
  }
  
  ${props => props.stepAccordionSeparator ?
        css`
      :before {
        content: '';
        display: block;
        width: calc(100% - 3.5rem);
        border-top: ${colors.lightGrey2} solid 1px;
        position: absolute;
        top: 0;
      }
    ` :
        null}
`;

export const AccordionSubTitle = styled(H6)`
    margin: 0 !important;
    flex-grow: 1;
    max-width: 100%;
`;

export const ToggleIcon = styled.div`
  padding-top: 5px;
  transition: transform .4s ease-in-out;
  transform: ${props => props.rotateIt ? 'rotate(180deg)' : 'none'};
`;

export const AccordionSummary = styled('p')`
  padding: 1rem 0 0 0;
`;

export const AccordionBody = styled('div')`
  padding: 1rem 0 0 0;
  ul, ol {
    margin-top: 0;
    margin-bottom: 0;
    li {
      padding: 0.4rem 0;
    }
  }

  button:last-child, a:last-child {
    margin-top: 1.5rem;
  }
`;
