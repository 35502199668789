import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Device } from '../Device/Device';
import { ExpandedBreadcrumb } from './ExpandedBreadcrumb';
import { CollapsedBreadcrumb } from './CollapsedBreadcrumb';
import { BreadCrumbContainer, BreadCrumbList } from './Breadcrumb.styled';
import { trackMe } from '../Components/ComponentTracker/componentTracker';

export const Breadcrumb = ({ className, linksList, hideContentOnMobile, industry }) => {
    useEffect(() => {
        trackMe('Breadcrumb');
    }, []);

    const links = linksList.map((link) => ({
        ...link,
        path: industry ? industry.path(link.path) : link.path,
    }));

    return (
        <BreadCrumbContainer aria-label='Breadcrumbs' className={ className }>
            <Device tablet desktop>
                <BreadCrumbList>
                    <ExpandedBreadcrumb linksList={ links }/>
                </BreadCrumbList>
            </Device>
            <Device mobile>
                <CollapsedBreadcrumb linksList={ links } hideContent={ hideContentOnMobile }/>
            </Device>
        </BreadCrumbContainer>
    );
};

Breadcrumb.propTypes = {
    className: PropTypes.string,
    linksList: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    })),
    hideContentOnMobile: PropTypes.bool,
    industry: PropTypes.object,
};

Breadcrumb.defaultProps = {
    linksList: [{ content: 'Dashboard', path: '/dashboard' }],
    hideContentOnMobile: false,
};

export default Breadcrumb;
