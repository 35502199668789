import React from 'react';
import { Box, Image, Text } from 'grommet';
import RadioButton from 'app/common/RadioButton';
import styled from 'styled-components';
import TextError from '../../common/TextError';
import TooltipComponent from '../../common/TooltipComponent';
import ErrorIcon from '../../common/assets/error.svg';

const FormRadioGroupContainer = styled(Box)`
  fieldset {
    margin-top: 0;
  }

  label {
    font-size: 16px;
  }
`;

type RadioOptionType = {
  value: string;
  label: string;
};

type RadioGroupType = {
  id: string;
  name: string;
  label: string;
  tooltip?: string;
  value: string;
  options: RadioOptionType[];
  handleOnChange: (value: string) => void;
  hasError: boolean;
};

const radioOptionToolTip = (option) => (
  <Box direction="row" gap="xxxsmall" align="center" justify="start">
    <Box>{option.label}</Box>
    {option.tooltip && (
      <Box>
        <TooltipComponent text={option.tooltip} />
      </Box>
    )}
  </Box>
);

const RadioGroup: React.FunctionComponent<RadioGroupType> = ({
  id,
  name,
  label,
  tooltip,
  value,
  options = [],
  handleOnChange,
  hasError,
}) => {
  return (
    <FormRadioGroupContainer pad={{ bottom: 'xxxsmall' }} fill>
      <fieldset>
        <legend>
          <Box direction="row" align="center" justify="start" pad={{ bottom: '16px' }}>
            <Text size="16px" weight="bold">
              {label}
            </Text>
            {tooltip && (
              <Box pad={{ left: 'xxxxsmall' }}>
                <TooltipComponent text={tooltip} />
              </Box>
            )}
          </Box>
        </legend>
        {(options as Array<RadioOptionType>).map((option, index) => (
          <Box pad={{ bottom: '16px' }} key={`${id}-${index}`}>
            <RadioButton
              id={`${id}-${index}`}
              key={`${id}-${index}`}
              name={name}
              label={radioOptionToolTip(option)}
              onChange={(event) => handleOnChange(event.target.value)}
              checked={value === option.value}
              value={option.value}
              hasError={hasError}
            />
          </Box>
        ))}
        {hasError && (
          <Box margin={{ top: '8px' }}>
            <Box direction="row" align="center" gap="small">
              <Box>
                <Image src={ErrorIcon} />
              </Box>
              <Box>
                <TextError>Please select an option</TextError>
              </Box>
            </Box>
          </Box>
        )}
      </fieldset>
    </FormRadioGroupContainer>
  );
};

export default RadioGroup;
