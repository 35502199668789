import { AccordionPanel, Box } from 'grommet';
import styled from 'styled-components';

export const CustomAccordionPanel = styled(AccordionPanel)`
  > div {
    padding: 0;
  }
`;

export const AccordionLabel = styled(Box)`
  font-weight: 600;
  max-width: 90%;

  span {
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;
