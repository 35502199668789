import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Heading from '../Headings/Heading';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../../utils';

const { colors, font } = tokens;

const Notice = styled.div.attrs(
    props => ({
        className: classNames(props.className),
        role: 'status',
    })
)`
    line-height: ${font.lineHeight.default};
    border-left: 6px solid ${colors.borders.info};
    background: ${colors.backgrounds.info}
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%232e5299'%3E%3Cpath d='M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm1.2 13.2h-2.4V6h2.4v7.2zm0 4.8h-2.4v-2.4h2.4V18z'/%3E%3C/svg%3E")
        no-repeat ${getSpacing([12, 'sm'])};
    margin: ${getSpacing(['sm', 'none'])};
    padding: ${getSpacing([48, 'sm', 'sm', 12])};

    a {
        color: ${colors.text.body};
        cursor: pointer;
        text-decoration: underline;
    }

    h2 {
        font-size: ${pxToRem(font.size.base)};
        line-height: ${font.lineHeight.default};
        font-weight: ${font.weight.bold};
    }

    h2, li, ol, p, ul {
        margin: ${getSpacing(['none', 'none', 4])};
        max-width: 100%;
    }

    li:last-child,
    ol:last-child,
    p:last-child,
    ul:last-child {
        margin-bottom: 0;
    }

    ${props => props.info && infoNoticeStyle};

    ${props => props.error && errorNoticeStyle};

    ${props => props.warning && warningNoticeStyle};

    ${props => props.success && successNoticeStyle};

    ${props => props.plain && plainNoticeStyle};

    ${getBreakpoint.md`
        padding: ${getSpacing(['md', 'md', 'md', 54])};
        background-position: ${getSpacing([12, 'md'])};
    `}

    ${getBreakpoint.print`
        background: none !important;
        padding: ${getSpacing(['sm', 'none', 'sm', 'sm'])};
    `}
`;

const infoNoticeStyle = css`
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%232E5299'%3E%3Cpath d='M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm1.2 18h-2.4v-7.2h2.4V18zm0-9.6h-2.4V6h2.4v2.4z'/%3E%3C/svg%3E");
`;

const errorNoticeStyle = css`
    background-color: ${colors.backgrounds.error};
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23B81237'%3E%3Cpath d='M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm5.878 16.2L16.2 17.878l-4.2-4.2-4.2 4.2L6.122 16.2l4.2-4.2-4.2-4.2L7.8 6.122l4.2 4.2 4.2-4.2L17.878 7.8l-4.2 4.2 4.2 4.2z'/%3E%3C/svg%3E");
    border-color: ${colors.borders.error};
`;

const warningNoticeStyle = css`
    background-color: ${colors.backgrounds.warning};
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23dc5800'%3E%3Cpath d='M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm1.2 13.2h-2.4V6h2.4v7.2zm0 4.8h-2.4v-2.4h2.4V18z'/%3E%3C/svg%3E");
    border-color: ${colors.borders.warning};
`;

const successNoticeStyle = css`
    background-color: ${colors.backgrounds.success};
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%2300A908'%3E%3Cpath d='M12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0zm6.681 6l-9.434 9.4-3.928-3.914L4 12.8l4.588 4.571.66.629.659-.629L20 7.314 18.681 6z'/%3E%3C/svg%3E");
    border-color: ${colors.borders.success};
`;

const plainNoticeStyle = css`
    background-image: none;
    padding-top: ${getSpacing('sm')};
`;

Notice.propTypes = {
    info: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    success: PropTypes.bool,
    plain: PropTypes.bool,
    className: PropTypes.string,
};

Notice.Title = styled(Heading).attrs({ level: 6 })`
    margin: ${getSpacing(['none', 'none', 4])};
    max-width: 100%;
`;

Notice.Copy = styled.p``;

export default Notice;
