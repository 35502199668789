import * as React from 'react';
const { createContext, useState } = React;

interface UserContext {
  user: User;
  setUser: (user: User) => void;
}

const UserContext = createContext<UserContext>(null);

export const UserProvider: React.FunctionComponent = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
