import axios from 'axios';
import { getIdTokenInLocalStorage } from 'app/features/user/components/AuthService';

const client = axios.create({
  baseURL: process.env.EOL_API_URL,
});

const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${process.env.USER_TOKEN || getIdTokenInLocalStorage()}`,
      'X-API-Key': process.env.X_API_KEY,
    },
  };
};

const getUserDetails = async (user: User) => {
  const { data: userDetails } = await client.get(`/userDetails/${user.myAccountId}`, getHeaders());

  return userDetails;
};

const createUserDetails = async (user: User): Promise<WillPlanningForm.Will> => {
  const { data: will } = await client.post(
    `/userDetails/${user.myAccountId}`,
    {
      email: user.email,
    },
    getHeaders(),
  );

  return will;
};

const updateUserDetails = async (user: User, userDetails: any) => {
  return client.patch(
    `/userDetails/${user.myAccountId}`,
    {
      email: user.email,
      ...userDetails,
    },
    getHeaders(),
  );
};

export default {
  getUserDetails,
  createUserDetails,
  updateUserDetails,
};
