import { Box } from 'grommet';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Button } from '@snsw/react-component-library';
import Logout from 'app/common/Logout';
import { routes } from 'app/common/routes';
import BackLinkComponent from '../../planning/components/BackLinkComponent';

const BookingConfirmation: React.FunctionComponent = ({ children }) => {
  const history = useHistory();

  const handleNext = () => {
    history.push(routes.HOME);
  };

  React.useEffect(() => {
    (window as any).quickFeed.init({
      placeholder: 'QuickfeedPlaceholder',
      label: 'How was your experience?',
      environment: process.env.TUTD_ENV,
      sourceApplication: 'Life Journey - EOL Planning',
      positionTop: 'calc(100vh - 220px)',
    });
  }, []);

  return (
    <Box gap="large">
      <Box>
        <BackLinkComponent
          handleBack={() => {
            history.push(routes.BOOKING);
          }}
        />
      </Box>
      {children}
      <Box width="large">
        <div id="QuickfeedPlaceholder"></div>
      </Box>
      <Box direction="row-responsive" justify="between" align="center" gap="medium" pad={{ top: 'xlarge' }}>
        <Box>
          <Logout type="button" />
        </Box>
        <Box>
          <Button theme="primary" className="primary-button-dcs" type="button" onClick={handleNext}>
            Return to homepage
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingConfirmation;
