import * as React from 'react';
import { Card, CardBody, CardHeader, Heading, ResponsiveContext, Text } from 'grommet';

type Props = {
  header: string | JSX.Element;
  body: string | JSX.Element;
  onClick?: () => void;
};

const ActionCard: React.FunctionComponent<Props> = ({ header, body, onClick }: Props) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Card
          round={{ corner: 'bottom', size: '4px' }}
          pad="medium"
          border={{ side: 'top', color: '#D7153A', size: 'medium' }}
          width="328px"
          height={size === 'small' ? '128px' : 'small'}
          justify="between"
          onClick={onClick}
        >
          <CardHeader pad={{ bottom: '10px' }}>
            <Heading level="4" size="medium" style={{ color: '#002664' }}>
              {header}
            </Heading>
          </CardHeader>
          <CardBody>
            <Text style={{ color: '#44405B' }}>{body}</Text>
          </CardBody>
        </Card>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default ActionCard;
