import { Font, StyleSheet } from '@react-pdf/renderer';
import MontserratBold from '../../assets/fonts/Montserrat-Bold.ttf';
import MontserratRegular from '../../assets/fonts/Montserrat-Regular.ttf';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: MontserratRegular, fontWeight: 'regular' },
    { src: MontserratBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    paddingBottom: '90pt',
    paddingHorizontal: '50pt',
  },
  header: {
    width: '100%',
    height: '50pt',
    flexDirection: 'row',
  },
  body: {
    marginVertical: '30pt',
    fontSize: '10pt',
    fontFamily: 'Montserrat',
  },
  section: {
    color: '#242934;',
    marginBottom: '30pt',
  },
  title: {
    fontSize: '12pt',
    fontWeight: 'bold',
    marginBottom: '15pt',
  },
  image: {
    objectFit: 'contain',
    height: '100%',
  },
  dataRow: {
    flexDirection: 'row',
    marginBottom: '10pt',
  },
  dataColumn: {
    width: '50%',
    marginRight: '15pt',
    alignContent: 'flex-start',
  },
  dataValue: {
    fontWeight: 'bold',
  },
  watermark: {
    position: 'absolute',
    height: '400pt',
    top: '220pt',
    left: '115pt',
  },
  horizontalLine: {
    borderBottom: '1pt',
    marginBottom: '15pt',
  },
  footer: {
    position: 'absolute',
    bottom: '30pt',
    left: '50pt',
    paddingHorizontal: '10pt',
    paddingVertical: '10pt',
    fontSize: '8pt',
    fontFamily: 'Montserrat',
    border: '1pt',
    borderRadius: '5pt',
    flexDirection: 'row',
    minHeight: '20pt',
  },
  asset: {
    marginBottom: '30pt',
  },
  question: {
    marginBottom: '30pt',
  },
  topSpace: {
    marginTop: '30pt',
  },
  warningIconContainer: {
    width: '5%',
  },
  iconImage: {
    objectFit: 'contain',
    height: '15pt',
  },
  warningTextContainer: {
    paddingLeft: '10pt',
    width: '95%',
  },
});

export default styles;
