import * as React from 'react';
import { Box, Card, Text, Image } from 'grommet';
import infoIcon from './assets/info.svg';

type Props = {
  title?: string;
  paragraphs: Array<string>;
  type?: 'info' | 'legal' | 'advise';
  width?: string;
};

const cardTypes = {
  info: {
    borderColor: '#2E5299',
    backgroundColor: '#EAEDF4',
    noIcon: false,
    pad: { vertical: '20px', right: '26px' },
    bottomMargin: 'large',
    contentPad: { left: 'small' },
  },
  legal: {
    borderColor: '#D7153A',
    backgroundColor: 'transparent',
    noIcon: true,
    pad: {},
    bottomMargin: 'xxsmall',
    contentPad: { left: 'small' },
  },
  advise: {
    borderColor: '#002664',
    backgroundColor: '#F4F4F7',
    noIcon: true,
    pad: { vertical: '16px' },
    bottomMargin: 'xxsmall',
    contentPad: { left: '22px', right: '25px' },
  },
};

const HighlightCardParagraphs: React.FunctionComponent<Props> = ({
  title,
  paragraphs,
  type = 'info',
  width = 'auto',
}: Props) => {
  // eslint-disable-next-line security/detect-object-injection
  const cardType = cardTypes[type];
  return (
    <Card
      width={width}
      background={cardType.backgroundColor}
      border={{ side: 'left', color: cardType.borderColor, size: 'medium' }}
      round=""
      elevation="none"
      pad={cardType.pad}
      margin={{ bottom: cardType.bottomMargin }}
    >
      <Box direction="row">
        {!cardType.noIcon && (
          <Box width={{ min: '28px', max: 'small' }} margin={{ left: 'xsmall', right: 'small' }}>
            <Image src={infoIcon} />
          </Box>
        )}
        <Box pad={cardType.contentPad}>
          {title && (
            <Text weight="bold" margin={{ bottom: '10px' }}>
              {title}
            </Text>
          )}
          {paragraphs.map((paragraph, i) => {
            return (
              <Text style={{ color: '#000000' }} key={i}>
                {paragraph}
              </Text>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
};

export default HighlightCardParagraphs;
