import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from './Field.styled';
import { getID } from '../../utils';
import { trackMe } from '../ComponentTracker/componentTracker';

export const FormSelect = ({
    id,
    label,
    placeholder,
    value,
    options,
    onChange,
    disabled,
    hasError,
    errorMessage,
    testId,
    small,
    helpMessage,
    name,
    inputRef,
    inputWidth,
    ...rest
}) => {

    useEffect(() => {
        trackMe('FormSelect');
    }, []);

    const handleOnChange = (e) => {
        onChange(e, options.find(option => option.value === e.target.value) || {});
    };

    const genID = () => {
        return id || getID();
    };

    const elemID = genID();
    const idError = `${elemID}-error`;
    const idHelper = `${elemID}-helper`;

    return (
        <Field { ...rest }>
            {label && <Field.Label htmlFor={ elemID }>{label}</Field.Label>}
            {helpMessage && <Field.Help id={ idHelper }>{helpMessage}</Field.Help>}
            <Field.Select
                id={ elemID }
                disabled={ disabled }
                onChange={ handleOnChange }
                value={ value }
                hasError={ hasError }
                data-testid={ testId }
                small={ small }
                aria-invalid={ hasError }
                aria-describedby={ `${idError} ${idHelper}` }
                aria-label={ elemID }
                name={ name }
                ref={ inputRef }
                inputWidth={ inputWidth }
            >
                {placeholder && <option value=''>{placeholder}</option>}
                {options.map((option, idx) =>
                    <option key={ idx } value={ option.value }>
                        {option.text}
                    </option>)}
            </Field.Select>
            {hasError && <Field.Error id={ idError }>{errorMessage}</Field.Error>}
        </Field>
    );
};

FormSelect.propTypes = {
    id: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    placeholder: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    testId: PropTypes.string,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    helpMessage: PropTypes.string,
    name: PropTypes.string,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
};

FormSelect.defaultProps = {
    id: '',
    label: '',
    placeholder: '',
    errorMessage: '',
    testId: '',
    hasError: false,
    disabled: false,
    small: false,
    helpMessage: '',
};

export default FormSelect;
