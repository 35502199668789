import * as React from 'react';
import { FieldTemplateProps } from '@rjsf/core';
import { Box } from 'grommet';
import { H3 } from '@snsw/react-component-library';
import WrapIfAdditional from './WrapIfAdditional';

const FieldTemplate: React.FunctionComponent<FieldTemplateProps> = ({
  id,
  children,
  classNames,
  disabled,
  displayLabel,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  rawHelp,
  rawDescription,
  schema,
}) => {
  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      <Box>
        {children}
        {displayLabel && rawDescription ? <H3>{rawDescription}</H3> : null}
        {rawHelp && <p id={id}>{rawHelp}</p>}
      </Box>
    </WrapIfAdditional>
  );
};

export default FieldTemplate;
