import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icons from '../Icons/index';
import styled from 'styled-components/macro';

export const CollapsedBreadcrumb = ({ linksList, hideContent }) =>
    <CollapsedBreadcrumbLink to={ linksList[linksList.length - 1].path }>
        <Icons.ChevronLeft/>
        {!hideContent &&
            <span>{linksList[linksList.length - 1].content}</span>
        }
    </CollapsedBreadcrumbLink>;

export const CollapsedBreadcrumbLink = styled(Link)`
  display: flex;
  line-height: 1rem;
  svg {
    margin-right: 0.5rem;
    path {
        fill: #242934;
    }
  }
`;

CollapsedBreadcrumb.propTypes = {
    linksList: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    })),
    hideContent: PropTypes.bool,
};