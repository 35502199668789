import { withTheme } from '@rjsf/core';
import Form from 'app/theme/Form/Form';
import { Box } from 'grommet';
import * as React from 'react';
import LabelAndValue from 'app/common/LabelAndValue';
import { routes } from 'app/common/routes';
import { assetTypesSelectOptions } from '../utils/assetTypesSelectOptions';
import { uiSchema } from './UISchema';
import SummaryHeaderComponent from './SummaryHeaderComponent';

const ThemedForm = withTheme(Form(true, null));

type Props = {
  assets: WillPlanningForm.AssetsState;
};

const YourAssetsComponent: React.FunctionComponent<Props> = ({ assets }: Props) => {
  return (
    <Box fill>
      <Box>
        <SummaryHeaderComponent text="Your assets" goTo={routes.ASSETS} />
      </Box>
      <Box width="xlarge">
        {assets.map((asset, index) => {
          const option = assetTypesSelectOptions.find((assetType) => assetType.value === asset?.type);
          return (
            <Box key={index} fill pad={{ bottom: 'medium' }} className="asset">
              <Box>
                <LabelAndValue label="Asset type" value={option.text} />
              </Box>
              <Box>
                <ThemedForm
                  uiSchema={{ ...uiSchema, 'ui:description': ' ' }} // empty so it won't render the description from the json schema
                  schema={option?.schema}
                  formData={asset}
                  disabled={true}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default YourAssetsComponent;
