import * as React from 'react';
import { Box, Text, Anchor } from 'grommet';
import { Button } from '@snsw/react-component-library';
import RequiredField from 'app/common/RequiredField';
import FieldRequired from 'app/common/FieldRequired';
import OptionRequired from 'app/common/OptionRequired';
import WarningCard from 'app/common/WarningCard';
import RadioButton from 'app/common/RadioButton';
import TextInput from 'app/common/TextInput';
import TextArea from 'app/common/TextArea';
import TextBold from 'app/common/TextBold';
import { DocumentType, Location } from './DocumentLocation';

type Props = {
  docTypeData: any;
  onSave: (updated: any) => void;
  closeAccordion: () => void;
};

const ReusablePanel: React.FunctionComponent<Props> = ({ docTypeData, onSave, closeAccordion }: Props) => {
  const [type, setType] = React.useState(docTypeData?.type);
  const [location, setLocation] = React.useState(docTypeData?.location);
  const [label, setLabel] = React.useState(docTypeData?.label);
  const [documentType, setDocumentType] = React.useState(docTypeData?.documentType); // other documents only..
  const [firmName, setFirmName] = React.useState(docTypeData?.firmName || '');
  const [suburb, setSuburb] = React.useState(docTypeData?.suburb || '');
  const [bankName, setBankName] = React.useState(docTypeData?.bankName || '');
  const [branch, setBranch] = React.useState(docTypeData?.branch || '');
  const [otherDetails, setOtherDetails] = React.useState(docTypeData?.otherDetails || '');

  const onLocationChange = (e: React.ChangeEvent<HTMLInputElement>, label) => {
    const location = e.target.value;
    setLocation(location);
    setLabel(label);
    setLocationTypeValid(true);
    if (Location.Solicitor.value === location) {
      setBankName('');
      setBranch('');
      setOtherDetails('');
    }
    if (Location.Bank.value === location) {
      setFirmName('');
      setSuburb('');
      setOtherDetails('');
    }
    if (Location.TrueteeAndGuardian.value === location) {
      setFirmName('');
      setSuburb('');
      setBankName('');
      setBranch('');
      setOtherDetails('');
    }
    if (Location.Home.value === location) {
      setFirmName('');
      setSuburb('');
      setBankName('');
      setBranch('');
      setOtherDetails('');
    }
    if (Location.Other.value === location) {
      setFirmName('');
      setSuburb('');
      setBankName('');
      setBranch('');
    }
  };

  const [locationValid, setLocationTypeValid] = React.useState(true);
  const [documentTypeValid, setDocumentTypeValid] = React.useState(true); // other documents only..
  const [firmNameValid, setFirmNameValid] = React.useState(true);
  const [bankNameValid, setBankNameValid] = React.useState(true);
  const [otherDetailsValid, setOtherDetailsValid] = React.useState(true);

  const validateDocumentType = (value) => {
    return !!value; // other documents only..
  };
  const validateFirmName = (value) => {
    return location === Location.Solicitor.value && value;
  };
  const validateBankName = (value) => {
    return location === Location.Bank.value && value;
  };
  const validateOtherDetails = (value) => {
    return location === Location.Other.value && value;
  };

  const validate = () => {
    let valid = true;
    if (!location) {
      setLocationTypeValid(false);
      valid = false;
    }
    if (isOther() && !documentType) {
      setDocumentTypeValid(false); // other documents only..
      valid = false;
    }
    if (location === Location.Solicitor.value && !firmName) {
      setFirmNameValid(false);
      valid = false;
    }
    if (location === Location.Bank.value && !bankName) {
      setBankNameValid(false);
      valid = false;
    }
    if (location === Location.Other.value && !otherDetails) {
      setOtherDetailsValid(false);
      valid = false;
    }

    return valid;
  };

  const onDocumentTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // other documents only..
    setDocumentType(e.target.value);
    if (validateDocumentType(e.target.value)) {
      setDocumentTypeValid(true);
    }
  };
  const onFirmNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirmName(e.target.value);
    if (validateFirmName(e.target.value)) {
      setFirmNameValid(true);
    }
  };
  const onBankNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBankName(e.target.value);
    if (validateBankName(e.target.value)) {
      setBankNameValid(true);
    }
  };
  const onOtherDetailsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherDetails(e.target.value);
    if (validateOtherDetails(e.target.value)) {
      setOtherDetailsValid(true);
    }
  };

  const handleSave = () => {
    if (validate()) {
      onSave({
        saved: true,
        type,
        location,
        label,
        documentType: documentType || type,
        firmName,
        suburb,
        bankName,
        branch,
        otherDetails,
      });
      closeAccordion();
    }
  };

  const handleCancelDelete = () => {
    onSave({ saved: false, type: docTypeData.type });
    closeAccordion();
  };

  const isOther = () => {
    return type === DocumentType.Other.value;
  };

  return (
    <Box>
      <Box>
        <RequiredField />
      </Box>
      {isOther() && (
        <>
          <Box margin={{ bottom: '20px' }}>
            <TextBold>Type of document? *</TextBold>
            <TextInput
              id={documentType}
              value={documentType}
              onChange={onDocumentTypeChange}
              required
              hasError={!documentTypeValid}
            />
            {!documentTypeValid && <FieldRequired />}
          </Box>
        </>
      )}
      <Box margin={{ bottom: '20px' }}>
        <TextBold>Where is your document located? *</TextBold>
      </Box>
      <Box margin={{ vertical: '5px' }}>
        <RadioButton
          id="location-0"
          name="location"
          label={Location.Solicitor.label}
          value={Location.Solicitor.value}
          checked={location === Location.Solicitor.value}
          onChange={(e) => onLocationChange(e, Location.Solicitor.label)}
          hasError={!locationValid}
        />
        {location !== Location.Solicitor.value ? null : (
          <Box pad={{ left: '60px' }} margin={{ top: '10px', bottom: '20px' }}>
            <Box>
              <TextBold>Name of the firm *</TextBold>
            </Box>
            <Box>
              <TextInput
                id={firmName}
                value={firmName}
                onChange={onFirmNameChange}
                required
                hasError={!firmNameValid}
              />
            </Box>
            {!firmNameValid && <FieldRequired />}
            <Box margin={{ top: '20px' }}>
              <TextBold>Suburb</TextBold>
            </Box>
            <Box>
              <TextInput id={suburb} value={suburb} onChange={(e) => setSuburb(e.target.value)} />
            </Box>
          </Box>
        )}
      </Box>
      <Box margin={{ vertical: '5px' }}>
        <RadioButton
          id="location-1"
          name="location"
          label={Location.Bank.label}
          value={Location.Bank.value}
          checked={location === Location.Bank.value}
          onChange={(e) => onLocationChange(e, Location.Bank.label)}
          hasError={!locationValid}
        />
        {location !== Location.Bank.value ? null : (
          <Box pad={{ left: '60px' }} margin={{ top: '10px', bottom: '20px' }}>
            <Box>
              <TextBold>Name of the bank *</TextBold>
            </Box>
            <Box>
              <TextInput
                id={bankName}
                value={bankName}
                onChange={onBankNameChange}
                required
                hasError={!bankNameValid}
              />
            </Box>
            {!bankNameValid && <FieldRequired />}
            <Box margin={{ top: '20px' }}>
              <TextBold>Branch</TextBold>
            </Box>
            <Box>
              <TextInput id={branch} value={branch} onChange={(e) => setBranch(e.target.value)} />
            </Box>
          </Box>
        )}
      </Box>
      <Box margin={{ vertical: '5px' }}>
        <RadioButton
          id="location-2"
          name="location"
          label={Location.TrueteeAndGuardian.label}
          value={Location.TrueteeAndGuardian.value}
          checked={location === Location.TrueteeAndGuardian.value}
          onChange={(e) => onLocationChange(e, Location.TrueteeAndGuardian.label)}
          hasError={!locationValid}
        />
      </Box>
      <Box margin={{ vertical: '5px' }}>
        <RadioButton
          id="location-3"
          name="location"
          label={Location.Home.label}
          value={Location.Home.value}
          checked={location === Location.Home.value}
          onChange={(e) => onLocationChange(e, Location.Home.label)}
          hasError={!locationValid}
        />
        {location !== Location.Home.value ? null : (
          <Box pad={{ left: '60px' }} margin={{ vertical: '15px' }}>
            <WarningCard
              text={
                <Text>
                  Keeping your important documents at home risks of being lost, damaged or stolen. Many people choose to
                  store their document with their solicitor. There are also safe-storage services, including that
                  offered by&nbsp;
                  <Anchor
                    href="https://www.tag.nsw.gov.au/wills/make-will/will-and-document-storage"
                    target="_blank"
                    label="NSW Trustee & Guardian"
                  />
                </Text>
              }
            />
          </Box>
        )}
      </Box>
      <Box margin={{ vertical: '5px' }}>
        <RadioButton
          id="location-4"
          name="location"
          label={Location.Other.label}
          value={Location.Other.value}
          checked={location === Location.Other.value}
          onChange={(e) => onLocationChange(e, Location.Other.label)}
          hasError={!locationValid}
        />
        {location !== Location.Other.value ? null : (
          <Box pad={{ left: '60px' }} margin={{ top: '10px', bottom: '20px' }}>
            <Box>
              <TextBold>Please provide details *</TextBold>
            </Box>
            <Box>
              <Text>
                Storing your important documents at other people&apos;s home risks being lost, damaged or stolen.
              </Text>
            </Box>
            <Box>
              <TextArea
                id={otherDetails}
                value={otherDetails}
                onChange={onOtherDetailsChange}
                required
                hasError={!otherDetailsValid}
              />
            </Box>
            {!otherDetailsValid && <FieldRequired />}
          </Box>
        )}
      </Box>
      {!locationValid && <OptionRequired />}
      <Box margin={{ top: '20px' }} direction="row" align="center" justify="between">
        <Button theme="primary" type="button" className="primary-button-dcs" onClick={handleSave}>
          Save
        </Button>
        <Button theme="secondary" type="button" className="button-dcs" onClick={handleCancelDelete}>
          {!docTypeData?.saved ? 'Cancel' : 'Delete'}
        </Button>
      </Box>
    </Box>
  );
};

export default ReusablePanel;
