import { Box, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';

const UnderlineText = styled(Text)`
  text-decoration: underline;
  color: rgba(0, 38, 100, 1);
`;

export const AddIcon: React.ElementType = () => {
  return (
    <Box>
      <UnderlineText>Add</UnderlineText>
    </Box>
  );
};
