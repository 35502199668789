import * as React from 'react';
import { Heading } from 'grommet';

type Props = {
  title: string;
};

const HeadingThree: React.FunctionComponent<Props> = ({ title }: Props) => {
  return (
    <Heading level="3" size="medium" margin={{ vertical: 'medium' }}>
      {title}
    </Heading>
  );
};

export default HeadingThree;
