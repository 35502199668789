import React from 'react';
import PropTypes from 'prop-types';
import { DimmerContainer } from './Dimmer.styled';

const Dimmer = ({ className, active, fullPage, children }) => active ? (
    <DimmerContainer className={ className } fullPage={ fullPage }>
        {children}
    </DimmerContainer>
) : <></>;

Dimmer.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    fullPage: PropTypes.bool,
};

Dimmer.defaultProps = {
    active: false,
    fullPage: false
};

export default Dimmer;
