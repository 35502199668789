import * as React from 'react';
import { Button } from '@snsw/react-component-library';
import { useHistory } from 'react-router';

type Props = {
  goTo: string;
};

const EditButtonComponent: React.FunctionComponent<Props> = ({ goTo }: Props) => {
  const history = useHistory();

  const handlOnClick = () => {
    history.push(goTo);
  };

  return (
    <Button theme="secondary" type="button" onClick={handlOnClick} className="button-dcs">
      Edit
    </Button>
  );
};

export default EditButtonComponent;
