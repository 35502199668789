export const questionsLabels: WillPlanningForm.QuestionLabel[] = [
  {
    id: '6d8dc300-474c-41fd-8fc0-78755d984720',
    label: 'I have a previous spouse/s and or children from an earlier relationship',
  },
  {
    id: '1a32c81a-dcb1-4349-8b3c-890e43d26369',
    label: 'I have minor aged children (less than 18 years)',
  },
  {
    id: '57ea28e2-0cc1-4273-b8b8-46a19fdc424d',
    label: 'I have overseas or interstate assets',
  },
  {
    id: '66f4e955-b58a-472f-bcbb-dff2ea7e446d',
    label: 'I have a private company or business',
  },
  {
    id: 'bc63baf2-e492-4b78-9736-9cafd9a5a92f',
    label: 'I have a self-managed super fund (SMSF)',
  },
  {
    id: '81754b43-559b-41b0-aff2-cb30ca3442db',
    label: 'I have loans owing to a beneficiary',
  },
  {
    id: 'c349a1c8-b4eb-4b06-979f-b6c30c0c6919',
    label: 'I have an interest in a trust',
  },
];

export const answers = {
  YES: 'Yes',
  NO: 'No',
  NO_ANSWER: 'Prefer not to say',
};

const DEFAULT_ANSWER = answers.NO_ANSWER;

export const getAnswerByQuestionId = (questions: WillPlanningForm.Question[], questionId: string): string => {
  return questions.find((question) => question.id === questionId)?.answer || DEFAULT_ANSWER;
};
