// eslint-disable-next-line react/prop-types
import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { color, layout } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

const TickSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 24 24' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' />
    </svg>
);

const Tick = styled(TickSvg)`
  ${color}
  ${layout}
`;

Tick.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 24,
    width: 24,
};

Tick.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default Tick;
