import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardContainer } from './Card.styled';
import { H6 } from '../../Headers/HeaderTag.styled';
import ComponentLoader from '../../Loader/ComponentLoader';
import { trackMe } from '../../Components/ComponentTracker/componentTracker';

const Card = ({ title, height, vertical, loading, children, ...rest }) => {
    useEffect(() => {
        trackMe('Card');
    }, []);

    return (
        <CardContainer height={ height } vertical={ vertical } { ...rest }>
            <ComponentLoader active={ loading }/>
            {title && <H6>{title}</H6>}
            {children}
        </CardContainer>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    height: PropTypes.string,
    vertical: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType(
        [
            PropTypes.node,
            PropTypes.array,
        ],
    ),
};

Card.defaultProps = {
    vertical: false,
    height: '14rem',
    loading: false,
};

export default Card;
