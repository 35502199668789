import styled from 'styled-components/macro';

export const StyledSlidingModal = styled.div`
   position: relative;
   min-height: 4rem;
   z-index: 2001;
   background-color: white;
   display: flex;
   flex-direction: column;
   width: 100%;
   max-width: 100%;
   height: 100%;
   justify-content: flex-start;
`;

StyledSlidingModal.DismissButton = styled.button`
    position: absolute;
    top: 0;
    left: -2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: #2e5299;
    border: none;
`;
