const bookingLocations = {
  branches: [
    'Bathurst Branch',
    'Broken Hill Branch',
    'Lismore Branch',
    'Newcastle Branch',
    'Parramatta Branch',
    'Port Macquarie Branch',
    'Sydney CBD Branch',
    'Wagga Wagga Branch',
    'Wollongong Branch',
  ],
  popup: [
    'Albury',
    'Armidale',
    'Ashfield',
    'Ballina',
    'Bankstown',
    'Batemans Bay',
    'Blacktown',
    'Burwood',
    'Campbelltown',
    'Castle Hill',
    'Cessnock',
    'Chatswood',
    'Cobar',
    'Coffs Harbour',
    'Dee Why',
    'Dubbo',
    'Erina',
    'Erskineville',
    'Forster',
    'Gosford',
    'Goulburn',
    'Gregory Hills',
    'Griffith',
    'Gymea',
    'Hurstville',
    'Katoomba',
    'Kempsey',
    'Kingsford',
    'Liverpool',
    'Macksville',
    'Maitland',
    'Maroubra',
    'Menai',
    'Milton',
    'Miranda',
    'Moss Vale',
    'Nowra',
    'Orange',
    'Penrith',
    'Queanbeyan',
    'Raymond Terrace',
    'Salamander Bay',
    'Shellharbour',
    'Swansea',
    'Tamworth',
    'Taree',
    'Tweed Heads',
    'Warilla',
    'Wentworth',
    'Woy Woy',
  ],
};

interface BookingLocationOption {
  text: string;
  value: string;
}

export const bookingLocationOptions = (
  locationType: 'branches' | 'popup',
  valueSuffix: string,
): BookingLocationOption[] => {
  // eslint-disable-next-line security/detect-object-injection
  return bookingLocations[locationType].map((bookingLocation) => ({
    text: bookingLocation,
    value: `${bookingLocation}${valueSuffix}`,
  }));
};
