import * as React from 'react';
import { Grommet } from 'grommet';
import { theme } from '@snsw/react-component-library';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { UserProvider } from 'user/context/UserContext';
import { PlanningFormProvider } from 'planning/context/PlanningFormContext';
import AuthCallback from 'user/components/AuthCallback';
import PlanningFlow from 'app/PlanningFlow';
import withAuth from 'user/components/WithAuth';
import customTheme from './theme/Global';
import ScrollToTop from './common/ScrollToTop';
import { routes } from './common/routes';

const App: React.FunctionComponent = () => (
  <BrowserRouter>
    <Grommet theme={customTheme}>
      <ThemeProvider theme={theme}>
        <PlanningFormProvider>
          {' '}
          {/* TODO this should be wrapped lower down? */}
          <UserProvider>
            <ScrollToTop />
            <Switch>
              <Route path={routes.AUTH_CALLBACK}>
                <AuthCallback />
              </Route>
              <Route path={routes.PLANNER_BASE_PATH} component={withAuth(PlanningFlow)} />
              <Redirect to={routes.HOME} />
            </Switch>
          </UserProvider>
        </PlanningFormProvider>
      </ThemeProvider>
    </Grommet>
  </BrowserRouter>
);

export default App;
