import { Box, Text, ResponsiveContext } from 'grommet';
import * as React from 'react';

type LabelAndValueProps = {
  label: string;
  value: string;
};

const LabelAndValue: React.FunctionComponent<LabelAndValueProps> = ({ label, value }: LabelAndValueProps) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box className="labelWithValue" direction={size === 'small' ? 'column' : 'row'} align="start" justify="start">
          <Box width="400px" pad={{ right: 'xsmall' }}>
            <Text>{label}</Text>
          </Box>
          <Box basis="1/3">
            <Text weight="bold">{value}</Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default LabelAndValue;
