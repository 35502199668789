import * as toolTips from '../schemas/tooltips.json';

const radioWidgetUiOption = {
  'ui:widget': 'radio',
};

const selectBoxWidgetUiOption = {
  'ui:widget': 'select',
};

export const uiSchema = {
  bankAccountsOwnership: radioWidgetUiOption,
  isPropertyMortgaged: {
    ...radioWidgetUiOption,
  },
  realEstateType: {
    'ui:widget': 'select',
  },
  bankAccountType: selectBoxWidgetUiOption,
  realEstateOwnership: {
    'ui:tooltip': {
      Jointly: toolTips['Jointly'],
      'As tenants in common': toolTips['As tenants in common'],
    },
    ...radioWidgetUiOption,
  },
  fundType: {
    ...radioWidgetUiOption,
  },
  hasNominatedBeneficiary: {
    'ui:tooltip': toolTips['hasNominatedBeneficiary'],
    ...radioWidgetUiOption,
  },
  certificateOfTitleLocation: {
    'ui:tooltip': toolTips['certificateOfTitleLocation'],
  },
  isNominationBinding: {
    ...radioWidgetUiOption,
  },
  isNominationNonLapsing: {
    ...radioWidgetUiOption,
  },
  structure: selectBoxWidgetUiOption,
  typeOfItem: selectBoxWidgetUiOption,
  investmentDetails: {
    'ui:tooltip': toolTips['investmentDetails'],
  },
};
