import React from 'react';
import PropTypes from 'prop-types';
import HeadingStyle from './Heading.styled';

const Heading = ({
    level,
    className,
    children,
}) => {
    const headingLevel = `h${level}`;

    return (
        <HeadingStyle className={ className } as={ headingLevel }>
            { children }
        </HeadingStyle>
    );
};

Heading.defaultProps = {
    level: 1,
};

Heading.propTypes = {
    className: PropTypes.string,
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    ]),
    children: PropTypes.string
};

export default Heading;
