import React from 'react';
import { questionsLabels, getAnswerByQuestionId } from 'planning/utils/questions';
import { Text as ReactPDFText, View } from '@react-pdf/renderer';
import styles from './stylesheet';

const questionsList = (personalQuestionsAnswers: WillPlanningForm.Question[]) => {
  if (!personalQuestionsAnswers) {
    return null;
  }

  return questionsLabels.map(({ id: questionId, label }) => {
    const answer = getAnswerByQuestionId(personalQuestionsAnswers, questionId);
    return (
      <View style={styles.question} key={questionId}>
        <View style={styles.dataRow}>
          <View style={styles.dataColumn}>
            <ReactPDFText>{label}</ReactPDFText>
          </View>
          <View style={styles.dataColumn}>
            <ReactPDFText style={styles.dataValue}>{answer}</ReactPDFText>
          </View>
        </View>
      </View>
    );
  });
};

const QuestionsSection: React.FunctionComponent<{
  personalQuestionsAnswers: WillPlanningForm.Question[];
}> = ({ personalQuestionsAnswers }) => {
  return (
    <View style={styles.section}>
      <View>
        <ReactPDFText style={styles.title}>Additional questions to help with your will planning process</ReactPDFText>
      </View>
      {questionsList(personalQuestionsAnswers)}
    </View>
  );
};

export default QuestionsSection;
