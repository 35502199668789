import styled, { css } from 'styled-components/macro';
import { FormContainer, FormLabel, HiddenRadioCheckbox, CustomRadioCheckbox } from './Form.styled';
import tokens from '../../Tokens/tokens';
import { getSpacing } from '../../utils';
import Field from './Field.styled';

const { colors } = tokens;

const Checkbox = styled.div`
    ${FormContainer}
`;

Checkbox.Item = styled.div.attrs(props => ({
    className: 'checkbox-item' // needed when referencing this in another component for spacing
}))`
    position: relative;
    margin-top: ${getSpacing(['sm'])};

    + ${Field.Error}{
        margin-top: ${getSpacing(['md'])};
    }
`;

Checkbox.Input = styled.input`
    ${HiddenRadioCheckbox}

    &:checked + ${Checkbox.Label}::before {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='19' height='15' viewBox='0 0 19 15' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M18.6,3.4 C18.8,3.2 18.8,2.9 18.6,2.7 L16.3,0.4 C16.1,0.2 15.8,0.2 15.6,0.4 L7,9 L3.4,5.4 C3.2,5.2 2.9,5.2 2.7,5.4 L0.4,7.7 C0.2,7.9 0.2,8.2 0.4,8.4 L6.7,14.7 C6.9,14.9 7.2,14.9 7.4,14.7 L18.6,3.4 Z' fill='%23646974'/%3E %3C/svg%3E") no-repeat center;
        background-color: ${colors.forms.background};
    }

    &:focus ~ ${Checkbox.Label}::before {
        outline: 2px solid ${colors.borders.focus};
        outline-offset: 2px;
    }

    &:disabled + ${Checkbox.Label}::before {
        opacity: 1;
        background-color: ${colors.backgrounds.disabled};
    }
`;

Checkbox.Label = styled(FormLabel)`
  ${CustomRadioCheckbox}

  ${props => props.hasError && css`
        &::before {
            border-color: ${colors.borders.error};
            box-shadow: none;
        }
    `}
`;

export default Checkbox;
