import * as React from 'react';
import { Box, Image, Text } from 'grommet';
import ErrorIcon from '../../../common/assets/error.svg';

const FieldErrorMessage: React.FunctionComponent = () => (
  <Box margin={{ top: 'xxsmall' }}>
    <Box direction="row" align="center" gap="small">
      <Box>
        <Image src={ErrorIcon} />
      </Box>
      <Box>
        <Text color="#b81237">Please select an option</Text>
      </Box>
    </Box>
  </Box>
);

export default FieldErrorMessage;
