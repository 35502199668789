import styled from 'styled-components/macro';
import { getBreakpoint, getSpacing, pxToRem } from '../../utils';
import Tokens from '../../Tokens/tokens';
import Icons from '../../Icons';

const { colors } = Tokens;

export const BreadcrumbContainer = styled.nav`
    display: flex;
    align-items: center;
    a {
        font-size: ${pxToRem(14)};
        text-decoration: underline;
        display: inline-block;
        color: ${colors.breadcrumb.link};
    }
    ${Icons.ChevronLeft} {
        height: ${pxToRem(8)};
        width: auto;
        margin-right: ${getSpacing([6])};
        margin-left: ${getSpacing([6])};
        path {
            fill: ${colors.breadcrumb.link};
        }
        ${getBreakpoint.sm`
            display: none;
        `}
    }
`;

export const BreadcrumbList = styled.ol`
    margin: 0;
    padding-left: 0;
    list-style: none;
    display: inline;
`;

export const BreadcrumbListItem = styled.li`
    display: none;
    padding-left: 0;
    &:nth-last-child(2) {
        display: inline;
    }
    ${getBreakpoint.sm`
        display: inline;
    `}
    ${getBreakpoint.sm`
        &:last-child {
            a {
                text-decoration: none;
            }
        }
    `}
    ${Icons.ChevronRight} {
        display: none;
        ${getBreakpoint.sm`
            display: inline;
            height: ${pxToRem(8)};
            width: auto;
            margin-right: ${getSpacing([6])};
            margin-left: ${getSpacing([6])};
            path {
                fill: ${colors.breadcrumb.link};
            }
        `}
    }
`;
