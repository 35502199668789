import { Box, Heading, Image, Text } from 'grommet';
import * as React from 'react';
import HighlightCard from 'app/common/HighlightCard';
import HeadingThree from 'app/common/HeadingThree';
import { useHistory } from 'react-router';
import { routes } from 'app/common/routes';
import BookingContext from '../context/BookingContext';
import SuccessImage from './assets/success.svg';

const SuccessIcon = <Image src={SuccessImage} />;

const BookingSuccess: React.FunctionComponent = () => {
  const { booking } = React.useContext(BookingContext);
  const history = useHistory();

  React.useEffect(() => {
    if (!booking.meetingType) {
      history.replace(routes.HOME);
    }
  }, []);

  return (
    <Box>
      <Box>
        <HighlightCard
          title="Appointment request complete"
          text="A NSW Trustee &amp; Guardian specialist will contact you soon to confirm your appointment and advise next steps."
          borderColor="#00A908"
          backgroundColor="#E5F6E6"
          icon={SuccessIcon}
        />
      </Box>
      <Box>
        <HeadingThree title="Your appointment request summary" />
      </Box>
      <Box margin={{ bottom: 'xlarge' }}>
        <Box>
          {booking?.meetingType === 'in person' ? (
            <Box>
              <Box direction="row" align="center" gap="xxsmall">
                <Box>
                  <Text>In person appointment at:</Text>
                </Box>
                <Box>
                  <Heading level="4" margin="0">
                    {booking?.location.substr(
                      0,
                      booking?.location.indexOf('(') === -1 ? booking?.location.length : booking?.location.indexOf('('),
                    )}
                  </Heading>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Heading level="4" margin="0">
                Online video conference
              </Heading>
            </Box>
          )}
          <Box direction="row" align="center" gap="xxsmall">
            <Box>
              <Text>Time slot:</Text>
            </Box>
            <Box>
              <Heading level="4" margin="0">
                {booking?.preferredTimeOfDay}
              </Heading>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingSuccess;
