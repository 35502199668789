import * as React from 'react';
const { createContext, useState } = React;

const BookingContext = createContext(null);

export const BookingProvider: React.FunctionComponent = ({ children }) => {
  const [booking, setBooking] = useState({});

  return (
    <BookingContext.Provider
      value={{
        booking,
        setBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingContext;
