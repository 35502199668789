import * as React from 'react';
import { Box, Image, Text, Anchor } from 'grommet';
import Logout from 'app/common/Logout';
import logo from './assets/logo.svg';

const Header: React.FunctionComponent = () => {
  return (
    <>
      <Box border={{ side: 'bottom' }} fill>
        <Box
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: '5%' }}
          background="brand"
          height="40px"
        >
          <Box>
            <Text size="xsmall" style={{ color: 'FFFFFF' }}>
              A NSW Government website
            </Text>
          </Box>
          <Box>
            <Logout />
          </Box>
        </Box>
        <Box justify="center" height="95px" pad={{ horizontal: '4.5%' }}>
          <Box width="70px" height="56px">
            <Anchor href="https://www.nsw.gov.au/">
              <Image src={logo} />
            </Anchor>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
