import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const HomeSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='M36 40H26c-.6 0-1-.4-1-1v-7h-6v7c0 .6-.4 1-1 1H8c-.6 0-1-.4-1-1V23c0-.3.1-.6.3-.7l14-12.7c.4-.3 1-.3 1.3 0l14 12.7c.2.2.3.5.3.7v16c.1.6-.3 1-.9 1zm-9-2h8V23.4L22 11.7 9 23.4V38h8v-7c0-.6.4-1 1-1h8c.6 0 1 .4 1 1v7z'/>
        <circle cx='22' cy='22' r='4'/>
        <path d='M3 22.5c-.3 0-.5-.1-.7-.3-.4-.4-.3-1 .1-1.4L6 17.4V11c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v.8l9.3-8.6c.4-.4 1-.4 1.4 0l19 17.5c.4.4.4 1 .1 1.4-.4.4-1 .4-1.4.1L22 5.4l-10.3 9.5c-.3.3-.7.3-1.1.2-.4-.2-.6-.5-.6-.9V12H8v5.8c0 .3-.1.5-.3.7l-4 3.7c-.2.2-.5.3-.7.3z'/>
    </svg>
);

export const Home = styled(HomeSvg)`
  ${color}
  ${layout}
`;

Home.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Home;
