import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { H3 } from '../../Headers/HeaderTag.styled';
import { CardsContainer } from './CardsGrid.styled';
import { trackMe } from '../../Components/ComponentTracker/componentTracker';

const CardsGrid = ({ title, vertical, children, ...otherProps }) => {
    useEffect(() => {
        trackMe('CardsGrid');
    }, []);

    return (
        <div { ...otherProps }>
            {React.isValidElement(title) ?
                title :
                (<H3 mb={ 0.5 }>{title}</H3>)}
            <CardsContainer vertical={ vertical }>
                {children}
            </CardsContainer>
        </div>
    );
};

CardsGrid.defaultProps = {
    vertical: false
};

CardsGrid.propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.oneOfType(
        [
            PropTypes.node,
            PropTypes.array,
        ]
    ).isRequired,
    vertical: PropTypes.bool,
};

export default CardsGrid;
