import { getCustomerDetails } from './MyAccountService';

type CustomerDetails = MyAccount.CustomerDetails;
type CustomerName = MyAccount.CustomerName;
type CustomerAddress = MyAccount.CustomerAddress;

const getNameIfDifferentFromEmail = (name: string, email: string) => {
  return name.toLowerCase() !== email ? name : '';
};

export const getFullName = (customerName: CustomerName, email: string): string => {
  const nameFields = [
    customerName.salutation,
    getNameIfDifferentFromEmail(customerName.firstName, email),
    customerName.middleName,
    getNameIfDifferentFromEmail(customerName.lastName, email),
  ];

  return nameFields.filter((field) => field).join(' ');
};

export const getFullAddress = (customerAddress: Partial<CustomerAddress>): string => {
  const addressFields = [
    customerAddress.street && `${customerAddress.street},`,
    customerAddress.city,
    customerAddress.state,
    customerAddress.postalCode,
    customerAddress.country,
  ];

  return addressFields.filter((field) => field).join(' ');
};

const myAccountCustomerToUserAdapter = (customer: CustomerDetails, myAccountId: string): User => {
  return {
    myAccountId,
    email: customer.contactDetails.emailAddress,
    title: customer.customerName.salutation,
    fullName: getFullName(customer.customerName, customer.contactDetails.emailAddress),
    givenName: getNameIfDifferentFromEmail(customer.customerName.firstName, customer.contactDetails.emailAddress),
    middleName: customer.customerName.middleName,
    familyName: getNameIfDifferentFromEmail(customer.customerName.lastName, customer.contactDetails.emailAddress),
    phoneNumber: customer.contactDetails.mobilePhone,
    address: customer.residentialAddress ? getFullAddress(customer.residentialAddress) : '',
  };
};

const fetchUserDataFromMyAccount = async (myAccountId): Promise<User> => {
  if (process.env.USER_TOKEN) {
    const fakeUser = {
      myAccountId: '13bf1201-01f011eb-ad40c137-ad13606c',
      title: 'Ms.',
      email: 'stephanie.mccarty@service.nsw.gov.au',
      givenName: 'Stephanie',
      middleName: 'Olivia',
      familyName: 'McCarty',
      fullName: 'Ms. Stephanie Olivia McCarty',
      phoneNumber: '0436357365',
      address: '2-24 Rawson Pl, Haymarket NSW 2000',
    };
    console.log('User is simulated', fakeUser);

    return Promise.resolve(fakeUser);
  }

  try {
    const myAccountCustomer = await getCustomerDetails();
    const user = myAccountCustomerToUserAdapter(myAccountCustomer, myAccountId);

    return user;
  } catch (error) {
    const emptyUser = {
      myAccountId,
      email: '',
      title: '',
      fullName: '',
      givenName: '',
      middleName: '',
      familyName: '',
      phoneNumber: '',
      address: '',
    };

    return Promise.resolve(emptyUser);
  }
};

export const getUserFromMyAccount = async (myAccountId: string): Promise<User> => {
  const userData = await fetchUserDataFromMyAccount(myAccountId);

  return Promise.resolve({
    myAccountId,
    ...userData,
  });
};
