import * as React from 'react';
import { Route, useLocation } from 'react-router';
import UserContext from 'user/context/UserContext';
import { isLoggedIn, parseIdToken, getIdTokenInLocalStorage } from 'user/components/AuthService';
import UserDetailsService from 'app/features/user-details/UserDetailsService';
import PlanningService from 'planning/services/PlanningService';
import PlanningFormContext from 'app/features/planning/context/PlanningFormContext';
import TagManager from 'react-gtm-module';
import RedirectToMyAccount from 'app/features/planning/components/RedirectToMyAccount';
import { getUserFromMyAccount } from './UserService';

const LOADING = 'Loading';
const AUTHENTICATED = 'Authenticated';
const NOT_AUTHENTICATED = 'Not Authenticated';

const loadUser = async (parsedIdToken: MyAccount.ParsedIdToken): Promise<User> => {
  if (!parsedIdToken) {
    return Promise.reject(null);
  }

  const user = await getUserFromMyAccount(parsedIdToken.myAccountId);
  if (!isLoggedIn(user, parsedIdToken.jwtExpireTimestamp)) {
    return Promise.reject(null);
  }

  return user;
};

const sendWillToGTM = (willId: string) => {
  const tagManagerArgs = {
    dataLayer: {
      applicationId: willId,
      applicationType: 'Life Administrator - End of Life planner',
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

const loadUserAndWill = async (
  parsedIdToken: MyAccount.ParsedIdToken,
  setUserInContext,
  setAssetsInContext,
  setPersonalQuestionsAnswersInContext,
  setAuthenticationStatus,
  setTaskInfoInContext,
) => {
  let user;
  try {
    user = await loadUser(parsedIdToken);
    setAuthenticationStatus(AUTHENTICATED);
    setUserInContext(user);
  } catch (error) {
    setAuthenticationStatus(NOT_AUTHENTICATED);
  }

  // TODO this whole init of customer data logic should be in it's own module..

  try {
    await UserDetailsService.getUserDetails(user);
  } catch (err) {
    console.warn(`no user details found! creating..`);
    await UserDetailsService.createUserDetails(user);
  }

  try {
    // TODO loading will plan data should happen upon drilldown into will plan tile instead..
    const will = await PlanningService.getWill(user);
    setAssetsInContext(will.assets);
    setPersonalQuestionsAnswersInContext(will.questions);
    setTaskInfoInContext(will.taskInfo);
    sendWillToGTM(will.id);
  } catch (error) {
    console.warn(`no will found! creating..`);
    const will = await PlanningService.createWill(user);
    sendWillToGTM(will.id);
  }
};

const withAuth: (Component) => React.FunctionComponent<unknown> = (Component) => {
  const WithAuth: React.FunctionComponent = () => {
    const location = useLocation();
    const { user, setUser } = React.useContext(UserContext);
    const { setAssets, setPersonalQuestionsAnswers, setTaskInfo } = React.useContext(PlanningFormContext);
    const [status, setStatus] = React.useState(LOADING);

    React.useEffect(() => {
      const idToken = process.env.USER_TOKEN || getIdTokenInLocalStorage();
      const parsedIdToken = parseIdToken(idToken);

      loadUserAndWill(parsedIdToken, setUser, setAssets, setPersonalQuestionsAnswers, setStatus, setTaskInfo);
    }, [location]);

    return (
      <Route
        render={(props) => {
          switch (status) {
            case AUTHENTICATED:
              return user && <Component {...props} />;
            case NOT_AUTHENTICATED:
              return <RedirectToMyAccount />;
            case LOADING:
            default:
              return <div>Loading...</div>;
          }
        }}
      />
    );
  };
  return WithAuth;
};

export default withAuth;
