import styled from 'styled-components/macro';
import { space } from 'styled-system';
import theme from './../GlobalStyle/theme/theme';
import colors from './../GlobalStyle/colors/colors';

export const ProgressBarStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const { progressBar } = theme.componentWidths.screener;
const maximumWidth = props => `max-width: ${props.maxWidth ? 'unset' : (progressBar ? progressBar : '54rem')}`;

ProgressBarStyle.FullBar = styled.div`
  ${maximumWidth};
  width: 100%;
  height: 3px;
  background: #d1d1d1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin: ${props => props.margin || '1rem 0 2.5rem 0'};

  @media (max-width: 480px) {
    margin-bottom: 0.5rem;
  }
`;

ProgressBarStyle.Progress = styled.div`
  width: ${props => props.percentage}%;
  height: 7px;
  background: ${colors.darkBlue};
  border-radius: 2px;
`;

// todo: check if this is used or not. it does not look like it is being used. 20/5/2020
export const ProgressTextContainer = styled.div`
  ${space};
  ${maximumWidth};
  width: 100%;
  margin: auto;
  color: ${colors.darkGrey};
  font-weight: 300;
`;
