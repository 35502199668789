import * as React from 'react';
import { AddButtonProps } from '@rjsf/core';
import { Button, Icons } from '@snsw/react-component-library';

const AddButton: React.FC<AddButtonProps> = (props) => (
  <Button {...props} color="secondary">
    <Icons.Add /> Add Item
  </Button>
);

export default AddButton;
