import { ThemeContext, RadioButton as RadioButtonGrommet } from 'grommet';
import * as React from 'react';
import { EventHandler } from 'react';

type Props = {
  id: string;
  name?: string;
  label: string | React.ReactNode;
  value: string | ReadonlyArray<string> | number;
  onChange: EventHandler<any>;
  checked: boolean;
  hasError: boolean;
};

const RadioButton: React.FunctionComponent<Props> = ({
  id,
  name,
  label,
  value,
  onChange,
  checked,
  hasError,
}: Props) => {
  const radioColor = hasError
    ? {
        dark: '#b81237',
        light: '#b81237',
      }
    : {
        dark: 'rgba(255, 255, 255, 0.5)',
        light: 'rgba(0, 0, 0, 0.15)',
      };

  return (
    <ThemeContext.Extend
      value={{
        radioButton: {
          border: {
            color: radioColor,
          },
        },
      }}
    >
      <RadioButtonGrommet label={label} onChange={onChange} id={id} name={name} checked={checked} value={value} />
    </ThemeContext.Extend>
  );
};

export default RadioButton;
