import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

const CancelSvg = ({ className, color, width, height }) => {
    return (
        <svg
            className={ className }
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill={ colors.legacy[color] || color }
            width={ width }
            height={ height }
            focusable='false'
            aria-hidden='true'
        >
            <path d='M0 0h24v24H0z' fill='none'/>
            <path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'/>
        </svg>
    );
};

const Cancel = styled(CancelSvg)`
  ${color}
  ${layout}
`;

Cancel.defaultProps = {
    color: `${colors.brand.snswPrimaryRed}`,
    width: 24,
    height: 24,
};

export default Cancel;
