import axios from 'axios';
import { getIdTokenInLocalStorage } from '../../features/user/components/AuthService';
import tasks from './tasks';

export const client = axios.create({
  baseURL: process.env.EOL_API_URL,
});

export const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${process.env.USER_TOKEN || getIdTokenInLocalStorage()}`,
      'X-API-Key': process.env.X_API_KEY,
    },
  };
};

export const updateAcceptTerms = (user: User, task: string): Promise<unknown> => {
  return client.patch(`/task/${user.myAccountId}/${task}/userDetails/acceptTerms`, null, getHeaders());
};

export const getTaskItem = async (user: User, taskType: string): Promise<any> => {
  // eslint-disable-next-line security/detect-object-injection
  const path = tasks[taskType]?.path || '';
  if (!path) {
    throw new Error(`Unrecognised task ${taskType}`);
  }

  const { data } = await client.get(`/${path}/${user.myAccountId}`, getHeaders());
  return data;
};

export const updateTaskItem = async (user: User, task: any, taskType: string): Promise<any> => {
  // eslint-disable-next-line security/detect-object-injection
  const path = tasks[taskType]?.path || '';
  if (!path) {
    throw new Error(`Unrecognised task ${taskType}`);
  }

  return client.patch(`/${path}/${user.myAccountId}`, task, getHeaders());
};

export default {
  client,
  getHeaders,
  getTaskItem,
  updateTaskItem,
};
