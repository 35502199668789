import styled, { css } from 'styled-components/macro';
import { FormLabel, HiddenRadioCheckbox, CustomRadioCheckbox, FormLegend, FormFieldset } from './Form.styled';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../../utils';
import Field from './Field.styled';

const { colors, font } = tokens;

export const RadioItemEditorContainer = styled.div`
    margin-top: ${pxToRem(8)};
    margin-bottom: -${pxToRem(8)};
    margin-left: ${pxToRem(11)};
    border-left: 8px solid #d8d8d8;
    padding: ${getSpacing([6, 'sm', 6, 'sm'])};

    .form__item label:before {
        display: none;
    }

    .form__item label {
        display: block;
        width: 100%;
        padding: ${getSpacing(['none', 'none', 12, 'none'])};
        max-width: ${pxToRem(769)};
    }

    .form__item input, select, textarea {
        opacity: 1;
        position: relative;
        width: 100%;
        max-width: ${pxToRem(372)};
        font-size: ${pxToRem(font.size.base)};
        color: ${colors.forms.inputText};
        background-color: ${colors.forms.background};
        border: 2px solid ${colors.forms.borderColor};
        border-radius: 6px;
        padding: ${getSpacing(['none', 13])};
        -webkit-transition: border-color .25s ease, background-color .25s ease;
        transition: border-color .25s ease, background-color .25s ease;
        height: ${pxToRem(48)};
        cursor: text;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        caret-color: ${colors.forms.labelText};
    }

    .form__item input.error, select.error, textarea.error {
        border-color: ${colors.borders.error};
        color: ${colors.forms.inputText};
        box-shadow: none
    }
`;

export const RadioGroupFieldset = styled(FormFieldset)`
    ${props => props.inline && RadioInlineStyles}

    ${props => props.inline && getBreakpoint.md`
        ${Field.Error} {
            margin-top: ${pxToRem(24)};
        }
    `}
`;

const Radio = styled.div`
    + ${Field.Error}{
        margin-top: ${getSpacing(['md'])};
    }
`;

Radio.Item = styled.div.attrs(props => ({
    className: 'radio-item' // needed when referencing this in another component for spacing
}))`
    position: relative;
    margin-top: ${getSpacing(['sm'])};
`;

Radio.Input = styled.input`
    ${HiddenRadioCheckbox}

    &:checked + ${Radio.Label}::before {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22px' height='22px' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'%3E %3Ccircle fill='%23646974' cx='11' cy='11' r='11'/%3E %3C/svg%3E") no-repeat center;
        background-color: ${colors.forms.background};
    }

    &:focus ~ ${Radio.Label}::before {
        outline: none;
    }

    &:focus ~ ${Radio.Label}::after {
        border: 2px solid ${colors.borders.focus};
    }
`;

Radio.Label = styled(FormLabel)`
    ${CustomRadioCheckbox}

    &::before {
        border-radius: 1rem;
    }

    &::after{
        border-radius: calc(1rem + 3px);
    }

    ${props => props.hasError && css`
        &::before {
            border-color: ${colors.borders.error};
            box-shadow: none;
        }
    `}
`;

export const RadioInlineStyles = css`
    ${Radio} {
        display: flex;
        padding-top: ${getSpacing('sm')};
    }

    ${Radio.Item} {
        margin-right: ${getSpacing('sm')};
        margin-bottom: 0;
        margin-top: 0;
    }

    .radio-item:first-of-type{
        margin-top: 0;
        margin-left: 0;
    }

    ${RadioItemEditorContainer} {
        margin-top: 0;
        margin-bottom: 0;
        padding: ${getSpacing([4, 'sm', 'none', 'sm'])};
    }

    ${getBreakpoint.md`
        ${FormLegend} {
            margin: 0;
        }

        ${Radio.Item} {
            margin: ${getSpacing(['none', 'none', 'none', 'md'])};
        }
    `}
`;

export default Radio;
