import { createGlobalStyle } from 'styled-components';
import tokens from '../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../utils';

const { colors, font } = tokens;

const GlobalStyle = createGlobalStyle`
    html {
        font-family: 'Gotham A','Gotham B', Helvetica, Arial, sans-serif;
        font-size: 100%;
        font-weight: ${font.weight.book};
        line-height: ${font.lineHeight.default};
        box-sizing: border-box;
    }

    input,
    select,
    textarea,
    button,
    option,
    optgroup,
    output,
    datalist {
        font-family: 'Gotham A','Gotham B', Helvetica, Arial, sans-serif;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    html,
    body {
        height: 100%;
    }

    body {
        color: ${colors.brand.snswDarkGrey};
        font-smoothing: antialiased;
    }

    a {
        color: ${colors.brand.snswSecondaryBlue};
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: ink;
        text-decoration-skip-ink: auto;
    }
    ol,
    ul {
        margin: ${getSpacing(['sm', 'none'])};
    }
    ${getBreakpoint.md`
        ol,
        ul {
            margin: ${getSpacing(['md', 'none'])};
            padding: ${getSpacing(['none', 'none', 'none', 30])}
        }
    `}
    ${getBreakpoint.print`
        ol,
        ul {
            margin-left: ${pxToRem(36)};
            padding: 0;
        }
    `}
    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin-top: ${pxToRem(12)};
        margin-bottom: 0;
    }
    li {
        margin: ${getSpacing(['none', 'none', 12])};
    }
    ol > li,
    ul > li {
        padding-left: ${pxToRem(6)};
        background: none;
    }
    ${getBreakpoint.print`
        ol > li,
        ul > li {
            padding: 0;
        }
    `}
    ol ol {
        list-style-type: lower-alpha;
    }
    ol ol ol {
        list-style-type: lower-roman;
    }
    ul ul {
        list-style-type: disc;
    }
    ul ul ul {
        list-style-type: disc;
    }
    dl {
        margin: ${getSpacing(['sm', 'none'])};
    }
    ${getBreakpoint.md`
        dl {
            margin: ${getSpacing(['md', 'none'])};
            padding: 0;
        }
    `}
    dl > div {
        margin: ${getSpacing(['none', 'none', 'sm'])};
    }
    ${getBreakpoint.md`
        dl > div {
            margin: ${getSpacing(['none', 'none', 'md'])};
        }
    `}
    ${getBreakpoint.xs`
        dl > div {
            display: flex;
            flex-wrap: wrap;
        }
    `}
    dl dt {
        margin: 0;
    }
    ${getBreakpoint.xs`
        dl dt {
            width: ${pxToRem(200)};
            padding-right: ${pxToRem(20)};
        }
    `}
    dl dd {
        margin: 0;
    }
    dl dd :first-child {
        margin-top: 0;
    }
    dl dd :last-child {
        margin-bottom: 0;
    }
    .dl--important {
        max-width: none;
    }
    .dl--important dt {
        font-weight: ${font.weight.medium};
    }
    ${getBreakpoint.xs`
        .dl--important dt {
            width: ${pxToRem(300)};
        }
    `}

    .mobile-only {
        display: none;
    }

    .computer-only {
        display: block;
    }

    @media (max-width: 767px) {
        .mobile-only {
            display: block;
        }

        .computer-only {
            display: none;
        }
    }

    .Width-100 {
        width: 100%;
    }

    .Align-center {
        text-align: center !important;
    }
`;

export default GlobalStyle;
