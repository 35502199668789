import * as React from 'react';
import { ThemeProps, utils } from '@rjsf/core';
import { Box, ResponsiveContext } from 'grommet';
import { Button } from '@snsw/react-component-library';
import Fields from '../Fields';
import FieldTemplate from '../FieldTemplate';
import ObjectFieldTemplate from '../ObjectFieldTemplate';
import Widgets from '../Widgets';

const { getDefaultRegistry } = utils;

const { fields, widgets } = getDefaultRegistry();

const DefaultChildren = ({ disabled, onDelete }) => {
  if (disabled) return null;
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box pad={{ bottom: 'medium' }}>
          <Box pad={{ top: 'medium' }}>
            <Box direction="row" align="center" justify="between">
              <Box>
                <Button
                  disabled={false}
                  external={false}
                  size="short"
                  theme="primary"
                  type="submit"
                  className="primary-button-dcs"
                >
                  Save
                </Button>
              </Box>
              {onDelete && (
                <Box>
                  <Button
                    disabled={false}
                    external={false}
                    size="short"
                    theme="secondary"
                    onClick={onDelete}
                    className="button-dcs"
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

const Form = (disabled: boolean, onDelete: () => void | null): ThemeProps => ({
  autoComplete: 'off',
  children: <DefaultChildren disabled={disabled} onDelete={onDelete} />,
  fields: { ...fields, ...Fields },
  FieldTemplate,
  ObjectFieldTemplate,
  widgets: { ...widgets, ...Widgets },
});

export default Form;
