import {
    FileContainer,
    FileContent,
    FileExtension,
    FileHeader,
    FileInfo,
    FileInput,
    FileName,
    FileRestrictions,
    RemoveFileButton
} from './FileUpload.styled';
import React, { useRef, useState } from 'react';

import BlueButton from '../../Buttons/BlueButton/BlueButton.styled';
import ComponentLoader from '../../Loader/ComponentLoader';
import Field from '../Form/Field.styled';
import Icons from '../../Icons';
import PropTypes from 'prop-types';
import fileUploadApi, { fileUploadApiBuilder } from './fileUploadApi';

const FileUpload = ({
    api,
    className,
    id,
    name,
    category,
    label,
    children,
    fileExtensions,
    maxMBSize,
    maxFiles,
    showFiles,
    onUploaded,
    onUploadError,
    onRemoved,
    onRemoveError,
    error,
    errorMessage,
    defaultFiles,
    removable,
}) => {
    const [validationError, setValidationError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState(defaultFiles);
    const fileInput = useRef(null);
    const hitFilesLimit = files.length >= maxFiles;

    const acceptedFiles = () => {
        let acceptedFileTypes = '';
        fileExtensions.forEach((fileExtension, index) => {
            const lastFile = index === fileExtensions.length - 1;
            acceptedFileTypes += '.' + fileExtension + (lastFile ? '' : ', ');
        });

        return acceptedFileTypes;
    };

    const acceptedFilesDescription = () => {
        if (fileExtensions.length === 0) { return 'any '; };
        let acceptedFilesDescription = '';
        fileExtensions.forEach((fileExtension, index) => {
            const lastFile = index === fileExtensions.length - 1;
            acceptedFilesDescription += (lastFile && fileExtensions.length > 1 ? ' or ' : '')
                + fileExtension.toUpperCase() + (lastFile ? '' : ', ');
        });
        return acceptedFilesDescription;
    };

    const uploadFiles = async (filesList) => {
        setLoading(true);
        let fileCount = files.length;
        let newFiles = [...files];
        await Promise.all(filesList.map(async file => {
            fileCount++;
            if (fileCount > maxFiles) {
                return;
            }

            let fileId = null;
            try {
                fileId = await api.uploadFile(file, name, category);
                // Minor quirk... double-quotes are accidentally returned by API. Strip off double-quotes.
                const newFile = { id: fileId.replace(/"/g, ''), name: file.name, category };
                newFiles = [...newFiles, newFile];
                onUploaded && onUploaded(newFile, newFiles);
            } catch (error) {
                onUploadError && onUploadError(file.name, error);
            }
        }));

        setLoading(false);
        setFiles(newFiles);
    };

    const onSelectFile = async () => {
        const selectedFile = fileInput.current.files[0];
        if (!selectedFile || !isFileValid(selectedFile)) {
            return;
        }

        await uploadFiles([...fileInput.current.files]);
    };

    const isFileValid = selectedFile => {
        // Is file larger than allowed MB size?
        const MAX_FILE_SIZE = maxMBSize * 1000000;
        if (selectedFile.size >= MAX_FILE_SIZE) {
            setValidationError(`File must not be larger than ${maxMBSize}MB`);
            return false;
        }

        // Is file already in the upload list?
        if (files.find(file => file.name === selectedFile.name)) {
            // No need to raise an error. Just silently ignore the file if it's already been added.
            return false;
        }

        setValidationError(null);
        return true;
    };

    const removeFile = async (file) => {
        setLoading(true);
        try {
            await api.removeFile(file.id);
            const newFiles = files.filter(f => f.id !== file.id);
            setFiles(newFiles);
            onRemoved && onRemoved(file, newFiles);
        } catch (error) {
            onRemoveError && onRemoveError(file.name, error);
        } finally {
            setLoading(false);
        }
    };

    const onRemoveFile = async file => {
        await removeFile(file);
    };

    const fileExtension = fileName => {
        const splitName = fileName.split('.');
        return splitName[splitName.length - 1];
    };

    const hasError = error || validationError;

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (files.length === maxFiles) {
            return;
        }

        const filesList = e.dataTransfer.files;
        if (filesList && filesList.length > 0) {
            // get files that are valid, i.e not over size limit, already uploaded
            const validFiles = [...filesList].filter(file => isFileValid(file));
            if (validFiles.length === 0) {
                return;
            }

            // get list of valid extensions
            const clonedExtensions = fileExtensions.slice();
            if (clonedExtensions.includes('jpg') && !clonedExtensions.includes('jpeg')) {
                clonedExtensions.push('jpeg');
            }

            // get files with valid ext as per above list
            const filesWithValidExt = validFiles.filter(file =>
                clonedExtensions.filter(ext => file.type.includes(ext)).length > 0);

            // check if all the files were valid
            if (filesWithValidExt.length !== validFiles.length) {
                setValidationError('Unsupported file extension');
                return;
            }

            uploadFiles(validFiles);
        }
    };

    return (
        <div className={ className } style={ { position: 'relative' } }>
            <ComponentLoader active={ loading } label='Please wait...' data-testid={ `${id}-loader` }/>

            <FileContainer data-testid={ `File-container${hasError ? '-error' : ''}` } error={ hasError }
                onDrop={ handleDrop }
                onDragOver={ handleDrag }
                onDragEnter={ handleDragIn }
                onDragLeave={ handleDragOut }
            >
                <FileHeader>
                    <h3>{label}</h3>
                    {children}
                </FileHeader>

                <hr/>

                <FileContent>
                    <FileInput>
                        <input
                            id={ `${id}-file-input` }
                            type='file'
                            accept={ acceptedFiles() }
                            ref={ fileInput }
                            onChange={ async () => await onSelectFile() }
                            data-testid={ `${id}-file-input` }
                            encType='multipart/form-data'
                            multiple
                        />

                        <BlueButton
                            as='label'
                            data-testid='upload-button'
                            htmlFor={ `${id}-file-input` }
                            disabled={ hitFilesLimit }
                        >
                            {hitFilesLimit ? `${maxFiles} files maximum` : files.length
                                ? 'Upload another file'
                                : 'Upload'}
                        </BlueButton>
                    </FileInput>
                    <FileRestrictions>
                        You can upload {acceptedFilesDescription()} files. Each file must not exceed {maxMBSize}MB.
                    </FileRestrictions>

                    {showFiles &&
                    files.map((file, index) => (
                        <FileInfo
                            key={ `${file.name}-${index}` }
                            data-testid={ `${id}-file` }
                        >
                            <FileExtension data-testid={ `${id}-file-extension` }>
                                {fileExtension(file.name)}
                            </FileExtension>
                            <FileName>
                                <strong data-testid={ `${id}-file-name` }>
                                    {file.name}
                                </strong>
                            </FileName>
                            {removable &&
                            <RemoveFileButton
                                data-testid={ `${id}-file-remove` }
                                onClick={ async () => await onRemoveFile(file) }
                                type='button'
                            >
                                <Icons.Delete/>
                                <span>Remove</span>
                            </RemoveFileButton>
                            }
                        </FileInfo>
                    ))
                    }
                </FileContent>
            </FileContainer>
            {error && <Field.Error>{errorMessage}</Field.Error>}
            {validationError && <Field.Error>{validationError}</Field.Error>}
        </div>
    );
};

FileUpload.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string,
    label: PropTypes.string,
    defaultFiles: PropTypes.array,
    removable: PropTypes.bool,
    showFiles: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    fileExtensions: PropTypes.arrayOf(PropTypes.string),
    maxMBSize: PropTypes.number,
    maxFiles: PropTypes.number,
    onUploaded: PropTypes.func,
    onUploadError: PropTypes.func,
    onRemoved: PropTypes.func,
    onRemoveError: PropTypes.func,
};

FileUpload.defaultProps = {
    api: fileUploadApi,
    maxMBSize: 4,
    maxFiles: 10,
    fileExtensions: [],
    error: false,
    errorMessage: '',
    showFiles: true,
    defaultFiles: [],
    removable: true,
};

FileUpload.buildApi = fileUploadApiBuilder;

export default FileUpload;
