import * as React from 'react';
import { WidgetProps } from '@rjsf/core';
import { Box, RadioButton, Image, Text, ThemeContext } from 'grommet';
import TooltipComponent from 'app/common/TooltipComponent';
import LabelAndValue from 'app/common/LabelAndValue';
import ErrorIcon from '../../common/assets/error.svg';
import FieldLabel from '../FieldLabel';

const RadioWidget: React.FunctionComponent<WidgetProps> = ({
  id,
  schema,
  uiSchema,
  options,
  value,
  label,
  onChange,
  onBlur,
  onFocus,
  disabled,
  rawErrors = [],
}) => {
  const { enumOptions } = options;
  const _onChange = (value) => {
    onChange(schema.type == 'boolean' ? value !== 'false' : value);
  };
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const radioColor =
    rawErrors.length > 0
      ? {
          dark: '#b81237',
          light: '#b81237',
        }
      : {
          dark: 'rgba(255, 255, 255, 0.5)',
          light: 'rgba(0, 0, 0, 0.15)',
        };

  const radioOptionToolTip = (option) => (
    <Box direction="row" gap="xxxsmall" align="center" justify="start">
      <Box>{option.label}</Box>
      {uiSchema['ui:tooltip'] && uiSchema['ui:tooltip'][option.label] && (
        <Box>
          <TooltipComponent text={uiSchema['ui:tooltip'][option.label]} />
        </Box>
      )}
    </Box>
  );

  if (disabled) {
    return (
      <Box fill pad={{ vertical: 'xxsmall' }}>
        <LabelAndValue
          label={label || schema.title}
          value={
            (enumOptions as Array<{ label: string; value: string }>).find((option) => option.value === value).label
          }
        />
      </Box>
    );
  } else {
    return (
      <Box margin={{ vertical: '15px' }} className="field">
        <FieldLabel htmlForId={id} label={label || schema.title} tooltip={uiSchema['ui:tooltip']} />
        <Box gap="small">
          {(enumOptions as Array<{ label: string; value: string }>).map((option, index) => (
            <ThemeContext.Extend
              value={{
                radioButton: {
                  border: {
                    color: radioColor,
                  },
                },
              }}
              key={index}
            >
              <RadioButton
                label={radioOptionToolTip(option)}
                onChange={() => _onChange(option.value)}
                onBlur={_onBlur}
                onFocus={_onFocus}
                name={id}
                checked={value === option.value}
                value={option.value}
              />
            </ThemeContext.Extend>
          ))}
        </Box>
        {rawErrors.length > 0 && (
          <Box margin={{ top: '8px' }}>
            <Box direction="row" align="center" gap="small">
              <Box>
                <Image src={ErrorIcon} />
              </Box>
              <Box>
                <Text color="#b81237" size="18px">
                  Please select an option
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
};

export default RadioWidget;
