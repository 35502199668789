import * as React from 'react';
import { WidgetProps } from '@rjsf/core';
import { Box } from 'grommet';
import LabelAndValue from 'app/common/LabelAndValue';
import { FormSelect } from '@snsw/react-component-library';
import styled from 'styled-components';
import FieldLabel from '../FieldLabel';

const renameLabelKeysToText = (options) => {
  return options.reduce((acc, curr) => {
    acc.push({ text: curr.label, value: curr.value });
    return acc;
  }, []);
};

const SelectContainer = styled(Box)`
  [class*='Field'] {
    margin: 0;
  }
`;

const SelectWidget: React.FunctionComponent<WidgetProps> = ({
  id,
  schema,
  options,
  value,
  label,
  onChange,
  disabled,
  rawErrors = [],
}) => {
  const { enumOptions } = options;

  const onSelectChange = (value) => {
    onChange(value);
  };

  if (disabled) {
    return (
      <Box fill pad={{ vertical: 'xxsmall' }}>
        <LabelAndValue
          label={label || schema.title}
          value={
            (enumOptions as Array<{ label: string; value: string }>).find((option) => option.value === value).label
          }
        />
      </Box>
    );
  } else {
    return (
      <Box margin={{ vertical: '15px' }} className="field">
        <FieldLabel htmlForId={id} label={label || schema.title} />
        <SelectContainer>
          <FormSelect
            id={id}
            name={id}
            options={renameLabelKeysToText(enumOptions)}
            placeholder="Select"
            onChange={(event) => onSelectChange(event.target.value)}
            hasError={rawErrors.length > 0}
            errorMessage={'Please select an option'}
            value={value}
          />
        </SelectContainer>
      </Box>
    );
  }
};

export default SelectWidget;
