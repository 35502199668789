import { TextInput as TextInputGrommet } from 'grommet';
import * as React from 'react';
import { EventHandler } from 'react';

type Props = {
  id: string;
  value: string | number;
  onChange: EventHandler<any>;
  required?: boolean;
  hasError?: boolean;
};

const TextInput: React.FunctionComponent<Props> = ({ id, value, onChange, required, hasError }: Props) => {
  return (
    <TextInputGrommet
      name={id}
      value={value}
      onChange={onChange}
      style={required && hasError ? { borderColor: '#b81237', borderWidth: '2px' } : { borderWidth: '2px' }}
    />
  );
};

export default TextInput;
