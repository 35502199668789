import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from './Field.styled';
import Checkbox from './FormCheckbox.styled';
import { getID } from '../../utils';
import { trackMe } from '../ComponentTracker/componentTracker';

export const FormCheckbox = ({ className, id, name, label, checked, value, onChange, hasError,
    errorMessage, disabled, testId, inputRef }) => {

    useEffect(() => {
        trackMe('FormCheckbox');
    }, []);

    const handleOnChange = ({ target: { checked } }) => onChange && onChange(checked ? value : '');

    const genID = () => {
        return id || getID();
    };

    const elemID = genID();
    const idError = `${elemID}-error`;

    return (
        <Checkbox.Item className={ className }>
            <Checkbox.Input type='checkbox'
                id={ elemID }
                name={ name }
                onChange={ handleOnChange }
                checked={ checked }
                disabled={ disabled }
                data-testid={ testId }
                aria-invalid={ hasError }
                aria-describedby={ idError }
                ref={ inputRef }
            />
            <Checkbox.Label htmlFor={ elemID } hasError={ hasError }>{label}</Checkbox.Label>
            {hasError && <Field.Error id={ idError }>{errorMessage}</Field.Error>}
        </Checkbox.Item>);
};

FormCheckbox.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    value: PropTypes.string.isRequired,
    testId: PropTypes.string,
    errorMessage: PropTypes.string,
    checked: PropTypes.bool,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

FormCheckbox.defaultProps = {
    label: '',
    testId: '',
    errorMessage: '',
    hasError: false,
    disabled: false,
};

export default FormCheckbox;
