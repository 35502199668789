import * as React from 'react';
import { AccordionPanel, Box, ThemeContext, Text } from 'grommet';
import { theme } from './DocumentLocation';
import ReusablePanel from './ReusablePanel';

type Props = {
  activeIndex: number[];
  documentLocation: any;
  saveDocumentLocation: (updated: any) => Promise<void>;
  closeAccordion: () => void;
  accordionIndex: number;
};

export const EnduringGuardianPanel: React.FunctionComponent<Props> = ({
  activeIndex,
  documentLocation,
  saveDocumentLocation,
  closeAccordion,
  accordionIndex,
}: Props) => {
  const onSave = (updated) => {
    documentLocation.eg = updated;
    saveDocumentLocation(documentLocation);
  };

  const label = () => {
    const label = `Enduring Guardianship`;
    if (documentLocation?.eg?.label) {
      return (
        <Text>
          {label} - <Text weight="bold">{documentLocation.eg.label}</Text>
        </Text>
      );
    }
    return <Text>{label}</Text>;
  };

  return (
    <ThemeContext.Extend value={theme(documentLocation?.eg?.saved)}>
      <Box
        pad="medium"
        fill
        background={activeIndex && activeIndex[0] === accordionIndex ? '' : '#F9FDFF'}
        border={{ color: '#C4D0D6', side: 'bottom' }}
        round={{ size: '1%', corner: 'bottom' }}
      >
        <AccordionPanel label={label()}>
          <ReusablePanel docTypeData={documentLocation.eg} onSave={onSave} closeAccordion={closeAccordion} />
        </AccordionPanel>
      </Box>
    </ThemeContext.Extend>
  );
};
