import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const minLength = (value, minLength) => {
    return value && (value.length < minLength);
};

export const dateBetween = (value, minDate, maxDate) => {
    return value.isBetween(minDate, maxDate, 'days', '[]'); // all inclusive
};

export const isMaskInputIncomplete = value => {
    return value && value.indexOf('_') !== -1;
};

export const emailFormat = (value) => {
    // eslint-disable-next-line max-len
    return value && !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value);
};

export const bankAccountNameDoesNotMatchRegex = (value) => {
    return value && !/^[a-z0-9][a-z0-9\s]{1,28}[a-z0-9]$/i.test(value);
};

export const bankAccountNumberDoesNotMatchRegex = (value) => {
    return value && !/^[0-9]{3,9}$/i.test(value);
};

export const alphaNameRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+$/i;

export const notAnAlphaName = (value) => {
    return !!value && !alphaNameRegex.test(value);
};

export const phoneNumberRegex = /^(\+?61[ ]?|0)[2-478]([ ]?[0-9]){8}$/;

export const notAustralianPhoneNumber = (value) => {
    return !!value && !phoneNumberRegex.test(value);
};

export const isAllWhitespace = (value) => {
    return !!value && value.trim() === '';
};

export default {
    dateBetween,
    emailFormat,
    isMaskInputIncomplete,
    bankAccountNameDoesNotMatchRegex,
    bankAccountNumberDoesNotMatchRegex,
    minLength,
    notAnAlphaName,
    notAustralianPhoneNumber,
    isAllWhitespace
};
