import React from 'react';
import PropTypes from 'prop-types';
import * as recaptcha from './Recaptcha';
import ComponentLoader from '../../Loader/ComponentLoader';
import Form from '../Form/Form';

export default class RecaptchaForm extends React.Component {
    state = {
        recaptchaId: '',
        showSpinner: false,
    };

    componentDidMount() {
        if (global.recaptchaLoaded) {
            this.renderRecaptcha();
        } else {
            this.bindCaptchaCallback();
        }
    };

    submitOnCaptchaResolved = () => {
        const recaptchaToken = recaptcha.getResponse(this.state.recaptchaId);
        this.props.onSubmit(recaptchaToken, this.hideSpinner);
    };

    renderRecaptcha() {
        const recaptchaId = recaptcha.init('recaptchaTarget', this.submitOnCaptchaResolved);

        this.setState({
            recaptchaId: recaptchaId
        });
    };

    bindCaptchaCallback() {
        global.getCaptchaResponse = () => {
            this.renderRecaptcha();
        };
    };

    handleSubmit = () => {
        if (this.props.onValidate()) {
            this.setState({ showSpinner: true });
            recaptcha.execute(this.state.recaptchaId);
        }
    };

    hideSpinner = () => {
        this.setState({ showSpinner: false });
    };

    render() {
        return <div>
            <Form onSubmit={ this.handleSubmit }>
                {this.props.children}
                <ComponentLoader active={ this.state.showSpinner }/>
            </Form>
            <div id='recaptchaTarget'/>
        </div>;
    };
}

/*
 * Form will pass recaptchaToken and hideSpinner callback to onSubmit.
 * onSubmit is in charge of construct request & hide spinner when error happened.
 */

RecaptchaForm.propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
};

RecaptchaForm.defaultProps = {
    onValidate: () => true,
};
