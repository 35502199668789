import * as React from 'react';
import { Box, Layer, ResponsiveContext } from 'grommet';
import { Button } from '@snsw/react-component-library';

type Props = {
  children: React.ReactElement | React.ReactElement[];
  handleContinue: CallableFunction;
  onModalClose?: () => void;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  continueButtonText?: string;
  disabledContinueButton?: boolean;
  style?: unknown;
};

const ActionModal: React.FunctionComponent<Props> = ({
  children,
  onModalClose,
  handleContinue,
  showCancelButton = true,
  disabledContinueButton = false,
  cancelButtonText = 'Cancel',
  continueButtonText = 'Continue',
  style,
}: Props) => {
  return (
    <Layer onEsc={onModalClose} style={style}>
      <Box justify="center" pad="large">
        {children}
        <Box>
          <Box direction="row" alignContent="between" justify={showCancelButton ? 'between' : 'center'}>
            {showCancelButton && (
              <Box alignSelf="start">
                <Button theme="secondary" type="button" onClick={onModalClose} className="button-dcs">
                  {cancelButtonText}
                </Button>
              </Box>
            )}
            <Box alignSelf="end">
              <Button
                className={`actionModalContinueButton-${continueButtonText} primary-button-dcs`}
                theme="primary"
                type="button"
                onClick={handleContinue}
                disabled={disabledContinueButton}
              >
                {continueButtonText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

export default ActionModal;
