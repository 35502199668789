import * as React from 'react';
import { Anchor, Box, Heading, Image, ResponsiveContext, Text } from 'grommet';
import { FormCheckbox } from '@snsw/react-component-library';
import styled from 'styled-components';
import { AnchorWithIconLabel } from '../features/legal-advise/LegalActionCard';
import ErrorIcon from './assets/error.svg';
import xIcon from './assets/x.svg';
import ActionModal from './ActionModal';

type Props = {
  isViewMode?: boolean;
  closeModal: () => void;
  saveAcknowledgement?: () => void;
  terms?: Array<string>;
  endText?: JSX.Element;
};

const fieldNamePrefix = 'disclaimer-';

const initialState = (terms: Array<string>) => {
  const iState = {};
  terms.forEach((_element, index) => {
    iState[`${fieldNamePrefix}${index}`] = '';
  });
  return iState;
};

const defaultEndText = (
  <Box direction="row" gap="xxxxsmall">
    <Text size="medium">{'Questions about your privacy? See our '}</Text>
    <Anchor
      size="medium"
      label={<AnchorWithIconLabel text={`Privacy Policy${' '}`} />}
      onClick={() => {
        window.open('https://www.nsw.gov.au/life-events/eol-privacy-policy', '_blank');
      }}
      style={{ textDecoration: 'underline' }}
    />
  </Box>
);

const DeclarationItem = styled(FormCheckbox)`
  font-size: 16px;
`;

const secureKeyName = (key: string) => {
  if (key.startsWith(fieldNamePrefix)) {
    return key;
  }
  throw new Error('Invalid key');
};

const Declaration: React.FunctionComponent<Props> = ({
  closeModal,
  saveAcknowledgement,
  isViewMode = false,
  terms = [],
  endText = defaultEndText,
}: Props) => {
  const size = React.useContext(ResponsiveContext);
  const [values, setValues] = React.useState(initialState(terms));
  const [errors, setErrors] = React.useState({});
  const checkErrors = (formValues) => {
    const newErrors = {};
    for (const [key, value] of Object.entries(formValues)) {
      if (!value) {
        newErrors[secureKeyName(key)] = true;
      }
    }
    return newErrors;
  };

  const handleOnChange = (fieldName, checked) => {
    const isChecked = !!checked || false;
    const updatedValues = {
      ...values,
      [secureKeyName(fieldName)]: isChecked,
    };

    setValues(updatedValues);
    if (isChecked) {
      delete errors[secureKeyName(fieldName)];
      setErrors({
        ...errors,
      });
    }
  };

  const handleContinue = () => {
    const newErrors = checkErrors(values);
    if (Object.values(newErrors).length === 0) {
      saveAcknowledgement();
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <ActionModal
      onModalClose={closeModal}
      handleContinue={isViewMode ? closeModal : handleContinue}
      showCancelButton={!isViewMode}
      continueButtonText={!isViewMode ? 'Save and continue' : 'Close'}
      style={
        size === 'small'
          ? {
              display: 'block',
              height: '100%',
              position: 'fixed',
              overflow: 'scroll',
              webkitOverflowScrolling: 'touch',
            }
          : null
      }
    >
      <Box>
        <Box align="start" justify="center">
          <Box direction="row" fill justify="end">
            <Anchor onClick={closeModal}>
              <Image src={xIcon} />
            </Anchor>
          </Box>
          <Box pad={{ bottom: 'large' }} direction="row" fill justify="between">
            <Heading level="3" margin="none">
              Declaration
            </Heading>
          </Box>
          <Box pad={{ bottom: 'large', right: 'xxsmall' }}>
            <form>
              {terms.map((term, index) => (
                <DeclarationItem
                  id={`${fieldNamePrefix}${index}`}
                  name={`${fieldNamePrefix}${index}`}
                  label={term}
                  checked={values[`${fieldNamePrefix}${index}`] || false}
                  value={'true'}
                  onChange={(value) => handleOnChange(`${fieldNamePrefix}${index}`, value)}
                  disabled={isViewMode}
                  hasError={!isViewMode && errors[`${fieldNamePrefix}${index}`]}
                  key={`${fieldNamePrefix}${index}`}
                />
              ))}
            </form>
          </Box>
          {endText && <Box pad={{ bottom: 'xlarge' }}>{endText}</Box>}
          {Object.values(errors).length !== 0 && (
            <Box margin={{ top: '8px', bottom: 'small' }}>
              <Box direction="row" align="center" gap="small">
                <Box>
                  <Image src={ErrorIcon} />
                </Box>
                <Box>
                  <Text color="#b81237" size="18px">
                    Please indicate your agreement to continue
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </ActionModal>
  );
};

export default Declaration;
