import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InfoCardContainer, InfoCardContent, InfoCardHeader } from './InfoCard.styled';
import { trackMe } from '../../Components/ComponentTracker/componentTracker';

const InfoCard = ({ header, verticalPadding = true, children, ...otherProps }) => {
    useEffect(() => {
        trackMe('InfoCard');
    }, []);

    return (
        <InfoCardContainer { ...otherProps }>
            {header && <InfoCardHeader>{header}</InfoCardHeader>}
            <InfoCardContent verticalPadding={ verticalPadding }>{children}</InfoCardContent>
        </InfoCardContainer>
    );
};

InfoCard.propTypes = {
    /** The header of the info card. */
    header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    /** Vertical padding of the content of the card. If yes top and bottom padding is 1rem */
    verticalPadding: PropTypes.bool,
    /** The content of the info card. */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
};

export default InfoCard;
