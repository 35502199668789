import * as React from 'react';
import { Box, Image } from 'grommet';
import infoIcon from 'app/common/assets/info.svg';
import HighlightCard from 'app/common/HighlightCard';

const InfoIcon = <Image src={infoIcon} />;

const VideoConferenceInfo: React.FunctionComponent = () => {
  return (
    <Box margin={{ top: 'medium' }}>
      <HighlightCard
        text={
          'For online video conference appointments, you may still be required to sign and finalise your documents in person.'
        }
        borderColor="#2E5299"
        backgroundColor="#EAEDF4"
        icon={InfoIcon}
      />
    </Box>
  );
};

export default VideoConferenceInfo;
