import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    HighlightCardContainer,
    HighlightCardContent,
    HighlightCardHeader,
    HighlightCardIcon
} from './HighlightCard.styled';
import { H5 } from '../../Headers/HeaderTag.styled';
import Icons from '../../Icons';
import styled from 'styled-components/macro';
import { space } from 'styled-system';
import { trackMe } from '../../Components/ComponentTracker/componentTracker';

const HighlightCard = ({ header, children, className }) => {
    useEffect(() => {
        trackMe('HighlightCard');
    }, []);

    return (
        <HighlightCardContainer className={ className }>
            <HighlightCardIcon>
                <Icons.Highlight color='darkBlue' height={ 35 } width={ 35 }/>
            </HighlightCardIcon>
            <HighlightCardContent>
                {header && <HighlightCardHeader>
                    <H5>{header}</H5>
                </HighlightCardHeader>}
                {children}
            </HighlightCardContent>
        </HighlightCardContainer>
    );
};

HighlightCard.propTypes = {
    className: PropTypes.string,
    header: PropTypes.string,
    children: PropTypes.node,
};

export default styled(HighlightCard)`
  ${space};
`;
