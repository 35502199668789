import * as React from 'react';
import { Box, Heading, Text, Anchor, ResponsiveContext } from 'grommet';
import ActionModal from 'app/common/ActionModal';
import { Button } from '@snsw/react-component-library';
import { useHistory } from 'react-router';
import ControlStatesContext from 'app/features/control-states/context/ControlStatesContext';
import { routes } from 'app/common/routes';
import HeadingOne from 'app/common/HeadingOne';
import LeavingFinalsAffairsModal from 'app/common/LeavingFinalAffairsModal';
import StepNavigationComponent from 'app/features/planning/components/StepNavigationComponent';
import BackLinkComponent from '../planning/components/BackLinkComponent';
import LegalActionCard, { AnchorWithIconLabel } from './LegalActionCard';

const GetLegalAdvice: React.FunctionComponent = () => {
  const history = useHistory();

  const [visibleModal, setVisibleModal] = React.useState(null);
  const [redirectLink, setRedirectLink] = React.useState(null);

  const { hasReachedEndOfFlow } = React.useContext(ControlStatesContext);

  const showRedirectToTagModal = () => {
    setRedirectLink('https://www.tag.nsw.gov.au/wills/make-will?referrer=mysnsw-legaladvice');
    setVisibleModal('redirectModal');
  };

  const showRedirectToLawSocietyModal = () => {
    setRedirectLink('https://www.lawsociety.com.au/for-the-public');
    setVisibleModal('redirectModal');
  };

  const showBookingModal = (event) => {
    setVisibleModal('bookingModal');
    event.stopPropagation();
  };

  const closeRedirectModal = () => {
    closeModal();
    setRedirectLink(null);
  };

  const closeModal = () => {
    setVisibleModal(null);
  };

  const redirectToPlanningHome = () => {
    history.push(routes.HOME);
  };

  const goToBookingPage = () => {
    history.push(routes.BOOKING);
  };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          <Box>
            <Box width={size === 'small' ? '100%' : '70%'}>
              <Box>
                <BackLinkComponent
                  handleBack={() => {
                    history.push(routes.HOME);
                  }}
                />
              </Box>
              <HeadingOne title="Get professional advice" />
              <Text margin={{ bottom: 'large' }}>
                Having a will prepared professionally could save your estate much higher legal fees if your will is
                contested or has to be interpreted by a court.
              </Text>
            </Box>
            <Box width={size === 'small' ? '100%' : '80%'}>
              <Box>
                <Box margin={{ bottom: 'large' }}>
                  <LegalActionCard
                    headerText="NSW Trustee & Guardian"
                    bodyText="Is a government agency with specialist teams that can help you create and finalise your will. They charge a maximum fee of $330 to prepare a will."
                    anchorText="Visit NSW Trustee & Guardian"
                    anchorOnClick={showRedirectToTagModal}
                    bookingOnClick={hasReachedEndOfFlow ? showBookingModal : null}
                  />
                </Box>
                <Box margin={{ bottom: 'xlarge' }}>
                  <LegalActionCard
                    headerText="The Law Society of NSW"
                    bodyText="Offers a solicitor referral service connecting members of the profession to the general public who would like legal advice."
                    anchorText="Visit Law Society of NSW"
                    anchorOnClick={showRedirectToLawSocietyModal}
                  />
                </Box>
              </Box>
            </Box>
            <Box margin={{ bottom: 'medium' }}>
              <StepNavigationComponent
                handleBack={() => {
                  history.push(routes.HOME);
                }}
                nextButton={
                  <Button theme="primary" className="primary-button-dcs" type="button" onClick={redirectToPlanningHome}>
                    Return to homepage
                  </Button>
                }
              />
            </Box>
          </Box>
          {visibleModal === 'redirectModal' && (
            <LeavingFinalsAffairsModal onModalClose={closeRedirectModal} redirectUrl={redirectLink} />
          )}
          {visibleModal === 'bookingModal' && (
            <ActionModal onModalClose={closeModal} handleContinue={goToBookingPage}>
              <Box>
                <Box>
                  <Heading level="3" margin={{ bottom: 'large' }}>
                    Sharing your information
                  </Heading>
                </Box>
                <Box>
                  <Text margin={{ bottom: 'xlarge' }}>
                    By selecting Continue, you are providing consent to share personal information you have entered with
                    NSW Trustee & Guardian for the purposes of making a booking request. If you do not agree to this,
                    please select cancel.
                  </Text>
                </Box>
                <Box>
                  <Anchor
                    size="medium"
                    href="https://www.tag.nsw.gov.au/privacy-policy.html"
                    label={<AnchorWithIconLabel text="NSW Trustee & Guardian Privacy Policy" />}
                    target="_blank"
                    margin={{ bottom: 'large' }}
                    style={{ textDecoration: 'underline' }}
                  />
                </Box>
              </Box>
            </ActionModal>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default GetLegalAdvice;
