import * as React from 'react';
import { Box, Text, TextInput, Select } from 'grommet';
import { Button } from '@snsw/react-component-library';
import { useHistory } from 'react-router';
import { routes } from 'app/common/routes';
import StepNavigationComponent from 'app/features/planning/components/StepNavigationComponent';
import UserContext from 'app/features/user/context/UserContext';
import BackLinkComponent from 'app/features/planning/components/BackLinkComponent';
import HeadingOne from 'app/common/HeadingOne';
import HeadingTwo from 'app/common/HeadingTwo';
import HighlightCardParagraphs from 'app/common/HighlightCardParagraphs';
import RequiredField from 'app/common/RequiredField';
import FieldRequired from 'app/common/FieldRequired';
import OptionRequired from 'app/common/OptionRequired';
import DataService from 'app/common/services/DataService';

const EnduringGuardianDetails: React.FunctionComponent = () => {
  const history = useHistory();

  const { user } = React.useContext(UserContext);

  const [enduringGuardianship, setEnduringGuardianship] = React.useState<EnduringGuardianship>(null);

  const [firstGuardian, setFirstGuardian] = React.useState('');
  const [secondGuardian, setSecondGuardian] = React.useState('');
  const [thirdGuardian, setThirdGuardian] = React.useState('');
  const [guardiansToAct, setGuardiansToAct] = React.useState(null);
  const [substituteGuardian, setSubstituteGuardian] = React.useState('');

  const [secondGuardianShow, setSecondGuardianShow] = React.useState(false);
  const [thirdGuardianShow, setThirdGuardianShow] = React.useState(false);
  const [addAnotherNameShow, setAddAnotherNameShow] = React.useState(true);
  const [guardiansToActShow, setGuardiansToActShow] = React.useState(false);

  const [firstGuardianValid, setFirstGuardianValid] = React.useState(true);
  const [secondGuardianValid, setSecondGuardianValid] = React.useState(true);
  const [thirdGuardianValid, setThirdGuardianValid] = React.useState(true);
  const [guardiansToActValid, setGuardiansToActValid] = React.useState(true);

  const onFirstGuardianChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstGuardian(e.target.value);
    if (firstGuardian) {
      setFirstGuardianValid(true);
    }
  };
  const onSecondGuardianChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecondGuardian(e.target.value);
    if (secondGuardian) {
      setSecondGuardianValid(true);
    }
  };
  const onThirdGuardianChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThirdGuardian(e.target.value);
    if (thirdGuardian) {
      setThirdGuardianValid(true);
    }
  };
  const onGuardiansToActChange = (option) => {
    console.log(option);
    setGuardiansToAct(option);
    if (option) {
      setGuardiansToActValid(true);
    }
  };
  const onSubstituteGuardianChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubstituteGuardian(e.target.value);
  };

  React.useEffect(() => {
    const loadEnduringGuardianship = async () => {
      try {
        const payload = await DataService.getTaskItem(user, 'enduringGuardianship');
        const eg = payload.enduringGuardianship;
        setEnduringGuardianship(eg);
        setFirstGuardian(eg.firstGuardian);
        setSecondGuardian(eg.secondGuardian);
        if (eg.secondGuardian) {
          setSecondGuardianShow(true);
          setGuardiansToActShow(true);
        }
        setThirdGuardian(eg.thirdGuardian);
        if (eg.thirdGuardian) {
          setThirdGuardianShow(true);
          setGuardiansToActShow(true);
        }
        setGuardiansToAct(eg.guardiansToAct);
        setSubstituteGuardian(eg.substituteGuardian);
      } catch (err) {
        console.warn('enduringGuardianship not found. 1st time in?');
      }
    };
    loadEnduringGuardianship();
  }, []);

  const addName = () => {
    if (!secondGuardianShow) {
      setSecondGuardianShow(true);
      setGuardiansToActShow(true);
    }
    if (secondGuardianShow && !thirdGuardianShow) {
      setThirdGuardianShow(true);
      setAddAnotherNameShow(false);
      setGuardiansToActShow(true);
    }
  };

  const deleteSecondGuardian = () => {
    if (thirdGuardian) {
      setSecondGuardian(thirdGuardian);
      setThirdGuardian('');
      setThirdGuardianShow(false);
      setAddAnotherNameShow(true);
    } else {
      setSecondGuardian('');
      setSecondGuardianShow(false);
      setGuardiansToAct(null);
      setGuardiansToActShow(false);
    }
  };

  const deleteThirdGuardian = () => {
    setThirdGuardian('');
    setAddAnotherNameShow(true);
    setThirdGuardianShow(false);
    if (!secondGuardianShow) {
      setGuardiansToAct(null);
      setGuardiansToActShow(false);
    }
  };

  const validate = () => {
    let valid = true;
    if (!firstGuardian) {
      setFirstGuardianValid(false);
      valid = false;
    }
    if (secondGuardianShow && !secondGuardian) {
      setSecondGuardianValid(false);
      valid = false;
    }
    if (thirdGuardianShow && !thirdGuardian) {
      setThirdGuardianValid(false);
      valid = false;
    }
    if (guardiansToActShow && !guardiansToAct) {
      setGuardiansToActValid(false);
      valid = false;
    }
    return valid;
  };

  const save = async () => {
    if (validate()) {
      enduringGuardianship.firstGuardian = firstGuardian;
      enduringGuardianship.secondGuardian = secondGuardian;
      enduringGuardianship.thirdGuardian = thirdGuardian;
      enduringGuardianship.guardiansToAct = guardiansToAct;
      enduringGuardianship.substituteGuardian = substituteGuardian;
      await DataService.updateTaskItem(user, enduringGuardianship, 'enduringGuardianship');
      history.push(routes.ENDURING_GUARDIAN_ADVANCE_CARE_DIRECTIVE);
    }
  };

  return (
    <>
      <BackLinkComponent
        handleBack={() => {
          history.push(routes.ENDURING_GUARDIAN_STATUS);
        }}
      />
      <HeadingOne title="Enduring Guardianship preparation" />
      <HeadingTwo title="What to consider when choosing your Enduring Guardian" />
      <Box margin={{ left: '17px', bottom: '20px' }}>
        <ul style={{ margin: 0, listStyleType: 'revert' }}>
          <li style={{ margin: '0 0 10px 0' }}>
            <Text>
              It is important the person(s) you choose is someone you can trust to uphold your values and preferences
              for your health, lifestyle and medical decisions.
            </Text>
          </li>
          <li style={{ margin: '0 0 10px 0' }}>
            <Text>
              An Enduring Guardian should not have any connection with those who provide you with accommodation, health
              care or services for a fee.
            </Text>
          </li>
          <li style={{ margin: '0 0 10px 0' }}>
            <Text>Choosing a person who lives close-by is more practical and recommended.</Text>
          </li>
          <li style={{ margin: '0 0 10px 0' }}>
            <Text>Consider appointing more than one person so as to share the burden.</Text>
          </li>
          <li style={{ margin: '0 0 10px 0' }}>
            <Text>
              If you get married after your Enduring Guardian appointment is formalised, it will be automatically
              revoked if you marry someone other than your appointed Enduring Guardian.
            </Text>
          </li>
          <li style={{ margin: '0 0 10px 0' }}>
            <Text>Consider consulting your medical professional in your planning.</Text>
          </li>
          <li style={{ margin: '0 0 10px 0' }}>
            <Text>Consider choosing a substitute as a ‘back-up’.</Text>
          </li>
        </ul>
      </Box>

      <HighlightCardParagraphs
        paragraphs={[
          `It is important you discuss your appointment with your chosen person(s) to ensure they understand their responsibilities and are willing to take on the role.`,
        ]}
        type={'legal'}
      />

      <RequiredField />

      <HeadingTwo title="Who are you considering to appoint?" />
      <Text>
        You can enter up to three names here. If you are considering more than three Enduring Guardians, it is best to
        discuss with your legal professional as multiple appointments may add to the complexity of decision making.
      </Text>

      <Box margin={{ top: '10px', bottom: '10px' }} width={{ min: 'small', max: 'medium' }}>
        <Box>
          <Text style={{ fontWeight: 'bold' }}>Enduring Guardian&apos;s full name *</Text>
        </Box>
        <Box direction="row" pad={{ vertical: '10px' }}>
          <Box width="276px">
            <TextInput
              value={firstGuardian}
              onChange={onFirstGuardianChange}
              style={!firstGuardianValid ? { borderColor: 'red' } : {}}
            />
          </Box>
          <Box></Box>
        </Box>
        {!firstGuardianValid && <FieldRequired />}
        {secondGuardianShow && (
          <>
            <Box>
              <Text style={{ fontWeight: 'bold' }}>Enduring Guardian&apos;s full name *</Text>
            </Box>
            <Box direction="row" pad={{ vertical: '10px' }}>
              <Box width="400px">
                <TextInput
                  value={secondGuardian}
                  onChange={onSecondGuardianChange}
                  style={!secondGuardianValid ? { borderColor: 'red' } : {}}
                />
              </Box>
              <Box margin={{ left: '20px' }} width="150px">
                <Button
                  theme="secondary"
                  size="short"
                  type="button"
                  className="button-dcs"
                  onClick={deleteSecondGuardian}
                >
                  Delete
                </Button>
              </Box>
            </Box>
            {!secondGuardianValid && <FieldRequired />}
          </>
        )}
        {thirdGuardianShow && (
          <>
            <Box>
              <Text style={{ fontWeight: 'bold' }}>Enduring Guardian&apos;s full name *</Text>
            </Box>
            <Box direction="row" pad={{ vertical: '10px' }}>
              <Box width="400px">
                <TextInput
                  value={thirdGuardian}
                  onChange={onThirdGuardianChange}
                  style={!thirdGuardianValid ? { borderColor: 'red' } : {}}
                />
              </Box>
              <Box margin={{ left: '20px' }} width="150px">
                <Button
                  theme="secondary"
                  size="short"
                  type="button"
                  className="button-dcs"
                  onClick={deleteThirdGuardian}
                >
                  Delete
                </Button>
              </Box>
            </Box>
            {!thirdGuardianValid && <FieldRequired />}
          </>
        )}
        {addAnotherNameShow && (
          <Box width="230px" pad={{ top: '10px' }}>
            <Button theme="primary" size="short" type="button" className="primary-button-dcs" onClick={addName}>
              Add another name
            </Button>
          </Box>
        )}
      </Box>

      <HeadingTwo title="Multiple Enduring Guardians" />
      <Text>If you appoint more than one guardian, you will need to decide how you want them to act.</Text>

      <Box margin={{ left: '17px', bottom: '20px' }}>
        <ul style={{ margin: 0, listStyleType: 'revert' }}>
          <li>
            <Text>
              <Text style={{ fontWeight: 'bold' }}>Jointly </Text>
              This means the Enduring Guardians must agree and make all decisions together.
            </Text>
          </li>
          <li>
            <Text>
              <Text style={{ fontWeight: 'bold' }}>Severally </Text>
              This means the Enduring Guardians are permitted to act independently.
            </Text>
          </li>
          <li>
            <Text>
              <Text style={{ fontWeight: 'bold' }}>Joint and severally </Text>
              This means the Enduring Guardian can either make decisions together or separately.
            </Text>
          </li>
        </ul>
      </Box>

      <Text>
        If you choose to have your guardian act jointly, several, jointly and severally, then you will have further
        decisions to make regarding whether to terminate the appointment or not if one of you Enduring Guardian dies,
        resigns, loses mental capacity, or the authority to act is revoked. It is recommended to discuss this with a
        professional.
      </Text>

      {guardiansToActShow && (
        <Box margin={{ top: '10px', bottom: '10px' }} width={{ min: 'small', max: 'large' }}>
          <Box>
            <Text style={{ fontWeight: 'bold' }}>I am considering appointing my guardians to act: *</Text>
          </Box>
          <Box direction="row" pad={{ vertical: '10px' }}>
            <Box width="276px">
              <Select
                placeholder="Select.."
                options={['Jointly', 'Independently', 'Jointly and independently', 'Not sure']}
                value={guardiansToAct}
                onChange={({ option }) => onGuardiansToActChange(option)}
              />
            </Box>
          </Box>
          {!guardiansToActValid && <OptionRequired />}
        </Box>
      )}

      <HeadingTwo title="Subsitute Enduring Guardian" />
      <Text>
        In the case that all of your original Enduring Guardian(s) die, resign or become incapacitated and can no longer
        act it is recommended to appoint a substitute guardian.
      </Text>

      <Box margin={{ top: '10px', bottom: '10px' }} width={{ min: 'small', max: 'medium' }}>
        <Box>
          <Text style={{ fontWeight: 'bold' }}>Substitute guardian&apos;s full name</Text>
        </Box>
        <Box direction="row" pad={{ vertical: '10px' }}>
          <Box width="276px">
            <TextInput value={substituteGuardian} onChange={onSubstituteGuardianChange} />
          </Box>
        </Box>
      </Box>

      <Box margin={{ bottom: '40px' }}>
        <Text>
          If you wish to have more than one substitute guardian, it is recommended you discuss this with a professional
          to make your document because there are further considerations that a professional will need to address with
          you.
        </Text>
      </Box>

      <Box margin={{ bottom: 'medium' }}>
        <StepNavigationComponent
          handleBack={() => {
            history.push(routes.ENDURING_GUARDIAN_STATUS);
          }}
          nextButton={
            <Button theme="primary" className="primary-button-dcs" type="button" onClick={save}>
              Save and continue
            </Button>
          }
        />
      </Box>
    </>
  );
};

export default EnduringGuardianDetails;
