import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CellField, CellDay, CellMonth, CellYear, DateContainer } from './FormDate.styled';
import Fieldset from '../Fieldset';
import Field from '../Field.styled';
import { trackMe } from '../../ComponentTracker/componentTracker';

dayjs.extend(customParseFormat);

export const FormDate = forwardRef(({
    className,
    id,
    name,
    label,
    value,
    onBlur,
    onChange,
    disabled,
    helpMessage,
    errorMessage,
    requiredMessage,
    validateOnFly,
    required,
    onValidateOnFlyChange,
    onValidate,
    hasError,
    ...rest
}, ref) => {

    useEffect(() => {
        trackMe('FormDate');
    }, []);

    const options = [
        { value: '', text: 'Select' },
        { value: 1, text: 'January' },
        { value: 2, text: 'February' },
        { value: 3, text: 'March' },
        { value: 4, text: 'April' },
        { value: 5, text: 'May' },
        { value: 6, text: 'June' },
        { value: 7, text: 'July' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'October' },
        { value: 11, text: 'November' },
        { value: 12, text: 'December' },
    ];

    const [day, setDay] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [errorText, setErrorText] = useState('');

    const getTestDate = (day, month, year) => dayjs(`${year}-${month}-${day}`, 'YYYY-M-D', true);

    const getDateString = (day, month, year) => {
        // Add zeroes onto any day/month less than 10 so it's easier for the consumer to process
        const displayDay = day && day < 10 ? '0' + day : day;
        const displayMonth = month && month < 10 ? '0' + month : month;
        day = displayDay ? `${displayDay}` : '';
        month = month ? `${displayMonth}-` : '';
        year = year ? `${year}-` : '';
        return `${year}${month}${day}`;
    };

    const validateDateAutomatically = (day, month, year) => {
        const testDate = getTestDate(day, month, year);

        if (required && (!day || !month || !year)) {
            return requiredMessage;
        }

        if (day && month && year) {
            return !testDate.isValid() ? errorMessage : '';
        }
    };

    const idDay = id + '-day';
    const idMonth = id + '-month';
    const idYear = id + '-year';
    const idDate = id + '-date';

    // Run once - 'value' is really 'initial value'. Once set, you can't change it.
    useEffect(() => {
        if (value) {
            const testDate = dayjs(value, ['YYYY-M-D', 'YYYY-MM-DD' ], true);
            if (testDate && testDate.isValid()) {
                setDay(testDate.date());
                // dayjs returns month from 0..11 not 1..12, so we have to +1 to fix that
                setMonth(testDate.month() + 1);
                setYear(testDate.year());
            }
        }
    }, [value]);

    useImperativeHandle(ref, () => {
        return ({
            // Call 'validate' to manually validate the control
            validate: () => {
                const error = validate(day, month, year);
                setErrorText(error);
                return !error;
            },
            // Call 'clear' to clear values and errors
            clear: () => {
                setDay(null);
                setMonth(null);
                setYear(null);
                setErrorText('');
            }
        });
    });

    useEffect(
        () => {
            onValidateOnFlyChange && onValidateOnFlyChange(validateOnFly);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [validateOnFly]
    );

    useEffect(
        () => {
            setErrorText(hasError ? errorMessage : '');

            // Re-run the custom validation if hasError has been switched off.
            if (!hasError) {
                const validateErrorMessage = validate(day, month, year);
                // Only when the date has been fully entered that we decided whether to display an error message or not
                validateOnFly && dateHasBeenFullyEntered() && setErrorText(validateErrorMessage);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasError]
    );

    const handleDayChange = event => {
        const currentDay = parseInt(event.target.value);
        setDay(currentDay);
        changed(currentDay, month, year);
        validateOnFly && setErrorText('');
    };

    const handleMonthChange = event => {
        const currentMonth = parseInt(event.target.value);
        setMonth(currentMonth);
        changed(day, currentMonth, year);
        validateOnFly && setErrorText('');
    };

    const handleYearChange = event => {
        const currentYear = parseInt(event.target.value);
        setYear(currentYear);
        changed(day, month, currentYear);
        validateOnFly && setErrorText('');
    };

    const changed = (day, month, year) => {
        if (onChange) {
            const isError = !!validate(day, month, year);
            const event = createEvent(isError, day, month, year);
            onChange(event);
        }
    };

    const createEvent = (isError, day, month, year) => {
        const formattedDate = getFormattedDate(isError, day, month, year);
        const event = { value: formattedDate, hasError: isError };
        // If we're validating on the fly and the field is required, but the user hasn't entered all the date yet,
        // then we're in an error state by default. So set an error flag.
        if (validateOnFly && required && !dateHasBeenFullyEntered()) {
            event.hasError = true;
        };
        return event;
    };

    const validate = (validateDay, validateMonth, validateYear) => {
        // If an error has been forced upon us by the consumer, use their error.
        if (hasError) {
            setErrorText(errorMessage);
            return errorMessage;
        }

        const autoErrorMessage = validateDateAutomatically(validateDay, validateMonth, validateYear);
        if (autoErrorMessage) { return autoErrorMessage; }
        // If there's no error so far, ask the consumer if they believe there's an error.
        if (onValidate) {
            const validateErrorMessage = onValidate(validateDay, validateMonth, validateYear);
            if (validateErrorMessage) { return validateErrorMessage; }
        }
    };

    const handleBlur = () => {
        const validateErrorMessage = validate(day, month, year);
        const isError = !!validateErrorMessage; // If there's an error message, we've got an error

        // Only when the date has been fully entered that we decided whether to display an error message or not
        validateOnFly && dateHasBeenFullyEntered() && setErrorText(validateErrorMessage);

        if (!value || dateHasBeenFullyEntered()) {
            const event = createEvent(isError, day, month, year);
            onBlur && onBlur(event);
        }
    };

    const dateHasBeenFullyEntered = () => {
        return (!!day && !!month && !!year);
    };

    const getFormattedDate = (isError, day, month, year) => {
        const testDate = getTestDate(day, month, year);
        return (!isError && day && month && year) ? testDate.format('YYYY-MM-DD') : getDateString(day, month, year);
    };

    return (
        <Fieldset
            legend={ label }
            hasError={ hasError }
            helpMessage={ helpMessage }
            errorMessage={ errorMessage }
            className={ className }
        >
            <DateContainer className='date-container'>
                <CellDay>
                    <CellField { ...rest }>
                        <Field.Label htmlFor={ idDay }>Day</Field.Label>
                        <Field.Input
                            id={ idDay }
                            disabled={ disabled }
                            onChange={ handleDayChange }
                            onBlur={ handleBlur }
                            hasError={ errorText }
                            type='text'
                            value={ day || '' }
                            data-test='day'
                            data-testid='day'
                            pattern='[0-9]*'
                            inputmode='numeric'
                        >
                        </Field.Input>
                    </CellField>
                </CellDay>
                <CellMonth>
                    <CellField { ...rest }>
                        <Field.Label htmlFor={ idMonth }>Month</Field.Label>
                        <Field.Select
                            id={ idMonth }
                            disabled={ disabled }
                            onChange={ handleMonthChange }
                            onBlur={ handleBlur }
                            hasError={ errorText }
                            value={ month || '' }
                            data-test='month'
                            data-testid='month'
                        >
                            { options.map((option, idx) =>
                                <option key={ idx } value={ option.value }>
                                    { option.text }
                                </option>) }
                        </Field.Select>
                    </CellField>
                </CellMonth>
                <CellYear>
                    <CellField { ...rest }>
                        <Field.Label htmlFor={ idYear }>Year</Field.Label>
                        <Field.Input
                            id={ idYear }
                            disabled={ disabled }
                            onChange={ handleYearChange }
                            onBlur={ handleBlur }
                            hasError={ errorText }
                            type='text'
                            value={ year || '' }
                            data-test='year'
                            data-testid='year'
                            pattern='[0-9]*'
                            inputmode='numeric'
                        >
                        </Field.Input>
                    </CellField>
                </CellYear>
                <input type='hidden' id={ idDate } data-test='date' data-testid='date' name={ name }
                    value={ day && month && year ? getDateString(day, month, year) : '' } />
            </DateContainer>
            { errorText &&
                <Field.Error data-testid='error'>{ errorText }</Field.Error>
            }
        </Fieldset>
    );
});

FormDate.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helpMessage: PropTypes.string,
    errorMessage: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool.isRequired,
    requiredMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    validateOnFly: PropTypes.bool.isRequired,
    onValidateOnFlyChange: PropTypes.func,
    onValidate: PropTypes.func
};

FormDate.defaultProps = {
    id: '',
    helpMessage: '',
    errorMessage: '',
    hasError: false,
    disabled: false,
    requiredMessage: 'A date is required.',
    required: false,
    validateOnFly: true,
};

export default FormDate;
