import styled, { css } from 'styled-components/macro';
import { colors } from '../GlobalStyle/colors/colors';
import { AccordionHeader, AccordionTileContainer } from './Accordion.styled';
import { LabelContainer } from './Label.styled';

export const AccordionBlue = styled.div`
  ${AccordionTileContainer} {
    &:first-of-type {
      .top {
        border-left: none;
      }
    }
  
    .top {
       margin-top: 0;
       border-left: ${props => props.stepAccordion ? css`${colors.blackBlue} solid 1px;` : css`none;`};
    }

    .bottom {
      margin-bottom: 0;
      border-left: ${props => props.stepAccordion ? css`${colors.blackBlue} solid 1px;` : css`none;`};
    }
    
    ${props => props.stepAccordion ? css`
      :last-child .bottom {
        &:before {
          content: '';
          position: absolute;
          width: 14px;
          height: 2px;
          background: ${colors.blackBlue};
          bottom: 0;
          left: 10px;
        }
      }
      
      :last-child::after {
          content: '';
          display: block;
          width: calc(100% + 1.5rem);
          height: 100%;
          border-bottom: ${colors.lightGrey2} solid 1px;
          position: relative;
          bottom: -25px;
      }
    ` : css``};
  }
  
  ${AccordionHeader} {
    ${LabelContainer} {
      color: ${colors.white};
      background-color: ${colors.blue};
    }
  }
`;