import { Box } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import TooltipComponent from '../common/TooltipComponent';

const LabelContainer = styled.label`
  font-size: 16px;
  font-weight: 700;
`;

const FieldLabel: React.FunctionComponent<any> = ({ htmlForId, label, tooltip, margin }) => {
  const customMargin = margin ? margin : { bottom: 'xsmall' };

  return (
    <Box direction="row" align="center" justify="start" margin={{ ...customMargin }}>
      <LabelContainer htmlFor={htmlForId}>{label}</LabelContainer>
      {tooltip && (
        <Box pad={{ left: 'xxxxsmall' }}>
          <TooltipComponent text={tooltip} />
        </Box>
      )}
    </Box>
  );
};

export default FieldLabel;
