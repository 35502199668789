import React from 'react';
import styled from 'styled-components/macro';
import { color, layout, space } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

const InfoSvg = ({ className, infoColor, circleColor, width, height, fillCircle }) => (
    <svg className={ className } width={ width } height={ height }
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 15 15' focusable='false' aria-hidden='true'>
        <g istroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-736.000000, -367.000000)' fillRule='nonzero'>
                <g transform='translate(737.000000, 364.000000)' fill={ fillCircle ? ( colors.legacy[circleColor] || circleColor ) : 'none'}>
                    <circle stroke={ colors.legacy[circleColor] || circleColor } cx='6.5' cy='10.5' r='6.5' />
                    <path d='M6.54785156,6.54882812 L6.9921875,6.8515625 C7.10286514,6.92643267 7.18505833,7.00455688 7.23876953,7.0859375 C7.29248074,7.16731812 7.31933594,7.25520786 7.31933594,7.34960938 C7.31933594,7.44726611 7.29817729,7.53108689 7.25585938,7.60107422 C7.21354146,7.67106155 7.13867241,7.74348921 7.03125,7.81835938 L6.55761719,8.140625 L6.11816406,7.84765625 C6.00097598,7.77278608 5.91390002,7.69466187 5.85693359,7.61328125 C5.79996716,7.53190063 5.77148438,7.44726606 5.77148438,7.359375 C5.77148438,7.26171826 5.79427061,7.1754561 5.83984375,7.10058594 C5.88541689,7.02571577 5.96842388,6.94596396 6.08886719,6.86132812 L6.54785156,6.54882812 Z M7.23144531,9 C7.21191396,9.70312852 7.19645188,10.3167292 7.18505859,10.8408203 C7.17366531,11.3649115 7.16796875,11.7945947 7.16796875,12.1298828 L7.16796875,12.7011719 C7.16796875,13.0820332 7.20621707,13.3253575 7.28271484,13.4311523 C7.35921262,13.5369471 7.50488174,13.5898438 7.71972656,13.5898438 C7.73600269,13.5898438 7.7612303,13.5882162 7.79541016,13.5849609 C7.82959001,13.5817057 7.8580728,13.5800781 7.88085938,13.5800781 L7.88085938,14 C7.58137871,13.9804687 7.31933706,13.9658204 7.09472656,13.9560547 C6.87011606,13.946289 6.68457104,13.9414062 6.53808594,13.9414062 C6.4143874,13.9414062 6.24755964,13.946289 6.03759766,13.9560547 C5.82763567,13.9658204 5.57780093,13.9804687 5.28808594,14 L5.28808594,13.5800781 L5.390625,13.5800781 C5.80729375,13.5800781 6.01725259,13.1959674 6.02050781,12.4277344 L6.02050781,12.1396484 L6.03027344,11.0996094 L6.03027344,10.6699219 C6.03027344,10.3053367 6.00097686,10.0709641 5.94238281,9.96679688 C5.88378877,9.86262969 5.76985762,9.81054688 5.60058594,9.81054688 C5.57779937,9.81054688 5.52571655,9.81380205 5.44433594,9.8203125 C5.40852847,9.82356772 5.38085947,9.8268229 5.36132812,9.83007812 L5.28808594,9.43945312 C5.63313975,9.39387998 5.96516768,9.33528682 6.28417969,9.26367188 C6.6031917,9.19205693 6.91894375,9.10416719 7.23144531,9 Z'
                        fill={ colors.legacy[infoColor] || infoColor } />
                </g>
            </g>
        </g>
    </svg>
);

const Info = styled(InfoSvg)`
  ${color};
  ${layout};
  ${space};
`;

Info.defaultProps = {
    circleColor: '#949494',
    fillCircle: false,
    height: 15,
    infoColor: '#B8B8B8',
    marginRight: '0.5rem',
    width: 15
};

export default Info;
