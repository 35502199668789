import * as React from 'react';
import {
  Page,
  Text as ReactPDFText,
  Image as ReactPdfImage,
  View,
  Document,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { Button } from '@snsw/react-component-library';
import equals from 'ramda/src/equals';
import nswLogo from '../../assets/nswGovLogo.jpg';
import nswWatermark from '../../assets/nswGovWatermark.jpg';
import warning from '../../assets/warning.jpg';
import styles from './stylesheet';
import UserDetailSection from './UserDetailSection';
import AssetsSection from './AssetsSection';
import QuestionsSection from './QuestionsSection';

const hasPropsChange = (prevProps, nextProps) => {
  return (
    equals(prevProps.user, nextProps.user) &&
    equals(prevProps.assets, nextProps.assets) &&
    equals(prevProps.personalQuestionsAnswers, nextProps.personalQuestionsAnswers)
  );
};

const pdfDocument = (user, assets, personalQuestionsAnswers) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.topSpace} fixed></View>
        <View style={styles.watermark} fixed>
          <ReactPdfImage style={styles.image} src={nswWatermark} />
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.warningIconContainer}>
            <ReactPdfImage style={styles.iconImage} src={warning} />
          </View>
          <View style={styles.warningTextContainer}>
            <ReactPDFText>
              This document is a record of the information you provided. It is not instructions for or a draft of, a
              final will, power of attorney or appointment of enduring guardian. Nor does it cancel or alter any of
              these documents.
            </ReactPDFText>
          </View>
        </View>
        <View>
          <View style={styles.header}>
            <ReactPdfImage style={styles.image} src={nswLogo} />
          </View>
          <View style={styles.body}>
            <View style={styles.section}>
              <ReactPDFText style={styles.title}>Your will planning summary</ReactPDFText>
              <ReactPDFText>Remember to keep your information up-to-date.</ReactPDFText>
            </View>
            <UserDetailSection user={user} />
            <View style={styles.horizontalLine}></View>
            <AssetsSection assets={assets} />
            <View style={styles.horizontalLine}></View>
            <QuestionsSection personalQuestionsAnswers={personalQuestionsAnswers} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const WillSummaryPdfLink: React.FunctionComponent<{ user; assets; personalQuestionsAnswers }> = ({
  user,
  assets,
  personalQuestionsAnswers,
}) => {
  return (
    <PDFDownloadLink
      document={pdfDocument(user, assets, personalQuestionsAnswers)}
      fileName="Will_Planning_Summary.pdf"
    >
      {({ loading, error }) => {
        if (loading || error) {
          return null;
        }
        return (
          <Button theme="tertiary" type="button">
            Download PDF
          </Button>
        );
      }}
    </PDFDownloadLink>
  );
};

export default React.memo(WillSummaryPdfLink, (prevProps, nextProps) => {
  return hasPropsChange(prevProps, nextProps);
});
