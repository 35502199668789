import * as React from 'react';
import { utils } from '@rjsf/core';
import { JSONSchema7 } from 'json-schema';
import { Box } from 'grommet';
import { FormInput, Icons } from '@snsw/react-component-library';

const { ADDITIONAL_PROPERTY_FLAG } = utils;

type WrapIfAdditionalProps = {
  children: React.ReactElement;
  classNames: string;
  disabled: boolean;
  id: string;
  label: string;
  onDropPropertyClick: (index: string) => (event?: unknown) => void;
  onKeyChange: (index: string) => (event?: unknown) => void;
  readonly: boolean;
  required: boolean;
  schema: JSONSchema7;
};

const WrapIfAdditional: React.FunctionComponent<WrapIfAdditionalProps> = ({
  children,
  disabled,
  id,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  schema,
}) => {
  const keyLabel = `${label} Key`;
  const additional = Object.prototype.hasOwnProperty.call(schema, ADDITIONAL_PROPERTY_FLAG);

  if (!additional) {
    return <>{children}</>;
  }

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => onKeyChange(target.value);

  return (
    <Box>
      <Box>
        <FormInput
          disabled={disabled || readonly}
          id={`${id}-key`}
          name={`${id}-key`}
          label={keyLabel}
          ceholder={label}
          suffix=""
          type="input"
          value=""
          onBlur={!readonly ? handleBlur : undefined}
        />
      </Box>
      <Box>{children}</Box>
      <Box>
        <Icons.Delete
          color="#2e5299"
          height={24}
          width={24}
          onClick={onDropPropertyClick(label)}
          disabled={disabled || readonly}
        />
      </Box>
    </Box>
  );
};

export default WrapIfAdditional;
