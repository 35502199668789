import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { redirectTo } from '../../../common/navigation';
import { getAccessTokenInLocalStorage } from './AuthService';

type CustomerDetails = MyAccount.CustomerDetails;

const baseURL = process.env.IDENTITY_URL;

const identityClient = axios.create({ baseURL });

const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${getAccessTokenInLocalStorage()}`,
    },
  };
};

export const getCustomerDetails = async (): Promise<CustomerDetails> => {
  const { data } = await identityClient.get('/customers/me', getHeaders());
  return data.customer;
};

export const doLogin = (): void => {
  const responseType = 'code';
  const redirectUri = `${window.location.origin}/auth/callback`;
  const scope = 'openid%20email%20profile';
  const clientId = process.env.X_API_KEY;
  const state = `SNSW_${uuidv4()}`;

  redirectTo(
    `${baseURL}/authorize?response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&client_id=${clientId}&state=${state}`,
  );
};

export const doLogout = (accessToken: AccessToken): Promise<unknown> => {
  return identityClient.delete(`/tokens/${accessToken}`);
};
