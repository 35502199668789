import { Box } from 'grommet';
import * as React from 'react';
import './footer.css';

const Footer: React.FunctionComponent = () => {
  return (
    <Box border={{ side: 'top' }} fill>
      <Box pad={{ horizontal: '5%' }}>
        <div className="nsw-footer__upper">
          <div id="block-footerupper">
            <div className="section-links">
              <div className="section-links__group">
                <h3 className="section-links__heading">
                  <a href="https://www.nsw.gov.au/">Popular </a>
                </h3>
                <ul className="section-links__list">
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/premier-of-nsw/contact-premier">Contact the Premier</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/nsw-government/ministers">Contact a Minister</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/about-nsw">About NSW</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/about-nsw/state-flag">State flag</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/state-funerals">State Funerals</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/news">News</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://iworkfor.nsw.gov.au/" className="is-external">
                      Find a job in NSW Government
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/regional-nsw/buy-regional">Buy Regional</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/life-events">Life events</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/living-nsw/school-and-public-holidays">
                      NSW school and public holidays
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.service.nsw.gov.au/nswgovdirectory" className="is-external">
                      NSW Government directory
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.service.nsw.gov.au/service-centre" className="is-external">
                      Service NSW locations
                    </a>
                  </li>
                </ul>
              </div>
              <div className="section-links__group">
                <h3 className="section-links__heading">
                  <a href="https://www.nsw.gov.au/about-this-website">About this website</a>
                </h3>
                <ul className="section-links__list">
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/customer-service/who-we-are/access-to-information">
                      Access to information
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/accessibility-statement">Accessibility statement</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/copyright">Copyright</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/disclaimer">Disclaimer</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/privacy-statement">Privacy Statement</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/sitemap">Sitemap</a>
                  </li>
                </ul>
              </div>
              <div className="section-links__group">
                <h3 className="section-links__heading">
                  <a href="https://www.nsw.gov.au/departments-and-agencies">Departments</a>
                </h3>
                <ul className="section-links__list">
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/customer-service">Customer Service</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.dcj.nsw.gov.au/" className="is-external">
                      Communities and Justice
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/department-of-education">Education</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/ministry-of-health">Health</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.dpie.nsw.gov.au/" className="is-external">
                      Planning, Industry and Environment
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/department-of-premier-and-cabinet">Premier and Cabinet</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.dpie.nsw.gov.au/our-work/regions-ind-ag-resources" className="is-external">
                      Regional NSW
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.transport.nsw.gov.au/" className="is-external">
                      Transport
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.treasury.nsw.gov.au/" className="is-external">
                      Treasury
                    </a>
                  </li>
                </ul>
              </div>
              <div className="section-links__group">
                <h3 className="section-links__heading">
                  <a href="https://www.nsw.gov.au/nsw-government">NSW Government </a>
                </h3>
                <ul className="section-links__list">
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/premier-of-nsw">The Premier</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/media-releases">Media releases</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/nsw-government/ministers">NSW Ministers</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.parliament.nsw.gov.au/members/pages/all-members.aspx" className="is-external">
                      Find your local Member of Parliament
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/have-your-say">Have your say</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://boards.dpc.nsw.gov.au/" className="is-external">
                      Boards and Committees
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://iworkfor.nsw.gov.au/" className="is-external">
                      I work for NSW
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/nsw-government-communications">NSW Government communications</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/nsw-government/unsolicited-proposals">Unsolicited proposals</a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/nsw-government/projects-and-initiatives">
                      Projects and initiatives
                    </a>
                  </li>
                  <li className="section-links__item">
                    <a href="https://www.nsw.gov.au/premiers-priorities">Premier&apos;s Priorities</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <Box>
        <div className="nsw-footer__lower">
          <Box pad={{ horizontal: '5%' }}>
            <div id="block-traditionalcustodianacknowledgement">
              We pay respect to the Traditional Custodians and First Peoples of NSW, and acknowledge their continued
              connection to their country and culture.
            </div>
            <div id="block-copyrightmessage">Copyright © 2020</div>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Footer;
