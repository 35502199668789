import * as qs from 'querystring';
import { ParsedUrlQuery } from 'querystring';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { saveTokensInLocalStorage, getTokensByAuthorizationCode, logout } from 'user/components/AuthService';
import { routes } from 'app/common/routes';
import ActionModal from 'app/common/ActionModal';
import { Box, Heading, Text } from 'grommet';
import { redirectTo } from 'app/common/navigation';
import UserContext from '../context/UserContext';

const getQueryParams = (location): ParsedUrlQuery => {
  const queryParam = location.search.replace('?', '');
  return qs.parse(queryParam);
};

const AuthCallback: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const [isFailedLoginModalVisible, setIsFailedLoginModalVisible] = React.useState(false);
  const { setUser } = React.useContext(UserContext);

  React.useEffect(() => {
    const queryParams = getQueryParams(location);
    getTokensByAuthorizationCode(queryParams.code as string)
      .then((tokens) => {
        saveTokensInLocalStorage(tokens);
        history.push(routes.HOME);
      })
      .catch((e) => {
        setIsFailedLoginModalVisible(true);
      });
  }, []);

  const clearUser = async () => {
    setUser(null);
    return logout();
  };

  const handleClose = async () => {
    try {
      await clearUser();
    } finally {
      redirectTo(process.env.LOGOUT_REDIRECT);
    }
  };

  return (
    isFailedLoginModalVisible && (
      <ActionModal showCancelButton={false} handleContinue={handleClose} continueButtonText="Close">
        <Box>
          <Box>
            <Heading level="3" margin={{ bottom: 'large' }}>
              It looks like there was a problem
            </Heading>
          </Box>
          <Box>
            <Text margin={{ bottom: 'xlarge' }}>
              We apoligise for the disruption. We&apos;re working to fix the issue.
            </Text>
          </Box>
        </Box>
      </ActionModal>
    )
  );
};

export default AuthCallback;
