import styled from 'styled-components';

interface SpinnerProps {
  color: string;
}

const Spinner = styled.div.attrs<SpinnerProps>(({ color }) => ({
  color,
}))`
  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: spinAround 500ms infinite linear;
  border: 2px solid ${(props: SpinnerProps) => props.color};
  border-radius: 20px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
  margin: auto;
`;

export default Spinner;
