import * as React from 'react';
import { ObjectFieldTemplateProps } from '@rjsf/core';
import { utils } from '@rjsf/core';
import { Box } from 'grommet';
import AddButton from '../AddButton/AddButton';

const { canExpand } = utils;

const ObjectFieldTemplate: React.FunctionComponent<ObjectFieldTemplateProps> = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
}) => {
  return (
    <>
      {(uiSchema['ui:title'] || title) && <TitleField id={`${idSchema.$id}-title`} title={title} required={required} />}
      {description && (
        <Box pad={{ top: 'xsmall' }}>
          <DescriptionField id={`${idSchema.$id}-description`} description={description} />
        </Box>
      )}
      <Box>
        {properties.map((element, index: number) => (
          <Box key={index}>{element.content}</Box>
        ))}
        {canExpand(schema, uiSchema, formData) && (
          <Box>
            <Box>
              <AddButton
                className="object-property-expand"
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ObjectFieldTemplate;
