const tasks = {
  documentLocation: {
    path: 'documentLocation',
  },
  enduringGuardianship: {
    path: 'enduringGuardianship',
  },
};

export default tasks;
