import styled from 'styled-components/macro';
import { H6 } from '../../Headers/HeaderTag.styled';
import Icons from '../../Icons';

export const LinkCardContainer = styled.div`
  cursor: pointer;
  color: #242934;
  height: ${props => props.height};
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.07);
  border-top: 4px solid #2e5299;
  border-radius: 4px;
  padding: 16px 24px;
  margin-bottom: 1.5rem;
  position: relative;

  @media (max-width: 576px) {
    height: 13rem;
  }

  :hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
    background-color: #2e5299;

    ${Icons.Arrow} {
      margin-left: 1rem;

      g {
        stroke: white;
        fill: white;
      }
    }
  }

  ${H6} {
    margin-bottom: 0.5rem;
  }

   ${Icons.Arrow} {
    position: absolute;
    left: 1.5rem;
    bottom: 1.5rem;
    font-weight: bold;
    margin-left: 0;
    transition: margin-left 500ms ease-in-out;
  }
`;

export const LinkCardTitle = styled.div`
  display: flex
`;
