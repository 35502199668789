import * as React from 'react';
import { Box, Heading } from 'grommet';
import { FormInput } from '@snsw/react-component-library';

type BookingAdditionalNotesProps = {
  name: string;
  value: string;
  onChange: (value: string) => void;
};

const updateAdditionalNotes = (onChange) => (event) => {
  onChange(event.target.value);
};

const BookingAdditionalNotes: React.FunctionComponent<BookingAdditionalNotesProps> = ({ name, value, onChange }) => {
  return (
    <Box>
      <Box>
        <Box direction="row" align="center" justify="start" gap="xxsmall">
          <Box>
            <Heading level="5">Additional notes</Heading>
          </Box>
        </Box>
      </Box>
      <Box>
        <FormInput type="input" name={name} value={value} onChange={updateAdditionalNotes(onChange)} />
      </Box>
    </Box>
  );
};

export default BookingAdditionalNotes;
