import React, { Component } from 'react';
import { AutoSuggestContainer } from './AutoSuggest.styled';
import Autosuggest from 'react-autosuggest';
import Field from '../Form/Field.styled';
import PropTypes from 'prop-types';
import { getID } from '../../utils';
import { trackMe } from '../ComponentTracker/componentTracker';

class AutoSuggest extends Component {
    static defaultProps = {
        minLength: 3
    }

    constructor(props) {
        super(props);
        this.state = { value: this.props.initialValue };
        trackMe('AutoSuggest');
    }

    componentDidUpdate(prevProps) {
        const { initialValue, onValueChange } = this.props;
        if (initialValue && !prevProps.initialValue) {
            this.setState({
                value: initialValue,
                suggestion: null
            });

            onValueChange(initialValue);
        }
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });

        this.props.onValueChange(newValue);
    };

    onBlur = (event, { highlightedSuggestion }) => {
        // Use highlightedSuggestion where possible, otherwise default to suggestion.
        const blurSuggestion = highlightedSuggestion ?? this.state.suggestion;
        // Update suggestion always
        this.setState({ suggestion: blurSuggestion });
        this.props.onBlur(blurSuggestion);
    };

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim();
        if (inputValue.length >= this.props.minLength) {
            this.props.onGetSuggestions(value);
        } else {
            this.props.onClearSuggestions();
        }
    };

    onSuggestionsClearRequested = () => {
        this.props.onClearSuggestions();
    };

    onSuggestionSelected = (_, { suggestion }) => {
        this.setState({ suggestion });
        this.props.onSelect(suggestion);
    };

    render() {
        const {
            className,
            id,
            name,
            label,
            helpMessage,
            hasError,
            errorMessage,
            disabled,
            placeholder,
            suggestions,
            getSuggestionValue,
            renderSuggestion,
            shouldRenderSuggestions,
            showManualAddress,
        } = this.props;

        const genID = () => {
            return id || getID();
        };

        const elemID = genID();
        const idError = `${elemID}-error`;
        const idHelper = `${elemID}-helper`;

        const value = this.state.value || '';
        const inputProps = {
            id: elemID, name, disabled, placeholder, value, onChange: this.onChange,
            onBlur: this.onBlur,
            'aria-invalid': hasError, 'aria-describedby': `${idError} ${idHelper}`
        };

        let finalSuggestions = suggestions;
        if (showManualAddress) {
            finalSuggestions = suggestions.concat({
                address: <>Can’t find your address? &nbsp;<span className='enter-address-manually'>Click here</span></>
            });
        }
        return (
            <Field className={ className }>
                { label &&
                    <Field.Label
                        htmlFor={ elemID }
                        style={ { maxWidth: '100%' } }
                    >
                        { label }
                    </Field.Label>
                }
                { helpMessage && <Field.Help id={ idHelper }>{ helpMessage }</Field.Help> }
                <AutoSuggestContainer showManualAddress={ showManualAddress } disabled={ disabled } hasError={ hasError }>
                    <Autosuggest
                        suggestions={ finalSuggestions }
                        getSuggestionValue={ getSuggestionValue }
                        inputProps={ inputProps }
                        onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
                        onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
                        onSuggestionSelected={ this.onSuggestionSelected }
                        shouldRenderSuggestions={ shouldRenderSuggestions }
                        renderSuggestion={ renderSuggestion }
                    />
                </AutoSuggestContainer>
                { hasError && <Field.Error id={ idError }>{ errorMessage }</Field.Error> }
            </Field >
        );
    }
}

AutoSuggest.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    helpMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    suggestions: PropTypes.array.isRequired,
    onGetSuggestions: PropTypes.func.isRequired,
    onClearSuggestions: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    getSuggestionValue: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    shouldRenderSuggestions: PropTypes.func,
    showManualAddress: PropTypes.bool,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    minLength: PropTypes.number.isRequired,
};

export default AutoSuggest;
