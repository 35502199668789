import styled from 'styled-components';
import { Text } from 'grommet';

const TextError = styled(Text)`
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 16px;
  color: rgb(184, 18, 55);
`;

export default TextError;
