import * as React from 'react';
import { Heading } from 'grommet';

type Props = {
  title: string;
};

const HeadingTwo: React.FunctionComponent<Props> = ({ title }: Props) => {
  return (
    <Heading level="2" size="24px" margin={{ vertical: '16px' }}>
      {title}
    </Heading>
  );
};

export default HeadingTwo;
