import Field, {
    AffixWrapperStyled,
    MaskedInputStyled,
    PrefixStyled,
    PrefixWrapperStyled,
    SuffixStyled,
    SuffixWrapperStyled
} from './Field.styled';
import React, { useEffect, useState } from 'react';
import { getID } from '../../utils';

import PropTypes from 'prop-types';
import { trackMe } from '../ComponentTracker/componentTracker';

// For Server Side Rendering
const document = document || {}; // eslint-disable-line
const navigator = navigator || {}; // eslint-disable-line

// Internet Explorer 6-11
// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Safari & iOS
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

const FormInput = ({
    id,
    label,
    value,
    placeholder,
    onBlur,
    onChange,
    onValidate,
    type,
    size,
    prefix,
    suffix,
    helpMessage,
    hasError,
    errorMessage,
    disabled,
    maxLength,
    name,
    inputRef,
    mask,
    inputWidth,
    ...rest
}) => {

    const genID = () => {
        return id || getID();
    };

    const elemID = genID();
    const idError = `${elemID}-error`;
    const idHelper = `${elemID}-helper`;
    const [errorText, setErrorText] = useState(errorMessage);
    const [isValidateError, setIsValidateError] = useState(false);

    useEffect(() => {
        trackMe('FormInput');
    }, []);

    useEffect(
        () => {
            setErrorText(hasError ? errorMessage : '');
            setIsValidateError(hasError);

            // Re-run the custom validation if hasError has been switched off.
            if (!hasError) {
                const validateErrorMessage = validate(value);
                setErrorText(validateErrorMessage);
                setIsValidateError(!!validateErrorMessage);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasError]
    );

    const validate = value => {
        // If an error has been forced upon us by the consumer, use their error.
        if (hasError) {
            setErrorText(errorMessage);
            return errorMessage;
        }

        // If there's no error so far, ask the consumer if they believe there's an error.
        if (onValidate) {
            const validateErrorMessage = onValidate(value);
            if (validateErrorMessage) { return validateErrorMessage; }
        }
    };

    const createEvent = (isError, value) => {
        const event = { value, hasError: isError };
        return event;
    };

    const handleBlur = e => {
        const value = e.target.value;
        const validateErrorMessage = validate(value);
        const isError = !!validateErrorMessage; // If there's an error message, we've got an error
        setErrorText(validateErrorMessage);
        setIsValidateError(isError);
        const event = createEvent(isError, value);
        onBlur && onBlur(e, event);
    };

    const handleChange = e => {
        const value = e.target.value;
        const isError = !!validate(value);
        // If there's no longer a custom error, remove custom error text
        if (!isError) {
            setErrorText('');
            setIsValidateError(false);
        }

        if (onChange) {
            const event = createEvent(isError, value);
            // Should be just 'event' (not 'e, event') but this is a pre-existing
            // event which is being used all over the place so I can't change it
            // without breaking people's code.
            onChange(e, event);
        }
    };

    const props = {
        onChange: handleChange,
        onBlur: handleBlur,
        ref: inputRef,
        name,
        value,
        placeholder,
        disabled,
        hasError: isValidateError,
        maxLength,
        prefix,
        suffix
    };

    const sizeOptionsArr = [
        ['small', 4],
        ['medium', 6],
        ['large', 8]
    ];

    const sizeOptions = new Map(sizeOptionsArr);

    const rowSize = sizeOptions.get(size);

    const renderDateField = () => {
        // IE & Safari on non-iOS to use react-text-mask input with placeholder
        if (isIE || (isSafari && !iOS)) {
            return (
                <MaskedInputStyled
                    mask={ [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] }
                    guide
                    id={ elemID }
                    placeholder='dd/mm/yyyy'
                    value={ value }
                    disabled={ disabled }
                    maxLength={ maxLength }
                    onChange={ props.onChange }
                    aria-invalid={ isValidateError }
                    aria-describedby={ `${idError} ${idHelper}` }
                    hasError={ isValidateError }
                    // inputWidth={ inputWidth } // TODO: test
                />
            );
        }

        // other browsers and Safari on iOS to use regular date input
        return (<Field.Input
            type='date'
            id={ elemID }
            aria-invalid={ isValidateError }
            aria-describedby={ `${idError} ${idHelper}` }
            inputWidth={ inputWidth }
            { ...props }
        />);
    };

    const renderMaskedInputField = () => {
        // https://www.npmjs.com/package/react-text-mask
        return (
            <AffixWrapperStyled>
                { prefix &&
                    <PrefixWrapperStyled>
                        <PrefixStyled { ...props }>
                            { prefix }
                        </PrefixStyled>
                    </PrefixWrapperStyled>
                }
                <MaskedInputStyled
                    mask={ mask || false }
                    type={ type }
                    id={ elemID }
                    aria-invalid={ isValidateError }
                    aria-describedby={ `${idError} ${idHelper}` }
                    inputWidth={ inputWidth }
                    { ...props }
                />
                { suffix &&
                    <SuffixWrapperStyled>
                        <SuffixStyled { ...props }>
                            { suffix }
                        </SuffixStyled>
                    </SuffixWrapperStyled>
                }
            </AffixWrapperStyled>
        );
    };

    const renderTextAreaField = () => {
        return (
            <Field.TextArea
                id={ elemID }
                aria-invalid={ isValidateError }
                aria-describedby={ `${idError} ${idHelper}` }
                rows={ rowSize }
                inputWidth={ inputWidth }
                { ...props }
            />
        );
    };

    const renderInputField = () => {
        return (
            <Field.Input
                type={ type }
                id={ elemID }
                aria-invalid={ isValidateError }
                aria-describedby={ `${idError} ${idHelper}` }
                inputWidth={ inputWidth }
                { ...props }
            />
        );
    };

    const renderField = () => {
        switch (type) {
            case 'textarea':
                return renderTextAreaField();
            case 'date':
                return renderDateField();
            default:
                if (mask || prefix || suffix) {
                    return renderMaskedInputField();
                }

                return renderInputField();
        }
    };

    return (
        <Field { ...rest }>
            { label && <Field.Label htmlFor={ elemID } >{ label }</Field.Label> }
            { helpMessage && <Field.Help id={ idHelper }>{ helpMessage }</Field.Help> }
            { renderField() }
            { isValidateError && <Field.Error id={ idError }>{ errorText }</Field.Error> }
        </Field>
    );
};

FormInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['text', 'textarea', 'number', 'date', 'email', 'tel']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    helpMessage: PropTypes.string,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    mask: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.func
    ]),
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    onValidate: PropTypes.func,
    onBlur: PropTypes.func
};

FormInput.defaultProps = {
    label: '',
    placeholder: '',
    prefix: '',
    suffix: '',
    helpMessage: '',
    hasError: false,
    errorMessage: '',
    disabled: false,
    name: '',
    type: 'text',
    size: 'small',
    mask: null,
};

export default FormInput;
