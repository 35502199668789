import * as React from 'react';
import { Heading, Box } from 'grommet';

type Props = {
  title: string;
};

const Title: React.FunctionComponent<Props> = ({ title }: Props) => {
  return (
    <Box style={{ borderBottom: '2px solid #e6e6e6', paddingBottom: '19px', marginBottom: '40px' }}>
      <Heading level="4" size="xlarge">
        {title}
      </Heading>
    </Box>
  );
};

export default Title;
