import axios from 'axios';
import { getIdTokenInLocalStorage } from '../../user/components/AuthService';
import { userToBookingUserDetailsAdapter } from '../../booking/utils/userToBookingUserDetailsAdapter';

const client = axios.create({
  baseURL: process.env.EOL_API_URL,
});

const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${process.env.USER_TOKEN || getIdTokenInLocalStorage()}`,
      'X-API-Key': process.env.X_API_KEY,
    },
  };
};

const getWill = async (user: User): Promise<WillPlanningForm.Will> => {
  const { data: will } = await client.get(`/wills/${user.myAccountId}`, getHeaders());

  return will;
};

const createWill = async (user: User): Promise<WillPlanningForm.Will> => {
  const { data: will } = await client.post(
    `/wills/${user.myAccountId}`,
    {
      userDetails: {
        loginId: user.email,
      },
    },
    getHeaders(),
  );

  return will;
};

const getAssets = async (user: User): Promise<WillPlanningForm.AssetSubType[]> => {
  const {
    data: { assets },
  } = await client.get(`/wills/${user.myAccountId}/assets`, getHeaders());

  return assets;
};

const setAssets = (user: User, assets: WillPlanningForm.AssetSubType[]): Promise<unknown> => {
  return client.patch(
    `/wills/${user.myAccountId}/assets`,
    {
      assets: assets,
    },
    getHeaders(),
  );
};

const getUserInteractions = async (user: User): Promise<ControlStates.UserInteractions> => {
  const { data: userInteractions } = await client.get(`/interactions/${user.myAccountId}/planning`, getHeaders());

  return userInteractions;
};

const updateUserInteractions = async (
  user: User,
  userInteractions: ControlStates.UserInteractionsUpdate,
): Promise<ControlStates.State> => {
  return client.patch(
    `/interactions/${user.myAccountId}/planning`,
    {
      ...userInteractions,
    },
    getHeaders(),
  );
};

const setQuestionsAnswers = (
  user: User,
  personalQuestionsAnswers: WillPlanningForm.PersonalQuestionsAnswersState,
): Promise<unknown> => {
  return client.patch(
    `/wills/${user.myAccountId}/questions`,
    {
      questions: personalQuestionsAnswers,
    },
    getHeaders(),
  );
};

const getQuestionsAnswers = async (user: User): Promise<WillPlanningForm.Question[]> => {
  const {
    data: { questions },
  } = await client.get(`/wills/${user.myAccountId}/questions`, getHeaders());

  return questions;
};

const sendBookingRequest = (
  userDetails: Booking.UserDetails,
  booking: Booking.MeetingFormData,
  myAccountUserId: string,
): Promise<unknown> => {
  return client.post(
    `/bookings/${myAccountUserId}`,
    {
      userDetails,
      booking,
    },
    getHeaders(),
  );
};

const submitWill = async (user: User): Promise<unknown> => {
  return client.patch(`/wills/${user.myAccountId}/taskInfo/submit`, {}, getHeaders());
};

export default {
  getWill,
  createWill,
  getAssets,
  setAssets,
  getQuestionsAnswers,
  setQuestionsAnswers,
  getUserInteractions,
  updateUserInteractions,
  sendBookingRequest,
  submitWill,
};
