import * as React from 'react';
import { Paragraph, Text } from 'grommet';

const RequiredField: React.FunctionComponent = () => {
  return (
    <Paragraph margin={{ vertical: '20px' }}>
      <Text color="red">* </Text>
      <Text>indicates a required field</Text>
    </Paragraph>
  );
};

export default RequiredField;
