import * as React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Heading, Box, Text, Anchor, Image, ResponsiveContext } from 'grommet';
import { Button } from '@snsw/react-component-library';
import AnchorIcon from './Anchor.svg';

type Props = {
  headerText: string;
  bodyText: string;
  anchorText: string;
  anchorOnClick?: () => void;
  bookingOnClick?: (event) => void;
};

type AnchorWithIconLabelProps = {
  text: string;
};

export const AnchorWithIconLabel: React.FunctionComponent<AnchorWithIconLabelProps> = ({
  text,
}: AnchorWithIconLabelProps) => {
  const size = React.useContext(ResponsiveContext);
  const AnchorWithIconDesktop = (
    <Box direction="row" gap="xxxxsmall">
      <Box>{text}</Box>
      <Box direction="row" alignContent="center">
        <Image src={AnchorIcon} />
      </Box>
    </Box>
  );
  const AnchorWithIconMobile = (
    <Box>
      <p>
        {text} <Image src={AnchorIcon} margin={{ left: 'xxxxsmall' }} />
      </p>
    </Box>
  );

  return (
    <Box direction="row" align="center" gap="xxsmall">
      {size === 'small' ? AnchorWithIconMobile : AnchorWithIconDesktop}
    </Box>
  );
};

const LegalActionCard: React.FunctionComponent<Props> = ({
  headerText,
  bodyText,
  anchorText,
  anchorOnClick,
  bookingOnClick,
}: Props) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Card
      pad={{ horizontal: 'medium', vertical: 'large' }}
      round=""
      justify="start"
      align="start"
      border={{ color: '#4D0D6' }}
      elevation="medium"
    >
      <CardHeader>
        <Heading level="3" color="brand" margin={{ bottom: 'xsmall' }}>
          {headerText}
        </Heading>
      </CardHeader>
      <CardBody>
        <Text margin={{ bottom: 'medium' }}>{bodyText}</Text>
      </CardBody>
      <CardFooter fill>
        <Box direction="row-responsive" align="center" justify="between" fill>
          <Box margin={{ bottom: size === 'small' ? 'large' : '' }}>
            <Anchor
              size="medium"
              label={<AnchorWithIconLabel text={anchorText} />}
              onClick={anchorOnClick}
              style={{ textDecoration: 'underline' }}
            />
          </Box>
          {bookingOnClick && (
            <Button theme="primary" className="primary-button-dcs" type="button" onClick={bookingOnClick}>
              Book an appointment
            </Button>
          )}
        </Box>
      </CardFooter>
    </Card>
  );
};

export default LegalActionCard;
