import UserContext from 'app/features/user/context/UserContext';
import { Anchor, Box, Heading, Text, Image, ResponsiveContext } from 'grommet';
import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import PlanningService from 'planning/services/PlanningService';
import ControlStatesContext from 'app/features/control-states/context/ControlStatesContext';
import { routes } from 'app/common/routes';
import { Button } from '@snsw/react-component-library';
import HeadingThree from 'app/common/HeadingThree';
import infoIcon from 'app/common/assets/info.svg';
import PlanningFormContext from '../context/PlanningFormContext';
import YourDetailsComponent from '../../user-details/YourDetailsComponent';
import YourAssetsComponent from './YourAssetsComponent';
import StepNavigationComponent from './StepNavigationComponent';
import YourAnswersComponent from './YourAnswersComponent';
import Disclaimer from './Disclaimer';
import BackLinkComponent from './BackLinkComponent';
import WillSummaryPdfLink from './PdfDownload/will/WillSummaryPdfLink';

const FloatingFooter = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Summary: React.FunctionComponent = () => {
  const history = useHistory();
  const { personalQuestionsAnswers, assets, setAssets, setPersonalQuestionsAnswers } = React.useContext(
    PlanningFormContext,
  );
  const { termsAndConditions } = React.useContext(ControlStatesContext);

  const { user } = React.useContext(UserContext);
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [isPdfReady, setIsPdfReady] = React.useState(false);

  const handleNext = () => {
    history.push('');
  };

  const handleBack = () => {
    history.push(routes.QUESTIONS);
  };

  const handleGetLegalAdvice = () => {
    history.push(routes.GET_LEGAL_ADVICE);
  };

  const closeModal = () => {
    setVisibleModal(false);
  };

  React.useEffect(() => {
    PlanningService.getWill(user)
      .then((will: WillPlanningForm.Will) => {
        setAssets(will.assets);
        setPersonalQuestionsAnswers(will.questions);
        setIsPdfReady(true);
      })
      .catch(() => {
        // Error while fetching up-to-date questions and assets
      });
  }, []);

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          <Box fill>
            <Box>
              <BackLinkComponent handleBack={handleBack} />
            </Box>
            <Box>
              <Heading level="2" margin="0">
                Review your will planning summary
              </Heading>
            </Box>
            <Box width="xlarge" pad={{ top: 'medium' }}>
              <Text>Check that your information is correct and up to date.</Text>
            </Box>
            <Box border={{ side: 'bottom' }} pad={{ vertical: 'medium' }}>
              <YourDetailsComponent user={user} showEdit={false} />
            </Box>
            {assets.length > 0 && (
              <Box border={{ side: 'bottom' }} pad={{ vertical: 'medium' }}>
                <YourAssetsComponent assets={assets} />
              </Box>
            )}
            {personalQuestionsAnswers.length > 0 && (
              <Box border={{ side: 'bottom' }} pad={{ vertical: 'medium' }}>
                <YourAnswersComponent questions={personalQuestionsAnswers} />
              </Box>
            )}
            <Box border={{ side: 'bottom' }} pad={{ vertical: 'medium' }}>
              <Box>
                {isPdfReady && (
                  <WillSummaryPdfLink user={user} assets={assets} personalQuestionsAnswers={personalQuestionsAnswers} />
                )}
              </Box>
            </Box>
            <Box pad={{ vertical: 'large' }}>
              <Box>
                <HeadingThree title="Next steps" />
              </Box>
              <Box>
                <ol>
                  {/* <li>Download your will planning summary</li> */}
                  <li>
                    <Anchor
                      label="Get professional advice"
                      onClick={handleGetLegalAdvice}
                      style={{ textDecoration: 'underline' }}
                    />{' '}
                    from NSW Trustee & Guardian or via the Law Society of NSW.
                  </li>
                </ol>
              </Box>
            </Box>
            <Box pad={{ top: 'large' }}>
              <StepNavigationComponent
                handleBack={handleBack}
                nextButton={
                  <Button theme="primary" className="primary-button-dcs" type="button" onClick={handleNext}>
                    Return to homepage
                  </Button>
                }
              />
            </Box>
            <FloatingFooter
              justify="center"
              background={{ color: '#EEF0F6' }}
              height={size === 'small' ? '110px' : '96px'}
              pad={{ horizontal: size === 'small' ? '10px' : '10%' }}
            >
              <Box direction="row" align="center">
                <Box
                  width={{ min: '28px' }}
                  margin={{ right: size === 'small' ? 'xxxsmall' : 'small' }}
                  style={{ alignSelf: size === 'small' ? 'start' : 'center' }}
                >
                  <Image src={infoIcon} />
                </Box>
                <Box>
                  <Box>
                    <Box>
                      <Heading level="4" margin="0" size={size === 'small' ? '16px' : ''}>
                        This is not a will
                      </Heading>
                    </Box>
                    <Box>
                      <Box direction={size === 'small' ? 'column' : 'row'}>
                        <Box margin={{ right: size === 'small' ? 'xxxsmall' : 'small' }}>
                          <Text size={size === 'small' ? 'xsmall' : ''}>
                            This page is a summary of the information you&apos;ve provided. It is not instructions for,
                            or a draft of, a will.{' '}
                            {size === 'small' && (
                              <Anchor
                                onClick={() => setVisibleModal(true)}
                                size="xsmall"
                                style={{
                                  textDecoration: 'underline',
                                  fontWeight: 'normal',
                                }}
                              >
                                Find out more.
                              </Anchor>
                            )}
                          </Text>
                        </Box>
                        <Box>
                          {size !== 'small' && (
                            <Anchor
                              onClick={() => setVisibleModal(true)}
                              style={{
                                textDecoration: 'underline',
                                fontWeight: 'normal',
                              }}
                            >
                              Find out more.
                            </Anchor>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </FloatingFooter>
          </Box>
          {visibleModal && (
            <Disclaimer closeModal={closeModal} isViewMode={true} hasAcceptedTerms={termsAndConditions?.hasAccepted} />
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Summary;
