import breakpoints from './Tokens/compiled/breakpoints';
import spacing from './Tokens/compiled/spacing';
import font from './Tokens/compiled/font';
import { css } from 'styled-components';

const DEFAULT_ID = 'id__';

let globalID;

if (globalID === undefined) {
    globalID = 0;
}

export const getID = (prefix = DEFAULT_ID) => {
    globalID++;
    return `${prefix}${globalID}`;
};

export const focusObjectGenerator = (arr) => {
    const focusableElements = {
        all: arr,
        first: arr[0],
        last: arr[arr.length - 1],
        length: arr.length,
    };

    return focusableElements;
};

export const getFocusableElement = (el) => {
    const elementArr = [].slice.call(el.querySelectorAll(`a[href],button:not([disabled]),
    area[href],input:not([disabled]):not([type=hidden]),
    select:not([disabled]),textarea:not([disabled]),
    iframe,object,embed,*:not(.is-draggabe)[tabindex],
    *[contenteditable]`));

    return focusObjectGenerator(elementArr);
};

export const trapTabKey = (event, focusObject) => {
    const { activeElement } = document;
    const focusableElement = focusObject;

    if (event.keyCode !== 9) {return false;};

    if (focusableElement.length === 1) {
        event.preventDefault();
    } else if (event.shiftKey && activeElement === focusableElement.first) {
        focusableElement.last.focus();
        event.preventDefault();
    } else if (!event.shiftKey && activeElement === focusableElement.last) {
        focusableElement.first.focus();
        event.preventDefault();
    }

    return true;
};

export const pxToRem = (size) => {
    return (`${size / 16}rem`);
};

export const pxToEm = (size) => {
    return (`${size / 16}em`);
};

export const getSpacing = (sizes) => {
    const sizesArr = (Array.isArray(sizes)) ? sizes : [sizes];
    const theSizes = sizesArr.map(size => {
        let returnVal;
        const spacingKeys = Object.keys(spacing);
        const tokenExists = spacingKeys.indexOf(size) !== -1;
        const isNumber = /^\d+$/.test(size);

        if(tokenExists){
            const spaceToken = spacing[size];
            returnVal = pxToRem(spaceToken);
        }
        if(!tokenExists && isNumber){
            returnVal = pxToRem(size);
        }

        if(!tokenExists && !isNumber) {
            const err = new Error('getSpacing function has been passed an invalid token.');
            throw err;
        };

        if(sizesArr.length > 4) {
            const err = new Error('getSpacing accepts arrays up to 4 values.');
            throw err;
        };

        return returnVal;
    });

    return theSizes.join(' ');
};

const mediaQuery = (...query) => (...rules) => css`@media ${css(...query)} { ${css(...rules)} }`;

export const getBreakpoint = {
    xs: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.xs)})`,
    sm: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.sm)})`,
    md: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.md)})`,
    lg: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.lg)})`,
    xl: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.xl)})`,
    print: mediaQuery`print`,
};

export const getTypeSize = (size, lineHeight = 'default') => {
    const lineHeightVal = (lineHeight === 'heading' ? font.lineHeight.heading : font.lineHeight.default);
    const fontSize = font.typeSize[size];
    const output = css`
        font-size: ${pxToRem(fontSize[0])};
        line-height: ${lineHeightVal};

        ${getBreakpoint.md`
            font-size: ${pxToRem(fontSize[1])};
        `}
    `;
    return output;

};

// For testing if the code is running in a client-side browser, or it's being server-side rendered (SSR).
// Usage:
//      import { canUseDom } from '/utils';
//      if (canUseDom) { ... }
export const canUseDom = Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);

const utils = {
    getID,
    focusObjectGenerator,
    getFocusableElement,
    trapTabKey,
    pxToRem,
    pxToEm,
    getSpacing,
    getBreakpoint,
    getTypeSize,
    canUseDom
};

export default utils;
