import { Box, ResponsiveContext } from 'grommet';
import * as React from 'react';
import { Button } from '@snsw/react-component-library';

type Props = {
  handleBack?: () => void;
  textBack?: string;
};

const BackLinkComponent: React.FunctionComponent<Props> = ({ handleBack, textBack = ' Back' }: Props) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Box pad={{ top: 'xxxxsmall', bottom: 'small' }}>
      <Button theme="back" type="button" className="back-link-dcs" onClick={handleBack}>
        {textBack}
      </Button>
    </Box>
  );
};

export default BackLinkComponent;
