const api = {
    request: async (url, options) => {
        const response = await fetch(url, options);

        if (response.status < 200 || response.status > 299) {
            throw Error('Received a non 2xx response');
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('json') !== -1) {
            return await response.json();
        }
        return await response.text();
    }
};

export default api;
