import styled from 'styled-components/macro';
import Field from '../Field.styled';
import { pxToRem, getBreakpoint } from '../../../utils';

export const DateContainer = styled.div`
    width: 100%;
    display:flex;
`;

export const CellField = styled(Field)`
    margin: 0 !important;
`;

export const CellDay = styled.div`
    flex-grow: 1;
    width:  ${pxToRem(70)};
    max-width: ${pxToRem(100)};

    ${getBreakpoint.md`
        width: ${pxToRem(100)};
    `}
`;

export const CellMonth = styled.div`
    flex: 2 1 auto;
    padding-left: ${pxToRem(6)};

    ${getBreakpoint.md`
        padding-left: ${pxToRem(12)};
    `}
`;

export const CellYear = styled.div`
    flex-grow: 1;
    width: ${pxToRem(100)};
    max-width: ${pxToRem(110)};
    padding-left: ${pxToRem(6)};

    ${getBreakpoint.md`
        width: ${pxToRem(110)};
        padding-left: ${pxToRem(12)};
    `}
`;

