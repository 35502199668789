import React from 'react';

import Address from '../../Components/Form/Address/Address';
import FormInput from '../../Components/Form/FormInput';
import Heading from '../../Components/Headings/Heading';
import PropTypes from 'prop-types';
import { australianPhoneNumber } from '../../Validation/Masks';
import { isAllWhitespace, emailFormat, notAnAlphaName, notAustralianPhoneNumber } from '../../Validation/Validators';
import { getErrorMessage } from '../../Validation/ErrorMessages';

const onNameValidate = name => {
    if (notAnAlphaName(name)) {
        return getErrorMessage('notAlphaName');
    } else if (isAllWhitespace(name)) {
        return getErrorMessage('allWhitespace');
    }
};

const onPhoneValidate = name => {
    if (notAustralianPhoneNumber(name)) {
        return getErrorMessage('notAustralianPhoneNumber');
    }
};

const emailOnValidate = value => {
    if (emailFormat(value)) {
        return getErrorMessage('emailFormat');
    }
};

const PersonalDetailsPage = ({ onChange, customer, apiKey, options }) => {

    return (
        <>
            <Heading>{ options?.personalDetailsHeading || 'Personal details'}</Heading>
            <FormInput
                label='First name'
                name='firstName'
                onChange={ (e, value) => onChange(e.target.name, value) }
                onValidate={ onNameValidate }
                value={ customer.firstName.value }
            />
            <FormInput
                label='Middle name'
                name='middleName'
                onChange={ (e, value) => onChange(e.target.name, value) }
                onValidate={ onNameValidate }
                value={ customer.middleName.value }
            />
            <FormInput
                label='Last name'
                name='lastName'
                onChange={ (e, value) => onChange(e.target.name, value) }
                onValidate={ onNameValidate }
                value={ customer.lastName.value }
            />
            <Heading>{ options?.contactDetailsHeading || 'Contact details'}</Heading>
            { (options?.captureAddress !== false) &&
            <Address
                addressLabel='Residential address'
                name='address'
                addressType='personal'
                onChange={ (value) => onChange('address', value) }
                helpMessage='Start typing and select your address from the results that appear.
                             If you are unable to locate your address please enter it manually.'
                address={ customer.address.address }
                apiKey={ apiKey }
            />}
            <FormInput
                label='Email address'
                name='email'
                onChange={ (e, value) => onChange(e.target.name, value) }
                helpMessage='Enter your email address using the format example@email.com'
                onValidate={ emailOnValidate }
                value={ customer.email.value }
            />
            <FormInput
                label='Phone number'
                name='phoneNumber'
                onChange={ (e, value) => onChange(e.target.name, value) }
                helpMessage='Enter your phone number using 10 digits.
                             Include the area code if you are entering a landline.'
                mask={ australianPhoneNumber }
                onValidate={ onPhoneValidate }
                value={ customer.phoneNumber.value }
            />
        </>
    );
};

PersonalDetailsPage.propTypes = {
    customer: PropTypes.shape({
        firstName: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        middleName: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        lastName: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        address: PropTypes.shape({
            address: PropTypes.shape({
                addressLine: PropTypes.string,
                buildingNumber: PropTypes.string,
                country: PropTypes.string,
                formattedAddress: PropTypes.string,
                latitude: PropTypes.string,
                longitude: PropTypes.string,
                nswPointId: PropTypes.string,
                postcode: PropTypes.string,
                propertyName: PropTypes.string,
                state: PropTypes.string,
                streetName: PropTypes.string,
                streetNumber: PropTypes.string,
                streetType: PropTypes.string,
                suburb: PropTypes.string,
                unitNumber: PropTypes.string,
                validated: PropTypes.bool
            }),
        }),
        email: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        phoneNumber: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
    }),
    apiKey: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.shape({
        captureAddress: PropTypes.bool,
        personalDetailsHeading: PropTypes.string,
        contactDetailsHeading: PropTypes.string
    })
};

export default PersonalDetailsPage;
