import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MainContainer, NumberInputContainer } from './NumberInput.styled';
import { MoreInfoModal } from '../Modal/MoreInfoModal';
import { trackMe } from '../Components/ComponentTracker/componentTracker';

export const NumberInput = ({ className, min, max, step, value, onChange, title, moreInfoTitle, moreInfoHelpText, ...rest }) => {

    useEffect(() => {
        trackMe('NumberInput');
    }, []);

    const [currentValue, setCurrentValue] = useState(value || '');

    const change = e => {
        const targetValue = e.target.value;
        if ((targetValue <= max && targetValue >= min) || !targetValue) {
            setCurrentValue(targetValue);
            onChange(targetValue.toString());
        }
    };

    const decrement = () => {
        if (currentValue >= min + step) {
            const newValue = parseInt('' + currentValue) - step;
            setCurrentValue(newValue);
            onChange(newValue.toString());
        }
    };

    const increment = () => {
        if (!currentValue) {
            setCurrentValue(min);
            onChange(min.toString());
        } else if (currentValue <= max - step) {
            const newValue = parseInt('' + currentValue) + step;
            setCurrentValue(newValue);
            onChange(newValue.toString());
        }
    };

    return (
        <MainContainer className={ className }>
            <p>{title}</p>
            <NumberInputContainer>
                <NumberInputContainer.Input { ...rest } value={ currentValue } onChange={ change } />
                <NumberInputContainer.ButtonMinus onClick={ decrement }>–</NumberInputContainer.ButtonMinus>
                <NumberInputContainer.ButtonAdd onClick={ increment }>+</NumberInputContainer.ButtonAdd>
                <MoreInfoModal title={ moreInfoTitle } helpText={ moreInfoHelpText } />
            </NumberInputContainer>
        </MainContainer>
    );
};

NumberInput.propTypes = {
    className: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    moreInfoTitle: PropTypes.string,
    moreInfoHelpText: PropTypes.string
};

NumberInput.defaultProps = {
    step: 1,
    min: 1,
    max: 20,
    value: 1,
};

export default NumberInput;
