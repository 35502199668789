import * as React from 'react';
import { FieldProps } from '@rjsf/core';
import { Box, Heading } from 'grommet';

const TitleField: React.FunctionComponent<FieldProps> = ({ title }) => (
  <Box>
    <Heading level="5">{title}</Heading>
  </Box>
);

export default TitleField;
