import * as React from 'react';
import { ISubmitEvent } from '@rjsf/core';
import { JSONSchema7 } from 'json-schema';
import { withTheme } from '@rjsf/core';
import Form from 'app/theme/Form/Form';
import { Box, Paragraph, Text } from 'grommet';
import transformErrors from '../utils/transform-errors';
import { uiSchema } from './UISchema';

type Props = {
  schema: JSONSchema7;
  initialFormData: unknown;
  handleOnSubmit: (formData: unknown) => void;
  handleOnDelete?: (formData: unknown) => void;
};

const FormContainer: React.FunctionComponent<Props> = ({
  schema,
  initialFormData,
  handleOnSubmit,
  handleOnDelete,
}: Props) => {
  const [formData, setFormData] = React.useState(initialFormData);

  const onSubmit = ({ formData }: ISubmitEvent<unknown>) => {
    setFormData(formData);
    handleOnSubmit(formData);
  };

  const onDelete = () => {
    handleOnDelete(formData);
  };

  // Do not delete! Fixes bug in EOL-361.
  React.useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  const ThemedForm = withTheme(Form(false, handleOnDelete ? onDelete : null));

  return (
    <Box margin={{ top: 'xsmall' }}>
      <Box margin={{ bottom: '15px' }}>
        <Paragraph>
          <Text color="red">* </Text>
          <Text>indicates a required field</Text>
        </Paragraph>
      </Box>
      <ThemedForm
        name={'form'}
        uiSchema={uiSchema}
        schema={schema}
        formData={formData}
        onSubmit={onSubmit}
        noHtml5Validate={true}
        showErrorList={false}
        transformErrors={transformErrors}
        omitExtraData={true}
      />
    </Box>
  );
};

export default FormContainer;
