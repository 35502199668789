import * as React from 'react';
import { Box, Card, Text, Image } from 'grommet';

type Props = {
  title?: string;
  text: string | JSX.Element;
};

const WarningCard: React.FunctionComponent<Props> = ({ title, text }: Props) => {
  return (
    <Card
      width="large"
      background="#FCEFE6"
      border={{ side: 'left', color: '#DC5800', size: 'medium' }}
      round=""
      elevation="none"
      pad={{ vertical: '20px', right: '26px' }}
    >
      <Box direction="row">
        <Box width={{ min: '28px', max: 'xxsmall' }} margin={{ left: 'xsmall', right: 'small' }}>
          <Image src={require('app/common/assets/warning.svg')} />
        </Box>
        <Box>
          {title && <Text weight="bold">{title}</Text>}
          {typeof text === 'string' ? <Text>{text}</Text> : text}
        </Box>
      </Box>
    </Card>
  );
};

export default WarningCard;
