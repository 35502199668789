import * as React from 'react';
import { Text, Box } from 'grommet';
import moment from 'moment';

type Props = {
  taskInfo: WillPlanningForm.TaskInfo;
};

const Tracking: React.FunctionComponent<Props> = ({ taskInfo }: Props) => {
  if (taskInfo?.status === 'IN_PROGRESS' || taskInfo?.status === 'INCOMPLETE' || taskInfo?.status === 'COMPLETE') {
    return (
      <Box>
        <Box height="20px" pad={{ bottom: '20px' }}>
          <Text color="#A0A5AE" size="small">
            Last updated {moment(taskInfo.updateDateTime).format('DD/MM/YYYY')}
          </Text>
        </Box>
        <Box height="20px">
          <Text style={{ textDecoration: 'underline' }}>Edit</Text>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box height="20px" pad={{ bottom: '20px' }}>
          <Text color="light-6" size="small">
            &nbsp;
          </Text>
        </Box>
        <Box height="20px">
          <Text style={{ textDecoration: 'underline' }}>Start</Text>
        </Box>
      </Box>
    );
  }
};

export default Tracking;
