import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledFormHeader, PageTitle } from '../SectionHeader/SectionHeader.styled';
import { Container } from '../ContentContainer/ContentContainer.styled';
import { trackMe } from '../ComponentTracker/componentTracker';

const FormHeader = ({ className, children }) => {
    useEffect(() => {
        trackMe('FormHeader');
    }, []);

    return (
        <StyledFormHeader className={ className }>
            <Container>
                <PageTitle>{children}</PageTitle>
            </Container>
        </StyledFormHeader>
    );
};

FormHeader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
};

export default FormHeader;
