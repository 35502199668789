import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const CarSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <g>
            <path d='M35.1 38h-1.2c-1.6 0-2.9-1.3-2.9-2.9V33h2v2.1c0 .5.4.9.9.9h1.2c.5 0 .9-.4.9-.9V33h2v2.1c0 1.6-1.3 2.9-2.9 2.9zm-24.7 0H8.9C7.3 38 6 36.7 6 35.1V33h2v2.1c0 .5.4.9.9.9h1.5c.5 0 .6-.5.6-.9V33h2v2.1c0 1.7-1.1 2.9-2.6 2.9zM6.7 32c-2.2 0-3.2-2.4-3.4-3.7-.1-.6-.9-4.4.6-8.3 0-.1.1-.1.1-.2.8-1.1 2.7-3.7 3.1-4.2L9.5 10c.9-2.3 2.4-2.8 2.5-2.9C16.7 5.9 21.7 6 21.9 6h.2c.2 0 5.2-.1 9.8 1.1.2.1 1.7.6 2.6 2.9l2.4 5.5c.4.5 2.3 3.2 3.1 4.2 0 .1.1.1.1.2 1.5 3.9.7 7.8.6 8.3-.2 1.4-1.2 3.9-3.6 3.7H6.9c-.1.1-.2.1-.2.1zm-1-11.2c-1.3 3.5-.5 7-.5 7v.1s.3 2.1 1.5 2H37.1c1.2 0 1.4-2 1.5-2v-.1s.9-3.6-.5-7c-.9-1.3-3.1-4.2-3.1-4.2 0-.1-.1-.1-.1-.2l-2.5-5.7c-.3-1.3-.9-1.7-1.1-1.7-4.3-1-9.2-1-9.2-1h-.2c-.1 0-4.9-.1-9.2 1-.1.1-.8.4-1.3 1.7l-2.5 5.7c0 .1-.1.1-.1.2 0 0-2.2 2.9-3.1 4.2z' />
            <path d='M39.8 25.1h-6.3c-.7-.1-1.1-.4-1.3-.7-.5-.6-.4-1.3-.4-1.4.7-4.1 6.4-4.4 6.6-4.5l.1 2s-4.1.3-4.7 2.6h6v2zM10.4 25.1H4.2v-2h6c-.5-2.3-4.7-2.6-4.7-2.6l.1-2c.2 0 5.9.4 6.6 4.4 0 .2.1.9-.4 1.5-.3.3-.6.7-1.4.7zM7.9 15H36v2H7.9zM29 30h-2v-2H17v2h-2v-3c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v3z' />
            <path d='M15 11c-2.2 0-4 1.8-4 4 0 .4.1.9.2 1.3s.5.7.9.7h5.6c.4 0 .8-.3.9-.7.2-.5.2-.9.2-1.3.2-2.2-1.6-4-3.8-4z' />
        </g>
    </svg>
);

export const Car = styled(CarSvg)`
  ${color}
  ${layout}
`;

Car.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Car;
