import * as React from 'react';
import { Text, Box, Card, Heading, Image, CardHeader, CardBody, CardFooter } from 'grommet';

type Props = {
  icon: string;
  header: string | JSX.Element;
  body: string;
  footer: string | JSX.Element;
  onClick?: () => void;
};

const TaskCard: React.FunctionComponent<Props> = ({ icon, header, body, footer, onClick }: Props) => {
  return (
    <Card
      round="4px"
      elevation="medium"
      width="328px"
      height="255px"
      pad={{ top: '34px', bottom: '24px' }}
      margin={{ bottom: '24px', right: '24px' }}
      onClick={onClick}
    >
      <Box direction="row" flex>
        <Box flex={{ grow: 1 }} direction="column" fill="vertical" pad={{ left: '24px', right: '24px' }}>
          <Image src={icon} width="52" height="56" />
        </Box>
        <Box flex={{ grow: 3 }} direction="column" fill="vertical">
          <CardHeader width="228px" pad={{ bottom: '8px' }}>
            <Heading level="4" size="medium" style={{ color: '#002664' }}>
              {header}
            </Heading>
          </CardHeader>
          <CardBody width="232px" pad={{ right: '24px' }}>
            <Text style={{ color: '#44405B' }}>{body}</Text>
          </CardBody>
          <CardFooter width="204px" style={{ color: '#002664' }}>
            {footer}
          </CardFooter>
        </Box>
      </Box>
    </Card>
  );
};

export default TaskCard;
