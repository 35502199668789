import * as React from 'react';
import { Text } from 'grommet';

type Props = {
  text: string;
};

const ParagraphText: React.FunctionComponent<Props> = ({ text }: Props) => {
  return <Text margin={{ bottom: 'medium' }}>{text}</Text>;
};

export default ParagraphText;
