import * as React from 'react';
import { Box, Heading, RadioButtonGroup } from 'grommet';
import { answers } from '../utils/questions';

type QuestionsRadioProps = {
  name: string;
  legend: string;
  onChange: (value: string) => void;
  value: string;
};

const QuestionsRadio: React.FunctionComponent<QuestionsRadioProps> = ({
  name,
  legend,
  onChange,
  value,
}: QuestionsRadioProps) => {
  return (
    <Box fill margin={{ bottom: 'medium' }} className="question">
      <Box>
        <Heading level="5" margin={{ bottom: 'small' }} style={{ maxWidth: '100%' }}>
          {legend}
        </Heading>
      </Box>
      <Box>
        <RadioButtonGroup
          onChange={(event) => onChange(event.target.value)}
          options={[
            {
              label: 'Yes',
              value: answers.YES,
            },
            {
              label: 'No',
              value: answers.NO,
            },
            {
              label: 'Prefer not to say',
              value: answers.NO_ANSWER,
            },
          ]}
          name={name}
          value={value}
        />
      </Box>
    </Box>
  );
};

export default QuestionsRadio;
