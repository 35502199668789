import * as React from 'react';
import { Heading, Box, Card, CardBody, Text, Anchor } from 'grommet';
import { useHistory } from 'react-router';
import moment from 'moment';
import ControlStatesContext from 'app/features/control-states/context/ControlStatesContext';
import UserContext from 'app/features/user/context/UserContext';
import { routes } from 'app/common/routes';
import TaskInfoService from 'app/features/home/TaskInfoService';
import HighlightCardParagraphs from 'app/common/HighlightCardParagraphs';
import ParagraphText from 'app/common/ParagraphText';
import Disclaimer from '../planning/components/Disclaimer';
import TaskCard from './TaskCard';
import ActionCard from './ActionCard';
import Title from './Title';
import Tracking from './Tracking';
import { PersonalIcon, WillIcon, EgIcon, DocIcon } from './assets';

const Home: React.FunctionComponent = () => {
  const history = useHistory();
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(false);

  React.useEffect(() => {
    (window as any).quickFeed.init({
      placeholder: 'QuickfeedPlaceholder',
      label: 'How useful is this site?',
      environment: process.env.TUTD_ENV,
      sourceApplication: 'Life Journey - EOL Planning',
      positionTop: 'calc(100vh - 220px)',
    });
  }, []);

  const { status, lastPageVisited, termsAndConditions, setTermsAndConditions } = React.useContext(ControlStatesContext);

  const START: WillPlanningForm.TaskInfo = {
    status: 'initial',
    statusMessage: 'Start',
    createDateTime: null,
    updateDateTime: null,
    submitDateTime: null,
  };

  const INCOMPLETE: WillPlanningForm.TaskInfo = {
    status: 'IN_PROGRESS',
    statusMessage: 'In progress',
    createDateTime: moment().toISOString(),
    updateDateTime: moment().toISOString(),
    submitDateTime: null,
  };

  const COMPLETE: WillPlanningForm.TaskInfo = {
    status: 'COMPLETE',
    statusMessage: 'In progress',
    createDateTime: moment().toISOString(),
    updateDateTime: moment().toISOString(),
    submitDateTime: moment().toISOString(),
  };

  const { user } = React.useContext(UserContext);

  const [allTaskInfo, setAllTaskInfo] = React.useState(null);

  React.useEffect(() => {
    const loadAllTaskInfo = async (user: User) => {
      try {
        const allTaskInfo = await TaskInfoService.getAllTaskInfo(user);
        console.log('allTaskInfo =>', allTaskInfo);
        setAllTaskInfo(allTaskInfo);
      } catch (err) {
        console.warn('allTaskInfo not found. 1st time in?');
      }
    };
    loadAllTaskInfo(user);
  }, []);

  const willOnClick = () => {
    if (!termsAndConditions?.hasAccepted) {
      setVisibleModal(true);
    } else {
      history.push(routes.ASSETS);
    }
  };

  const comingSoon = () => {
    window.alert('Coming soon..');
  };

  const epoaTaskInfo = START;

  const guardianTaskInfo = INCOMPLETE;

  //const careTaskInfo = START;

  const funeralTaskInfo = INCOMPLETE;

  const gotoLegalAdvice = () => {
    history.push(routes.GET_LEGAL_ADVICE);
  };

  const saveAcknowledgement = async () => {
    await setTermsAndConditions({
      timestamp: new Date().getTime(),
      hasAccepted: true,
      userAction: 'start-will-planning',
    });
    closeModal();
    history.push(routes.ASSETS);
  };

  const closeModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Box>
        <Box>
          <Box pad={{ top: 'large' }}>
            <Heading level="4" style={{ fontWeight: 'bold', color: '#242934' }}>
              Life Administrator
            </Heading>
            <Heading level="1" style={{ color: '#242934' }}>
              End of Life planner
            </Heading>
          </Box>
          <Box width="xlarge" pad={{ top: 'medium' }} style={{ color: '#44405B' }}>
            <ParagraphText text="This information relates only to the law of NSW. In some circumstances, including if you currently live, have lived or have assets outside NSW, the law of another state, territory or country may apply." />
          </Box>
        </Box>

        <Box style={{ color: '#333333' }}>
          <HighlightCardParagraphs
            title="New release"
            paragraphs={[
              `This version of the planner has new features.`,
              `We'll be continually adding more in future updates.`,
            ]}
            width={'685px'}
          />
        </Box>

        <Box style={{ color: '#242934' }}>
          <Title title="Choose your planning task" />
        </Box>

        <Box width="xlarge" margin={{ bottom: 'xlarge' }} flex wrap direction="row">
          <TaskCard
            icon={PersonalIcon}
            header="Personal details"
            body="Make sure your details are up to date."
            footer={<Tracking taskInfo={allTaskInfo?.userDetails?.taskInfo} />}
            onClick={() => {
              history.push(routes.USER_DETAILS);
            }}
          />

          <TaskCard
            icon={WillIcon}
            header="Will preparation"
            body="A will is a legal document that sets out who you want to recieve your assets when you pass away."
            footer={<Tracking taskInfo={allTaskInfo?.will?.taskInfo} />}
            onClick={willOnClick}
          />

          {/* <TaskCard
            icon={EpoaIcon}
            header="Enduring Power of Attorney"
            body="Authorises someone to make legal and financial decisions for you."
            footer={<Tracking taskInfo={epoaTaskInfo} />}
            onClick={comingSoon}
          /> */}
          {/* </Box> */}

          {/* <Box direction="row-responsive" gap="small" margin={{ top: 'small' }}> */}
          <TaskCard
            icon={EgIcon}
            header="Enduring Guardianship"
            body="Authorises someone to make lifestyle, health and medical decisions for you."
            footer={<Tracking taskInfo={guardianTaskInfo} />}
            onClick={() => {
              history.push(routes.ENDURING_GUARDIAN_STATUS);
            }}
          />

          {/* <TaskCard
            icon={AcdIcon}
            header="Advanced Care Directive"
            body="Lets people know in advance what health care treatments you'd like to have, or refuse."
            footer={<Tracking taskInfo={careTaskInfo} />}
            onClick={comingSoon}
          /> */}

          {/* <TaskCard
            icon={FuneralIcon}
            header="Funeral wishes"
            body="Letting your wishes to be known can help reduce stress for friends and family when they are grieving."
            footer={<Tracking taskInfo={funeralTaskInfo} />}
            onClick={comingSoon}
          /> */}
          {/*</Box>*/}

          {/*<Box direction="row-responsive" gap="24px" margin={{ top: '24px' }}>*/}
          <TaskCard
            icon={DocIcon}
            header="Will and other documents location"
            body="Make sure your executor, beneficiaries and family know where your documents are kept."
            footer={<Tracking taskInfo={allTaskInfo?.documentLocation?.taskInfo} />}
            onClick={() => {
              history.push(routes.DOCUMENT_LOCATION);
            }}
          />
        </Box>

        <Box style={{ color: '#242934' }}>
          <Title title="Getting advice" />
        </Box>

        <Box>
          <ActionCard
            header="Get professional advice"
            body="Having your will prepared professionally"
            onClick={gotoLegalAdvice}
          />
        </Box>

        <Box pad={{ top: 'xlarge' }} width="large">
          <Box>
            <Card>
              <CardBody pad="medium">
                <Text style={{ color: '#44405B' }}>
                  For general enquiries, visit a{' '}
                  <Anchor
                    href="https://www.service.nsw.gov.au/service-centre"
                    target="_blank"
                    label="Service NSW location"
                    style={{ textDecoration: 'underline' }}
                  />{' '}
                  or call <Anchor href="tel:137788" label="13 77 88" style={{ textDecoration: 'underline' }} />
                </Text>
              </CardBody>
            </Card>
          </Box>

          <Box pad={{ top: 'xlarge' }}>
            <div id="QuickfeedPlaceholder"></div>
          </Box>
        </Box>
      </Box>

      {visibleModal && (
        <Disclaimer
          closeModal={closeModal}
          saveAcknowledgement={saveAcknowledgement}
          setHasAcceptedTerms={setHasAcceptedTerms}
          hasAcceptedTerms={hasAcceptedTerms}
        />
      )}
    </>
  );
};

export default Home;
